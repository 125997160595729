import Link from 'next/link'
import Image from 'next/future/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useRouter} from 'next/router'
import {addLocalePrefix} from '@/components/BlockMapper/utils'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {ResponsiveLinkWithIconContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/responsive-link-with-icon'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {Icon} from '../../components/common/icon'

export type FaqHeadingShareBlockContentItem = IContentItem<{
	heading: Elements.TextElement
	linkIcon: Elements.LinkedItemsElement<ResponsiveLinkWithIconContentItem>
}>

export const FaqHeadingShareBlockBlock: Block<
	FaqHeadingShareBlockContentItem
> = ({
	block: {
		elements: {linkIcon, heading},
	},
}) => {
	const {locale = ''} = useRouter()
	return (
		<section className="container-wide my-6">
			<div className="flex flex-col justify-between md:flex-row md:items-center">
				<div>
					<h1
						className="mb-8 text-4xl font-semibold text-midnight-blue md:mb-0"
						data-kontent-element-codename="heading"
					>
						{heading}
					</h1>
				</div>
				<ul className="flex space-x-2">
					{linkIcon.length > 0 &&
						linkIcon.map((link) => (
							<li
								data-kontent-item-id={link.system.id}
								key={link.system.id}
							>
								<Link
									href={addLocalePrefix(
										link.elements.url,
										locale
									)}
									passHref
								>
									{/* eslint-disable-next-line jsx-a11y/anchor-is-valid -- TODO: remove when next/link updates */}
									<a
										className="flex flex-col items-center justify-end text-[#333]"
										rel="noreferrer"
										target={
											link.elements.openInNewTab[0]
												?.codename === 'yes'
												? '_blank'
												: '_self'
										}
									>
										<Icon
											className="h-6 w-6"
											noBackground
											noBorder
										>
											<>
												{link.elements.icon[0] ? (
													<Image
														alt={
															link.elements
																.icon[0]
																.description ||
															''
														}
														className="h-6 w-6"
														height={24}
														loader={
															loadImageFromKontentAI
														}
														src={
															link.elements
																.icon[0].url ||
															''
														}
														width={24}
													/>
												) : null}
											</>
										</Icon>
										<span
											className="text-sm"
											data-kontent-element-codename="text"
										>
											{link.elements.text}
										</span>
									</a>
								</Link>
							</li>
						))}
				</ul>
			</div>
		</section>
	)
}
