export interface HistoryTimelineSectionProps {
	children: React.ReactNode
	title: string
	id: string
}

export const HistoryTimelineSection: React.FC<HistoryTimelineSectionProps> = ({
	children,
	title, // eslint-disable-line @typescript-eslint/no-unused-vars -- used in parent component
	id,
}) => {
	return (
		<div
			className="pr-4 md:pl-[200px] xl:mx-auto xl:w-[calc(100%-400px)] xl:max-w-5xl xl:px-0"
			id={id}
		>
			{children}
		</div>
	)
}
