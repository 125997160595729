export const parvoPet = {
	formSchema: {
		us: {
			type: 'object',
			required: [
				'firstName',
				'lastName',
				'emailAddress',
				'caseDate',
				'clinicZipcode',
				'practiceName',
			],
			properties: {
				firstName: {
					type: 'string',
					title: 'First Name',
				},
				lastName: {
					type: 'string',
					title: 'Last Name',
				},
				emailAddress: {
					type: 'string',
					title: 'Email Address',
					pattern: '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$',
				},
				caseDate: {
					type: 'string',
					title: 'Date of Case',
					format: 'date',
				},
				clinicZipcode: {
					type: 'string',
					title: 'Case Zip Code',
					pattern: '(^[0-9]{5}$)|(^[0-9]{5}-[0-9]{4}$)',
				},
				practiceName: {
					type: ['string'],
					title: 'If you took your dog to the veterinarian, which veterinary clinic?',
				},
			},
		},
		testing: {
			type: 'object',
			required: [
				'firstName',
				'lastName',
				'emailAddress',
				'caseDate',
				'clinicZipcode',
				'practiceName',
			],
			properties: {
				firstName: {
					type: 'string',
					title: 'First Name',
				},
				lastName: {
					type: 'string',
					title: 'Last Name',
				},
				emailAddress: {
					type: 'string',
					title: 'Email Address',
					pattern: '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$',
				},
				caseDate: {
					type: 'string',
					title: 'Date of Case',
					format: 'date',
				},
				clinicZipcode: {
					type: 'string',
					title: 'Case Zip Code',
					pattern: '(^[0-9]{5}$)|(^[0-9]{5}-[0-9]{4}$)',
				},
				practiceName: {
					type: ['string'],
					title: 'If you took your dog to the veterinarian, which veterinary clinic?',
				},
			},
		},
	},
	uiSchema: {
		'ui:rootFieldId': 'parvoPet',
		'ui:order': [
			'firstName',
			'lastName',
			'emailAddress',
			'caseDate',
			'clinicZipcode',
			'practiceName',
		],
		description:
			'By clicking “Submit” I agree to Elanco’s <a href="https://www.elancostatements.com/en/terms-of-use/elanco-com">Terms of Use</a>, and acknowledge that Elanco will collect and process my personal information in accordance with Elanco’s <a href="https://privacy.elanco.com/en-us">Privacy Notice</a>.',
	},
}
