import type {ModelAsCode} from '@elanco/model-as-code'
import {
	OPTIONAL,
	QUESTION_ID,
	QUESTION_TEXT,
	REQUIRED,
	REQUIRED_OR_OPTIONAL,
} from './common-types'

export const DATE_SELECTION = 'date_selection'

const SingleSelectionQuestionModel: ModelAsCode = (builder) => ({
	modelType: 'contentType',
	name: 'Campaign Question - Date',
	codename: DATE_SELECTION,
	elements: [
		builder.guidelinesElement({
			codename: 'usage_guidance',
			type: 'guidelines',
			guidelines:
				'<p>This type of campaign question will allow the user to select a date.</p>' +
				'<h5>Usage</h5>' +
				'<ul><li>Question Text: This is the text prompt for the question.  It will be rendered above the date picker.</li>' +
				'<li>Question Identifier: This string is used as the key to the reponses in SFMC. It can contain only alphanumerics, dashes, and underscores.</li>' +
				'</ul>',
		}),
		builder.multipleChoiceElement({
			mode: 'single',
			options: [
				{codename: REQUIRED, name: 'Required'},
				{codename: OPTIONAL, name: 'Optional'},
			],
			name: 'Required or Optional',
			guidelines:
				'indicate if the question will be required or optional.  Required questions will keep the user from submitting the form until they have made a selection.',
			is_required: true,
			type: 'multiple_choice',
			codename: REQUIRED_OR_OPTIONAL,
			default: {global: {value: [{codename: OPTIONAL}]}},
		}),
		builder.richTextElement({
			name: 'Question Text',
			guidelines:
				'The text of the question, to be presented to the user.',
			is_required: true,
			type: 'rich_text',
			codename: QUESTION_TEXT,
		}),
		builder.textElement({
			name: 'Question Identifier',
			guidelines:
				'The identifier used to pass the response to the question in to the campaign',
			is_required: true,
			type: 'text',
			codename: QUESTION_ID,
			validation_regex: {
				regex: '^[A-Za-z0-9_\\-]+$',
				is_active: true,
				validation_message:
					'Question Identifier can contain only alphanumeric characters, dashes and underscores',
			},
		}),
		builder.snippetElement({
			snippet: {codename: 'authentication_gate_component'},
			type: 'snippet',
		}),
		builder.multipleChoiceElement({
			mode: 'single',
			options: [
				{codename: 'yes', name: 'Yes'},
				{codename: 'no', name: 'No'},
			],
			name: 'Hide component on login',
			guidelines:
				'Select the option to hide the component if the user is logged in.',
			is_required: false,
			type: 'multiple_choice',
			codename: 'hide_component_on_login_enabled',
		}),
	],
})

export default SingleSelectionQuestionModel
