import {ResponsiveLinkWithIcon as IconLink} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useRouter} from 'next/router'
import type {BlockWithClassName} from '@/_new-code/services/kontent-ai/types'

export type ResponsiveLinkWithIconContentItem = IContentItem<{
	text: Elements.TextElement
	url: Elements.TextElement
	icon: Elements.AssetsElement
	openInNewTab: Elements.MultipleChoiceElement
}>

export const ResponsiveLinkWithIconBlock: BlockWithClassName<
	ResponsiveLinkWithIconContentItem
> = ({className, block}) => {
	const {locale = ''} = useRouter()
	const isExternalLink = block.elements.openInNewTab[0]?.codename === 'yes'
	const optionalLocalePath = block.elements.url.startsWith('/')
		? `/${locale}`
		: ''

	return (
		<div
			className={className || ''}
			data-kontent-element-codename={block.system.codename}
		>
			<IconLink
				icon={block.elements.icon[0]?.url}
				isExternalLink={isExternalLink}
				text={block.elements.text}
				url={`${optionalLocalePath}${block.elements.url}`}
			/>
		</div>
	)
}
