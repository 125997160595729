import {MetaTags} from '@elanco/component-library-v2'
import Link from 'next/link'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useRouter} from 'next/router'
import {addLocalePrefix} from '@/components/BlockMapper/utils'
import type {Block} from '@/_new-code/services/kontent-ai/types'

export type TagSingleContentItem = IContentItem<{
	title: Elements.TextElement
	tag: Elements.TextElement
	link: Elements.TextElement
	snippetCategoryColorCategoryColor: Elements.MultipleChoiceElement
}>

export const TagSingleBlock: Block<TagSingleContentItem> = ({block}) => {
	const color = block.elements.snippetCategoryColorCategoryColor[0]?.codename
	const singleTagColor = color || 'primary'
	const {locale} = useRouter()

	const metaTagsComponent = (
		<MetaTags
			as="p"
			className="container-narrow w-full"
			headerText={block.elements.title}
			tags={[block.elements.tag]}
			titleAboveTags={Boolean(block.elements.title)}
			titleBorder={Boolean(block.elements.title)}
			variant={singleTagColor}
		/>
	)
	if (block.elements.link) {
		return (
			<Link href={addLocalePrefix(block.elements.link, locale ?? '')}>
				{/* eslint-disable-next-line jsx-a11y/anchor-is-valid -- Necessary for next/link */}
				<a rel="noreferrer">{metaTagsComponent}</a>
			</Link>
		)
	}
	return metaTagsComponent
}
