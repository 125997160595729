interface DatePickerProps {
	value: string
	onChange: (value: string) => void
	id: string
	required: boolean
}

const getDate = (date = new Date()): string => {
	const offset = date.getTimezoneOffset()
	const newDate = new Date(date.getTime() - offset * 60 * 1000)
	const out = newDate.toISOString().split('T')[0]

	if (!out) return ''

	return out
}

export const DatePicker = ({
	value,
	onChange,
	id,
	required,
}: DatePickerProps): JSX.Element => (
	<div className="relative">
		<input
			className="form-control"
			id={id}
			max={getDate()}
			name={id}
			onChange={(e) => {
				onChange(e.target.value)
			}}
			required={required}
			type="date"
			value={value}
		/>
	</div>
)
