import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {ProductHeader, Image} from '@elanco/component-library-v2'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {loadImageFromKontentAI} from '@/imageLoader'
import {RichTextRenderer} from '../../components/rich-text-renderer'

type KeyInfoIconContentItem = IContentItem<{
	image: Elements.AssetsElement
	doseInfo: Elements.TextElement
}>

type KeyInfoPackageContentItem = IContentItem<{
	title: Elements.TextElement
	keyInfoIcons: Elements.LinkedItemsElement<KeyInfoIconContentItem>
}>

type KeyInfoWeightContentItem = IContentItem<{
	title: Elements.TextElement
	image: Elements.AssetsElement
	lowWeight: Elements.TextElement
	highWeight: Elements.TextElement
}>

export type KeyInfoModuleContentItem = IContentItem<{
	title: Elements.TextElement
	image: Elements.AssetsElement
	description: Elements.TextElement
	// The MaC codename is medicationinfo rather than medication_info - how annoying
	medicationinfo: Elements.TextElement
	keyFeatures: Elements.RichTextElement
	additionalTabWeight: Elements.LinkedItemsElement<KeyInfoWeightContentItem>
	additionalTabsDosage: Elements.LinkedItemsElement<KeyInfoPackageContentItem>
}>

export const KeyInfoModuleBlock: Block<KeyInfoModuleContentItem> = ({
	block,
	...context
}) => {
	const {
		title,
		image,
		description,
		medicationinfo: medicationInfo,
		keyFeatures,
		additionalTabWeight,
		additionalTabsDosage: additionalTabDosage,
	} = block.elements

	const formattedWeight = additionalTabWeight.map((weight) => {
		const {
			title: weightTitle,
			image: weightImage,
			lowWeight,
			highWeight,
		} = weight.elements

		return (
			<ProductHeader.Weight
				className="md:pr-6"
				key={weight.system.id}
				title="Weight"
			>
				<p>{weightTitle}</p>
				<div className="mb-2 flex items-center md:justify-around">
					{/* eslint-disable-next-line @next/next/no-img-element -- Not possible due to Component Library styling */}
					<img
						alt="Weight icon"
						className="mb-0 max-w-[112px]"
						src={weightImage[0]?.url}
					/>
				</div>
				<div className="flex md:justify-around">
					<p className="pr-4">{lowWeight}</p>
					<p className="px-4">{highWeight}</p>
				</div>
			</ProductHeader.Weight>
		)
	})

	const formattedDosage = additionalTabDosage.map(({elements, system}) => {
		const {title: dosageTitle, keyInfoIcons} = elements
		return (
			<ProductHeader.Pack
				className="lg:border-l lg:pl-6"
				key={system.id}
				title="Dosage"
			>
				<p>{dosageTitle}</p>
				<div className="mb-2 flex md:justify-around">
					{keyInfoIcons.map((icon) => {
						const currentIconImage = icon.elements.image[0]

						return currentIconImage ? (
							<Image
								// TODO: A11y Lint - If icon is for presentation purposes no alt text should be present
								alt={
									currentIconImage.description ||
									'Dosage Icon'
								}
								className="mb-0 h-16 w-16"
								height={64}
								key={`${icon.system.id}-icon`}
								loader={loadImageFromKontentAI}
								src={currentIconImage.url}
								width={64}
							/>
						) : null
					})}
				</div>
				<div className="flex text-center md:justify-around">
					{keyInfoIcons.map((icon) => (
						<p className="mb-0 pr-4" key={`${icon.system.id}-text`}>
							{icon.elements.doseInfo}
						</p>
					))}
				</div>
			</ProductHeader.Pack>
		)
	})

	const tabSections = formattedWeight.concat(formattedDosage).slice(0, 2)
	const hasPageSecondaryConfig =
		(
			context.page.elements.headerConfig[0]?.elements
				.secondaryHeaderConfig ?? []
		).length > 0
	const hasGlobalConfigSecondaryHeader =
		(
			context.globalConfig.elements.headerConfig[0]?.elements
				.secondaryHeaderConfig ?? []
		).length > 0
	const hasSecondaryOnPageNav =
		hasPageSecondaryConfig || hasGlobalConfigSecondaryHeader
	const addTopMarginOffsetIfNavPresent = hasSecondaryOnPageNav
		? 'mt-16 md:mt-0'
		: ''
	// Casting the type here because in some towers the field doesn't exist.
	const medicationInfoArray = (medicationInfo as string | undefined)
		?.split(/\s+/)
		?.filter((word) => word !== '') // Split and filter out empty strings

	return (
		<ProductHeader className={addTopMarginOffsetIfNavPresent} expandFirst>
			{image[0] ? (
				<Image
					alt={image[0].description}
					height={image[0].height ?? 400}
					src={image[0].url}
					width={image[0].width ?? 400}
				/>
			) : null}
			{/* TODO: A11y Lint - Is this really the first and only h1 on the page? */}
			<h1>{title}</h1>
			<div>
				<p className="mr-0 md:mr-8">{description}</p>
				<p className="flex flex-wrap">
					{medicationInfoArray?.map((text, index) => (
						<span
							className="m-1 border-2 border-black p-1"
							// eslint-disable-next-line react/no-array-index-key -- no unique key available here
							key={`${text}-${index}`}
						>
							{text}
						</span>
					))}
				</p>
			</div>
			{/* TODO: A11y Lint - Sections have semantic meaning, given that's not being used here this should just be a div */}
			<section>
				<RichTextRenderer element={keyFeatures} {...context} />
			</section>
			{tabSections.length > 0 && tabSections[0]}
			{tabSections.length > 1 && tabSections[1]}
		</ProductHeader>
	)
}
