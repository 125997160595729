import {
	Card as ClCard,
	CardFooter,
	CardImage,
} from '@elanco/component-library-v2'
import Link from 'next/link'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import {spacing} from '@/_new-code/products/flexible-web-toolkit/styles'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {ReusableCTAButtonComponent} from '../cta-button'

export type CardContentItem = IContentItem<{
	image: Elements.AssetsElement
	title: Elements.TextElement
	text: Elements.TextElement
	buttonText: Elements.TextElement
	buttonLink: Elements.TextElement
	openInNewTab: Elements.MultipleChoiceElement
	snippetSpacingSpacing: Elements.MultipleChoiceElement
	variant: Elements.MultipleChoiceElement
}>

export const CardBlock: Block<CardContentItem> = ({block, ...context}) => {
	const textLines = block.elements.text
		.split(/\n/)
		.filter((element) => element)
	const spacingChoice =
		spacing[
			block.elements.snippetSpacingSpacing[0]
				?.codename as keyof typeof spacing
		]
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not unnecessary, spacingChoice may be null
	const componentSpacing = !spacingChoice ? spacing.none : spacingChoice

	return (
		<ClCard
			LinkComponent={Link}
			className={clsx(
				'h-full !w-[21rem] break-words text-black',
				componentSpacing
			)}
			title={
				<span data-kontent-element-codename="card_title">
					{block.elements.title}
				</span>
			}
		>
			{block.elements.image[0] ? (
				<CardImage
					alt={block.elements.image[0]?.description}
					className="h-48 object-cover"
					data-kontent-element-codename="image"
					src={block.elements.image[0]?.url}
				/>
			) : null}
			{textLines.length > 0 && (
				<div data-kontent-element-codename="card_text">
					{textLines.map((text, index) => (
						<p
							className={
								textLines.length - 1 !== index ? 'mb-3' : ''
							}
							key={text}
						>
							{text}
						</p>
					))}
				</div>
			)}
			{block.elements.buttonText ? (
				<CardFooter>
					<ReusableCTAButtonComponent
						block={{
							elements: {
								icon: [],
								openLinkInNewTab: block.elements.openInNewTab,
								preserveQueryString: [],
								text: block.elements.buttonText,
								url: block.elements.buttonLink,
								variant: block.elements.variant,
								authenticationGateComponentUserRoles: [],
								snippetSpacingSpacing: [],
							},
							system: {
								codename: '',
								id: '',
								type: 'cta_button',
								name: '',
							},
						}}
						className="w-full"
						data-kontent-element-codename="button_text"
						{...context}
					/>
				</CardFooter>
			) : null}
		</ClCard>
	)
}
