import {useRouter} from 'next/router'
import {useEffect, useRef, useState} from 'react'
import type {CampaignData} from '@elanco/gateway-utils'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {sendScreenSetEventToDataLayer} from '@/utils/analytics'
import {addLocalePrefix} from '@/components/BlockMapper/utils'
import type {
	Block,
	Tersed,
	CampaignConfigContentITem,
} from '@/_new-code/services/kontent-ai/types'
import {logError, logMessage, logEvent} from '@/services/client-logger'
import type {CdcGigyaContentItem} from '../../auth/cdc-screensets/basic'
import {CdcGigyaBlock} from '../../auth/cdc-screensets/basic'
import type {
	OnSubmitEvent,
	OnBeforeValidationEvent,
	OnBeforeSubmitEvent,
} from '../../auth/cdc-screensets/models'
import {setErrorLabel, FormSubmitErrorLabel} from './form-elements'
import {CDCScreenSetValidator} from './cdc-screenset-validator'
import {getBusinessData} from './business-search'
import {handleUTMQueryItem} from './cdc-screenset-helper'
import {CampaignInterestHandler} from './campaign-questions/campaign-interest-handler'
import {injectIntoScreenset} from './campaign-questions/campaign-question-injector'
import type {
	SingleSelectionContentItem,
	MultipleSelectionContentItem,
	DateSelectionContentItem,
} from './models'

export type CdcScreenSetWrapperContentItem = IContentItem<{
	wrappedScreenset: Elements.LinkedItemsElement<CdcGigyaContentItem>
	redirectPathOverride: Elements.TextElement
	campaignQuestions: Elements.LinkedItemsElement<
		| SingleSelectionContentItem
		| MultipleSelectionContentItem
		| DateSelectionContentItem
	>
	tabTitle: Elements.TextElement
	bsNoResultText: Elements.TextElement
	formSubmissionGenericErrorMessage: Elements.TextElement
	customerLeavePageMessage: Elements.TextElement
	disableFormScrollingAfterSubmit: Elements.MultipleChoiceElement
}>

export const CdcScreenSetWrapperBlock: Block<
	CdcScreenSetWrapperContentItem
> = ({
	block: {
		elements: {
			bsNoResultText,
			campaignQuestions,
			redirectPathOverride,
			formSubmissionGenericErrorMessage,
			customerLeavePageMessage, // This message will be visible if the user attempts to navigate away from the form whilst the form is submitting
			disableFormScrollingAfterSubmit,
			wrappedScreenset: [wrappedScreenset],
		},
		system: {id, type},
	},
	page,
	...context
}) => {
	const router = useRouter()
	const {events, query, locale = ''} = router
	const screensetValidator = new CDCScreenSetValidator()
	const beforeUnloadEventName = 'beforeunload'
	const beforeRouteUnloadEventName = 'routeChangeStart'
	const disableFormScrolling =
		disableFormScrollingAfterSubmit[0]?.codename === 'yes'
	const campaignInterestHandler = new CampaignInterestHandler()
	const containsCampaignQuestions = campaignQuestions.length !== 0
	const cdcScreenSetHandlerRef = useRef(null as HTMLDivElement | null)
	const holdRedirectUntilInterestApiFinished = campaignQuestions.length !== 0
	const [attachBeforeUnloadEvent, setAttachBeforeUnloadEvent] = useState<
		boolean | null
	>(null)
	const [campaignInterestSuccess, setCampaignInterestSuccess] = useState<
		boolean | null
	>(null)
	const displayUnloadEventAlert = useRef(false) // using to prevent double call of routeChangeStart event

	const handleBeforeUnloadEvent = (e: BeforeUnloadEvent): boolean => {
		e.preventDefault()
		return true
	}

	const handleRouteChangeEvent = (): void => {
		if (attachBeforeUnloadEvent) {
			if (displayUnloadEventAlert.current) {
				displayUnloadEventAlert.current = false
				// eslint-disable-next-line no-alert -- confirmation to user is required
				if (!confirm(customerLeavePageMessage)) {
					logError(
						new Error(
							'Route change Aborted by the user via confirmation dialog for campaign form'
						)
					)
					events.emit('routeChangeError')
					throw new Error('Route change aborted by user') // required to prevent navigation
				}
			} else {
				logError(
					new Error(
						'Route change Aborted :unload event alert is not true for campaign form'
					)
				)
				events.emit('routeChangeError')
				throw new Error('Route change aborted by user') // required to prevent navigation
			}
		}
	}

	useEffect(() => {
		if (attachBeforeUnloadEvent) {
			displayUnloadEventAlert.current = true
			window.addEventListener(
				beforeUnloadEventName,
				handleBeforeUnloadEvent
			)
			events.on(beforeRouteUnloadEventName, handleRouteChangeEvent)

			return () => {
				window.removeEventListener(
					beforeUnloadEventName,
					handleBeforeUnloadEvent
				)
				events.off(beforeRouteUnloadEventName, handleRouteChangeEvent)
			}
		}
		window.removeEventListener(
			beforeUnloadEventName,
			handleBeforeUnloadEvent
		)
		events.off(beforeRouteUnloadEventName, handleRouteChangeEvent)
		// eslint-disable-next-line react-hooks/exhaustive-deps -- This block have single dependency on attachBeforeUnloadEvent
	}, [attachBeforeUnloadEvent])

	useEffect(() => {
		if (campaignInterestSuccess !== null) {
			if (!campaignInterestSuccess) {
				logError(
					new Error(
						`Campaign Form submision error${formSubmissionGenericErrorMessage}`
					)
				)
				setErrorLabel(
					FormSubmitErrorLabel(),
					formSubmissionGenericErrorMessage
				)
			} else if (redirectPathOverride) {
				void router.push(
					addLocalePrefix(redirectPathOverride, router.locale ?? '')
				)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps -- This block have single dependency on campaignInterestSuccess
	}, [campaignInterestSuccess])

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- Implicit is better here
	const onSubmit = (event: OnSubmitEvent) => {
		const businessSearchData = getBusinessData()
		const business = businessSearchData ?? event.formModel.data.business

		const data = {
			...event.formModel.data,
			business,
		}
		logMessage('Successfully fetched business data for campaign form.')
		// Injected elements must be removed from profile object or CDC will error
		const injectedElements = campaignInterestHandler.getInjectedElements()
		logMessage('fetched injected elements for removal for campaign form')
		const profile = containsCampaignQuestions
			? Object.fromEntries(
					Object.entries(event.formModel.profile).filter(
						([key, _value]) => {
							return !injectedElements.includes(key)
						}
					)
				)
			: event.formModel.profile

		return {...event.formModel, profile, data}
	}

	const onBeforeValidation = (_event: OnBeforeValidationEvent): void => {
		if (containsCampaignQuestions) {
			campaignInterestHandler.validate()
		}

		screensetValidator.validate()
	}

	const onAfterScreenLoad = (_event: OnAfterScreenLoadEvent): void => {
		screensetValidator.setup(locale, bsNoResultText)
		if (containsCampaignQuestions) {
			injectIntoScreenset(id, campaignQuestions, campaignInterestHandler)
		}
	}

	const onBeforeSubmit = (_event: OnBeforeSubmitEvent): boolean => {
		if (containsCampaignQuestions && !campaignInterestHandler.isValid()) {
			logMessage('Campaign interest is not valid')
			return false
		}

		return screensetValidator.isValid()
	}

	const onAfterSubmit = async (
		event: OnAfterSubmitEvent
	): Promise<boolean> => {
		logMessage('Campaign Form Submitted Suceessfully')
		const cdcScreenSetHandlerElement = cdcScreenSetHandlerRef.current
		if (!disableFormScrolling && cdcScreenSetHandlerElement) {
			const {top} = cdcScreenSetHandlerElement.getBoundingClientRect()
			window.scrollTo({
				top: window.scrollY + top - 270,
				behavior: 'smooth',
			})
		}
		const {UID} = event.response
		const {response, form} = event
		const medium = handleUTMQueryItem(query.utm_medium) ?? ''
		const source = handleUTMQueryItem(query.utm_source) ?? ''
		const pageConfig = page.elements.config[0]
		let campaignSubmissionResponse = true

		const campaignId =
			pageConfig && 'campaignId' in pageConfig.elements
				? (pageConfig as Tersed<CampaignConfigContentITem>).elements
						.campaignId
				: null

		const blockCodename = type

		if (campaignId) {
			setAttachBeforeUnloadEvent(true)
			logEvent({
				name: 'ATTACH_BEFORE_UNLOAD_EVENT_ENABLED_FOR_CAMPAIGN_FORM',
				properties: {
					campaignId,
				},
			})
			const data: CampaignData = {
				ContactId: String(UID),
				campaignId,
				medium,
				source,
				data: {},
				typeOfCampaign: 'B2B',
			}
			campaignSubmissionResponse =
				await campaignInterestHandler.send(data)
			setCampaignInterestSuccess(campaignSubmissionResponse)
			logEvent({
				name: 'CAMPAIGN_INTEREST_DATA_SENT',
				properties: {
					campaignId,
					contactId: String(UID),
					medium,
					source,
				},
			})
			setAttachBeforeUnloadEvent(false)

			if (form === 'gigya-subscribe-with-email-form') {
				sendScreenSetEventToDataLayer(
					response,
					form,
					blockCodename,
					campaignId
				)
			}
		}
		logEvent({
			name: 'CAMPAIGN_SUBMISSION_COMPLETED',
			properties: {
				campaignSubmissionResponse,
				formName: form,
			},
		})
		return campaignSubmissionResponse
	}

	const eventListeners = {
		onSubmit,
		onBeforeValidation,
		onAfterScreenLoad,
		onBeforeSubmit,
		onAfterSubmit,
	}

	const redirectPath = redirectPathOverride
		? redirectPathOverride
		: wrappedScreenset?.elements.redirectPath

	if (wrappedScreenset) {
		wrappedScreenset.elements.redirectPath = redirectPath ?? ''
	}

	return (
		<div ref={cdcScreenSetHandlerRef}>
			{wrappedScreenset ? (
				<>
					<CdcGigyaBlock
						block={wrappedScreenset}
						eventRegistration={eventListeners}
						holdRedirectUntilInterestApiFinished={
							holdRedirectUntilInterestApiFinished
						}
						page={page}
						{...context}
					/>
					<span className="campaign-form-submit-error-message block text-center text-sm font-bold leading-5 text-red-500" />
				</>
			) : null}
		</div>
	)
}
