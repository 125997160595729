/* eslint-disable no-param-reassign */
import {Reminder} from '@/fetchers/fetchReminders'
import {
	DEFAULT_PRODUCT_REMINDER_FREQUENCY,
	PRODUCTS_REMINDER_FREQUENCY_MONTHS,
} from './reminders'

export function getUTCTime() {
	const now = new Date()
	const UTCTimeObject = new Date(
		Date.UTC(
			now.getUTCFullYear(),
			now.getUTCMonth(),
			now.getUTCDate(),
			now.getUTCHours(),
			now.getUTCMinutes(),
			now.getUTCSeconds(),
			now.getUTCMilliseconds()
		)
	)
	return UTCTimeObject.toUTCString()
}

export function safeStringify(obj: any): string {
	try {
		return JSON.stringify(obj)
	} catch (err: any) {
		return err.message || 'Object could not be serialized'
	}
}

const getTimezoneDate = (date: Date): Date => {
	const tz = date.getTimezoneOffset()
	const hours = date.getHours()
	const mins = date.getMinutes()
	const tzHours = tz / 60
	const tzMins = tz % 60
	if (tz > 0) {
		date.setHours(hours + tzHours, mins + tzMins)
	}
	return date
}

const formatDate = (date: Date): string => {
	const d = getTimezoneDate(new Date(date))

	let month: number | string = d.getMonth() + 1
	let day: number | string = d.getDate()
	const year = d.getFullYear()

	if (month <= 9) month = `0${month}`
	if (day <= 9) day = `0${day}`

	return [month, day, year].join('/')
}

interface ReminderResponse {
	pagination: {limit: number; offset: number; totalPages: number}
	data: Reminder[]
}

export const addRecurring = (
	remindersData: ReminderResponse,
	futureMonths: number,
	pastMonths: number
): ReminderResponse => {
	const {data} = remindersData
	const now = new Date()
	data.forEach((result) => {
		result.recurringDates = []
		result.pastDates = []
		result.serestoRefillDate = []
		let originalDate = null
		if (result.date !== undefined) {
			originalDate = new Date(result.date)
		} else {
			originalDate = new Date(result.startDate)
		}

		if (
			originalDate > now ||
			originalDate.toLocaleDateString() === now.toLocaleDateString()
		) {
			result.recurringDates.push(formatDate(originalDate))
		}

		const monthsFrequency =
			(result.marketingBrand?.id &&
				Number(
					// @ts-expect-error -- who even knows how this works
					PRODUCTS_REMINDER_FREQUENCY_MONTHS[result.marketingBrand.id]
				)) ||
			DEFAULT_PRODUCT_REMINDER_FREQUENCY

		const originalSerestoDate = new Date(result.date)
		originalSerestoDate.setDate(originalSerestoDate.getDate() + 210)
		result.serestoRefillDate.push(formatDate(originalSerestoDate))

		const day = originalDate.getDate()
		let i = monthsFrequency
		while (result.recurringDates.length < futureMonths) {
			const nD = new Date(originalDate)
			if (nD === undefined || i === 100) {
				break
			}

			const monthsToAdd = originalDate.getMonth() + i
			nD.setMonth(monthsToAdd)

			if (nD.getDate() !== day) {
				nD.setDate(0)
			}
			if (nD > now) {
				result.recurringDates.push(formatDate(nD))
			} else {
				result.pastDates.push(formatDate(nD))
			}

			i += monthsFrequency
		}
		if (result.pastDates.length > pastMonths) {
			result.pastDates.splice(0, result.pastDates.length - pastMonths)
		}
		if (
			result.pastDates.length < pastMonths &&
			originalDate < now &&
			originalDate.toLocaleDateString() !== now.toLocaleDateString()
		) {
			result.pastDates.push(formatDate(new Date(originalDate.getTime())))
		}
	})
	return remindersData
}

export const sortItemsAsc = (
	a: string | number,
	b: string | number
): number => {
	if (a < b) {
		return 1
	}
	if (a > b) {
		return -1
	}
	return 0
}

export const convertDateToLocale = (date: string): string => {
	// We provide a date/time string so the date is parsed to the local timezone by JS
	const newDate = new Date(`${date}T00:00:00`)
	return newDate.toLocaleDateString('en-US', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	})
}

export const convertDateToLocaleDatePicker = (date: string | Date): string => {
	if (!date) return ''

	// We provide a date/time string so the date is parsed to the local timezone by JS

	const newDate = date instanceof Date ? date : new Date(`${date}T00:00:00`)

	const month: string = `0${newDate.getMonth() + 1}`.slice(-2)
	const day: string = `0${newDate.getDate()}`.slice(-2)
	const year: number = newDate.getFullYear()

	return [year, month, day].join('-')
}
