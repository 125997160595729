import {ShareBlockStatic as ShareBlockStaticCL} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'

export type ShareBlockStaticContentItem = IContentItem<{
	title: Elements.TextElement
	urlToShare: Elements.TextElement
	emailSubject: Elements.TextElement
	emailBody: Elements.TextElement
	showFacebook: Elements.MultipleChoiceElement
	showTwitter: Elements.MultipleChoiceElement
	showLinkedIn: Elements.MultipleChoiceElement
	showEmail: Elements.MultipleChoiceElement
}>

export const ShareBlockStaticBlock: Block<ShareBlockStaticContentItem> = ({
	block,
}) => {
	return (
		<ShareBlockStaticCL
			mailBody={block.elements.emailBody}
			mailSubject={block.elements.emailSubject}
			showEmail={block.elements.showEmail[0]?.codename === 'yes'}
			showFacebook={block.elements.showFacebook[0]?.codename === 'yes'}
			showLinkedIn={block.elements.showLinkedIn[0]?.codename === 'yes'}
			showTwitter={block.elements.showTwitter[0]?.codename === 'yes'}
			title={block.elements.title}
			urlToShare={block.elements.urlToShare}
		/>
	)
}
