import {useState} from 'react'
import ScaleLoader from 'react-spinners/ScaleLoader'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import type {Block} from '@/_new-code/services/kontent-ai/types'

export type JotFormContentItem = IContentItem<{
	tabTitle: Elements.TextElement
	jotformId: Elements.TextElement
	height: Elements.TextElement
}>

export const JotFormBlock: Block<JotFormContentItem> = ({block}) => {
	const [isLoaded, setIsLoaded] = useState(false)

	// Function to sanitize and validate height
	const sanitizeHeight = (height: string | undefined): string => {
		if (!height) return '800px'

		// Check if height ends with "px"
		if (!height.endsWith('px')) return '800px'

		const parsedHeight = parseInt(height, 10)
		return isNaN(parsedHeight) || parsedHeight <= 0
			? '800px'
			: `${parsedHeight}px` // Validate and return with "px"
	}
	const formHeight = sanitizeHeight(block.elements.height)

	return (
		<div>
			{!isLoaded && (
				<div className="flex w-full justify-center py-6">
					<ScaleLoader
						color="#02253e"
						height={40}
						loading
						margin={5}
						width={4}
					/>
				</div>
			)}
			<iframe
				allow="geolocation; microphone; camera"
				allowFullScreen
				className={clsx(!isLoaded && 'hidden')}
				frameBorder="0"
				id={`JotFormIFrame-${block.elements.jotformId}`}
				onLoad={() => {
					setIsLoaded(true)
				}}
				src={`https://elancoah.jotform.com/${block.elements.jotformId}`}
				style={{
					minWidth: '100%',
					maxHeight: '100%',
					height: formHeight,
					border: 'none',
					overflow: 'scroll',
				}}
				title={`JotForm IFrame ${block.elements.jotformId}`}
			/>
		</div>
	)
}
