import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {Button, ButtonIcons} from '@elanco/component-library-v2'
import {useRouter} from 'next/router'
import {spacing} from '@/_new-code/products/flexible-web-toolkit/styles'
import type {Block} from '@/_new-code/services/kontent-ai/types'

export type CTAButtonEmailContentItem = IContentItem<{
	text: Elements.TextElement
	emailRecipient: Elements.TextElement
	emailCc: Elements.TextElement
	emailSubject: Elements.TextElement
	emailBody: Elements.TextElement
	variant: Elements.MultipleChoiceElement
	icon: Elements.MultipleChoiceElement
	snippetSpacingSpacing: Elements.MultipleChoiceElement
}>

export const CTAButtonEmailBlock: Block<CTAButtonEmailContentItem> = ({
	block,
}) => {
	const {locale} = useRouter()
	const spacingChoice =
		spacing[
			block.elements.snippetSpacingSpacing[0]
				?.codename as keyof typeof spacing
		]
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not unnecessary, spacingChoice may be null
	const componentSpacing = !spacingChoice ? spacing.default : spacingChoice

	const emailCc = block.elements.emailCc
	const emailSubject = block.elements.emailSubject
	const emailBody = block.elements.emailBody
	const emailRecipient = block.elements.emailRecipient

	const cc = encodeURIComponent(emailCc)
	const subject = encodeURIComponent(emailSubject)
	const body = encodeURIComponent(emailBody)

	// Construct the mailto link
	const href = `mailto:${emailRecipient}?cc=${cc}&subject=${subject}&body=${body}`

	return (
		<div className={`${componentSpacing} container-narrow text-center`}>
			<Button
				as="a"
				data-kontent-element-codename="text"
				href={href}
				icon={
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO: fix component library types
					ButtonIcons[
						// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- TODO: fix component library types
						block.elements.icon[0]?.name as keyof typeof ButtonIcons
					]
				}
				locale={locale}
				variant={block.elements.variant[0]?.codename ?? 'primary'}
			>
				{block.elements.text}
			</Button>
		</div>
	)
}
