import {FactListerBlock as FactLister} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import type {CtaButtonContentItem} from '../cta-button'

type FactBlockContentItem = IContentItem<{
	title: Elements.TextElement
	subTitle: Elements.TextElement
}>

export type FactListerBlockContentItem = IContentItem<{
	title: Elements.TextElement
	body: Elements.RichTextElement
	ctaButton: Elements.LinkedItemsElement<CtaButtonContentItem>
	factsList: Elements.LinkedItemsElement<FactBlockContentItem>
}>

export const FactListerBlock: Block<FactListerBlockContentItem> = ({block}) => {
	return (
		<FactLister
			body={block.elements.body.value}
			buttonCta={block.elements.ctaButton[0]}
			facts={block.elements.factsList.map((fact) => ({
				title: fact.elements.title,
				sub_title: fact.elements.subTitle,
			}))}
			title={block.elements.title}
		/>
	)
}
