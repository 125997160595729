import {useEffect, useState} from 'react'
import type {IContentItem} from '@kontent-ai/delivery-sdk'
import type {ExtendedBlock, Tersed} from '@/_new-code/services/kontent-ai/types'
import type {
	PetTraitContentItem,
	PetTraitGroupContentItem,
} from '@/_new-code/products/pet-name-finder/models'
import {useWindowSize} from '@/_new-code/utilities/hooks/use-window-size'
import type {TraitsByType} from './pet-trait-card'
import {PetTraitCardBlock} from './pet-trait-card'
import {CardWrapper} from './card-wrapper'

interface PetNameFormProps {
	traitGroup: Tersed<PetTraitGroupContentItem>
	selectedTraitsByGroup: TraitsByType
	toggleTrait: (
		traitName: Tersed<PetTraitContentItem>,
		groupTitle: string
	) => void
}

export const PetTraitGroupCardBlock: ExtendedBlock<
	IContentItem,
	PetNameFormProps
> = ({
	traitGroup,
	selectedTraitsByGroup,
	toggleTrait,
	...context
}): JSX.Element => {
	const size = useWindowSize()
	const {isMobile} = size
	const [columns, setColumns] = useState(2)

	useEffect(() => {
		setColumns(
			isMobile || traitGroup.elements.traitBlocks.length === 2 ? 2 : 3
		)
	}, [isMobile, traitGroup.elements.traitBlocks.length])

	return (
		<CardWrapper title={traitGroup.elements.displayTitle}>
			<ul
				className="flex max-w-[515px] flex-wrap justify-center"
				style={{columns}}
			>
				{traitGroup.elements.traitBlocks.map((trait) => {
					const selectedTraits =
						selectedTraitsByGroup[traitGroup.elements.displayTitle]

					const selectedTraitDisplayName =
						selectedTraits?.elements.displayName

					const isActive =
						selectedTraitDisplayName === trait.elements.displayName
					const isUnselected =
						selectedTraitDisplayName !== trait.elements.displayName

					return (
						<li
							className="mb-5 flex justify-center px-4"
							key={trait.elements.displayName}
							style={{
								flexBasis:
									columns === 2 ? '50%' : '33.3333333%',
							}}
						>
							<PetTraitCardBlock
								{...context}
								block={trait}
								isActive={isActive}
								key={trait.elements.displayName}
								onClick={(
									selectedTrait: Tersed<PetTraitContentItem>
								) => {
									toggleTrait(
										selectedTrait,
										traitGroup.elements.displayTitle
									)
								}}
								unselected={Boolean(
									selectedTraitDisplayName && isUnselected
								)}
							/>
						</li>
					)
				})}
			</ul>
		</CardWrapper>
	)
}
