interface QuestionProps {
	counter: number
	questionText: React.ReactNode
}

export const Question = ({
	counter,
	questionText,
}: QuestionProps): JSX.Element => {
	return (
		<div>
			<div className="mb-2 italic">
				<span>{`Question ${counter}`}</span>
			</div>
			<h3 className="mb-4">{questionText}</h3>
		</div>
	)
}
