import {Coupon} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'

export type CouponContentItem = IContentItem<{
	title: Elements.TextElement
	description: Elements.TextElement
	buttonText: Elements.TextElement
	footerText: Elements.TextElement
	logo: Elements.AssetsElement
	image: Elements.AssetsElement
	url: Elements.TextElement
	brandColor: Elements.MultipleChoiceElement
}>

export const CouponBlock: Block<CouponContentItem> = ({block}) => {
	const color = block.elements.brandColor[0]?.codename
	const buttonCTAcolor = color ? `bg-${color}` : 'bg-primary'

	return (
		<Coupon
			buttonText={block.elements.buttonText}
			buttonUrl={block.elements.url}
			description={block.elements.description}
			footerText={block.elements.footerText}
			imageUrl={block.elements.image[0]?.url}
			logoUrl={block.elements.logo[0]?.url}
			title={block.elements.title}
			variant={buttonCTAcolor}
		/>
	)
}
