import {useEffect, useState} from 'react'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block, Tersed} from '@/_new-code/services/kontent-ai/types'
import type {
	RebatesTableContentItem,
	RebatesTableRowContentItem,
} from './rebates-table'
import {RebatesTableBlock} from './rebates-table'
import type {FilterType, ModuleRebatesFilterContentItem} from './rebates-filter'
import {ModuleRebatesFilterBlock, filter} from './rebates-filter'
import type {ModuleRebatesHeaderSearchContentItem} from './rebates-header-search'
import {ModuleRebatesHeaderSearchBlock} from './rebates-header-search'
import type {ModuleRebatesHeaderContentContentItem} from './rebates-header-content'
import {ModuleRebatesHeaderContentBlock} from './rebates-header-content'

export type RebatesTableAndFilterContentItem = IContentItem<{
	rebatesHeaderContent: Elements.LinkedItemsElement<ModuleRebatesHeaderContentContentItem>
	rebatesHeaderSearch: Elements.LinkedItemsElement<ModuleRebatesHeaderSearchContentItem>
	rebatesFilter: Elements.LinkedItemsElement<ModuleRebatesFilterContentItem>
	rebatesTableParent: Elements.LinkedItemsElement<RebatesTableContentItem>
}>

export const RebatesTableAndFilterBlock: Block<
	RebatesTableAndFilterContentItem
> = ({
	block: {
		elements: {
			rebatesFilter,
			rebatesHeaderContent,
			rebatesHeaderSearch,
			rebatesTableParent,
		},
	},
	...context
}) => {
	const [searchTerm, setSearchTerm] = useState<string>('')
	const [activeType, setActiveType] = useState<FilterType>(filter.ALL)
	const [activeBrand, setActiveBrand] = useState<string>('')
	const [rebates, setRebates] = useState([
		...(rebatesTableParent[0]?.elements.rebatesTableData ?? []),
	])

	const filterRebates = (
		rawData: Tersed<RebatesTableRowContentItem>[]
	): Tersed<RebatesTableRowContentItem>[] => {
		let data = rawData
		if (activeType !== filter.ALL) {
			data = data.filter((item) =>
				item.elements.type
					.map(({codename}) => codename)
					.includes(activeType)
			)
		}

		if (activeBrand) {
			data = data.filter((item) =>
				item.elements.brand.some(
					({codename}) => codename === activeBrand
				)
			)
		}

		if (searchTerm) {
			const termToUppercase = searchTerm.toUpperCase()
			data = data.filter((item) =>
				item.elements.offerCode.includes(termToUppercase)
			)
		}
		return data
	}

	useEffect(() => {
		const filteredData = filterRebates(
			rebatesTableParent[0]?.elements.rebatesTableData ?? []
		)
		setRebates(filteredData)
		// eslint-disable-next-line react-hooks/exhaustive-deps -- Disallow due to scoping
	}, [activeType, activeBrand])

	const handleSearch = (): void => {
		const searchResults = filterRebates(
			rebatesTableParent[0]?.elements.rebatesTableData ?? []
		)
		setRebates(searchResults)
		const element = document.getElementById('rebates-table')
		if (element) element.scrollIntoView({behavior: 'smooth'})
	}

	return (
		<div className="container-wide">
			<div className="grid grid-cols-1 place-content-center place-items-center md:grid-cols-2">
				{rebatesHeaderContent[0] ? (
					<ModuleRebatesHeaderContentBlock
						block={rebatesHeaderContent[0]}
						{...context}
					/>
				) : null}
				{rebatesHeaderSearch[0] ? (
					<ModuleRebatesHeaderSearchBlock
						block={rebatesHeaderSearch[0]}
						onSearch={() => {
							handleSearch()
						}}
						searchTerm={searchTerm}
						setSearchTerm={setSearchTerm}
						{...context}
					/>
				) : null}
			</div>
			{rebatesFilter[0] ? (
				<ModuleRebatesFilterBlock
					activeBrand={activeBrand}
					activeType={activeType}
					block={rebatesFilter[0]}
					setActiveBrand={setActiveBrand}
					setActiveType={setActiveType}
					{...context}
				/>
			) : null}
			{rebatesTableParent[0] ? (
				<RebatesTableBlock
					block={rebatesTableParent[0]}
					rebates={rebates}
					{...context}
				/>
			) : null}
		</div>
	)
}
