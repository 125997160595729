import type {
	BrandConfigContentItem,
	Tersed,
	PageConfigContentItem,
} from '@/_new-code/services/kontent-ai/types'

export function getBrandColor(
	config: Tersed<PageConfigContentItem>
): string | null {
	if (!('color' in config.elements)) return null

	const brandConfig = config as Tersed<BrandConfigContentItem>

	return brandConfig.elements.color[0]?.codename ?? null
}
