export interface MapEntity {
	entityId: string
	entity: {
		ELA_customer_id__c: string
		corporate_name__v: string
		addresses__v: {
			formatted_address__v: string
		}[]
	}
}

interface Response {
	entities: MapEntity[]
}

async function search(
	q = '*',
	offset = 0,
	classification = '',
	lat = '',
	lng = '',
	dist = '10mi'
): Promise<Response> {
	try {
		const response = await fetch('/api/vet', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				q,
				offset,
				classification,
				lat,
				lng,
				dist,
			}),
		})

		return (await response.json()) as any
	} catch (e) {
		throw new Error("Couldn't fetch data")
	}
}

async function searchLatLng(
	lat = '',
	lng = '',
	classification = ''
): Promise<Response> {
	return search('*', 0, classification, lat, lng)
}

export default {
	search,
	searchLatLng,
}
