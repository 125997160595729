import {FC} from 'react'
import {useFormContext} from 'react-hook-form'
import {ErrorMessage} from '../Form'

interface ChoiceFieldProps {
	type: string
	label: string
	name: string
	id: string
	value: string
	className?: string
	isOptional?: boolean
	requiredErrorMsg?: string
	checked?: boolean
	onChange?: (value: string) => void
	disabled?: boolean
	isGroup: boolean
}

const ChoiceField: FC<ChoiceFieldProps> = ({
	className = '',
	checked = false,
	type,
	label,
	name,
	id,
	value,
	isOptional = false,
	requiredErrorMsg = '',
	isGroup,
	onChange = () => {},
	disabled = false,
}) => {
	const requiredMsg = requiredErrorMsg || `${label} is required`
	const {register, formState} = useFormContext()
	const {onChange: libOnChange, ...registerProps} = register(name, {
		required: !isOptional,
	})

	return (
		<div className={`${isGroup ? 'my-1' : 'mb-4'} relative ${className}`}>
			<input
				{...registerProps}
				onChange={(e) => {
					libOnChange(e)
					onChange(e.target.value)
				}}
				defaultChecked={checked}
				disabled={disabled}
				type={type}
				id={id}
				name={name}
				className={`${
					formState.errors[name] ? 'invalid' : ''
				} hover:!bg-white`}
				value={value}
			/>
			<label
				htmlFor={id}
				style={{marginTop: '0', marginBottom: '0'}}
				className="w-full !text-sm text-pet-primary before:!border before:!border-light-grey after:!left-[9px] after:!top-[9px] after:!h-[14px] after:!w-[14px] after:!bg-pet-green"
			>
				<span>
					{isGroup || isOptional ? (
						label
					) : (
						<>
							{label}
							<span className="text-red-600">*</span>
						</>
					)}
				</span>
			</label>
			{!isGroup && formState.errors[name] && (
				<ErrorMessage message={requiredMsg} />
			)}
		</div>
	)
}

export default ChoiceField
