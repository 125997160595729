import {clsx} from 'clsx'
import {useState} from 'react'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {spacing} from '@/_new-code/products/flexible-web-toolkit/styles'
import type {CdcGigyaContentItem} from '@/_new-code/products/auth/cdc-screensets/basic'
import {CdcGigyaBlock} from '@/_new-code/products/auth/cdc-screensets/basic'

export type ModuleCdcTabBlockContentItem = IContentItem<{
	snippetSpacingSpacing: Elements.MultipleChoiceElement
	tabbedComponents: Elements.LinkedItemsElement<CdcGigyaContentItem>
}>

export const ModuleCdcTabBlockBlock: Block<ModuleCdcTabBlockContentItem> = ({
	block,
	...context
}) => {
	const [activeTab, setActiveTab] = useState(0)
	const componentSpacing =
		spacing[
			(block.elements.snippetSpacingSpacing[0]?.codename ??
				'default') as keyof typeof spacing
		]

	return (
		<div className={clsx(`${componentSpacing} container-wide`)}>
			<div className="relative flex flex-row rounded-t-lg bg-gray-100">
				{block.elements.tabbedComponents.map((tab, index) => {
					const isFirstItem = index === 0 ? 'rounded-tl-lg' : ''
					const isLastItem =
						index + 1 === block.elements.tabbedComponents.length
							? 'rounded-tr-lg'
							: ''
					const isActiveTab =
						activeTab === index
							? 'bg-pet-green'
							: 'hover:bg-gray-200'

					return (
						<div
							className={clsx(
								`flex flex-1 ${isFirstItem} ${isLastItem} ${isActiveTab}`
							)}
							key={tab.system.id}
						>
							<button
								className={clsx(
									`flex w-full justify-start p-4 font-semibold ${
										activeTab === index
											? 'text-white'
											: 'text-midnight-blue'
									}`
								)}
								onClick={() => {
									setActiveTab(index)
								}}
								type="button"
							>
								{tab.elements.tabTitle}
							</button>
						</div>
					)
				})}
			</div>
			<div className="mx-4 my-10 flex">
				{block.elements.tabbedComponents.map((tab, index) => (
					<div
						className={activeTab === index ? 'block' : 'hidden'}
						key={tab.system.id}
					>
						<CdcGigyaBlock block={tab} {...context} />
					</div>
				))}
			</div>
		</div>
	)
}
