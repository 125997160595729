import {Icon as CIcon} from '@elanco/component-library-v2'
import Image from 'next/future/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import {customBrandChoices, customBrands} from '@/components/BlockMapper/utils'
import {defaultMargins} from '@/_new-code/products/flexible-web-toolkit/styles'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {BlockWithClassName} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'

type IconListItemContentItem = IContentItem<{
	icon: Elements.AssetsElement
	brand: Elements.MultipleChoiceElement
	iconSize: Elements.MultipleChoiceElement
	title: Elements.TextElement
	text: Elements.RichTextElement
	squareIcon: Elements.MultipleChoiceElement
}>

export type IconListContentItem = IContentItem<{
	items: Elements.LinkedItemsElement<IconListItemContentItem>
	title: Elements.TextElement
	horizontal: Elements.MultipleChoiceElement
	margin: Elements.MultipleChoiceElement
}>

export const IconListBlock: BlockWithClassName<IconListContentItem> = ({
	block,
	className,
	...context
}) => {
	const isHorizontal = block.elements.horizontal[0]?.codename === 'yes'
	return (
		<div>
			{block.elements.title ? <h4>{block.elements.title}</h4> : null}
			<ul
				className={clsx(
					'container-narrow container',
					isHorizontal && 'm-0 mb-2 flex flex-wrap p-0',
					className ? className : defaultMargins,
					block.elements.margin[0]?.codename === 'no'
						? ''
						: defaultMargins
				)}
			>
				{block.elements.items.map(
					({
						elements: {
							icon,
							iconSize,
							brand,
							title,
							text,
							squareIcon,
						},
						system: {id},
					}) => (
						<li
							className={clsx(
								'mb-4 flex',
								isHorizontal && 'mr-3 flex-col'
							)}
							data-kontent-item-id={id}
							key={id}
						>
							<CIcon
								brand={
									brand[0] &&
									customBrandChoices.includes(
										brand[0].codename
									)
										? 'none'
										: brand[0]?.codename ?? 'none'
								}
								className="flex-shrink-0"
								iconCustomClasses={`${
									brand[0] &&
									customBrandChoices.includes(
										brand[0].codename
									)
										? customBrands[brand[0].codename]
												?.border
										: ''
								}`}
								noBorder
								showSquareIcon={
									squareIcon[0]?.codename === 'yes'
								}
								size={iconSize[0]?.codename || 'lg'}
							>
								<div className="relative h-full w-full">
									{icon[0] ? (
										<Image
											alt={
												icon[0].description ?? undefined
											}
											data-kontent-element-codename="icon"
											fill
											loader={loadImageFromKontentAI}
											src={icon[0].url}
										/>
									) : null}
								</div>
							</CIcon>
							<div className="ml-2">
								<div className="font-bold">{title}</div>
								{text.value !== '<p><br></p>' && (
									<RichTextRenderer
										element={text}
										{...context}
									/>
								)}
							</div>
						</li>
					)
				)}
			</ul>
		</div>
	)
}
