export const ButtonIcons = {
	ArrowLeft: {
		class: 'arrow-left',
		icon: (
			<svg height="23.37" viewBox="0 0 23.978 23.37" width="23.978">
				<path
					d="M10.195,4.213l1.188-1.188a1.279,1.279,0,0,1,1.814,0l10.4,10.4a1.279,1.279,0,0,1,0,1.814L13.2,25.64a1.279,1.279,0,0,1-1.814,0l-1.188-1.188a1.286,1.286,0,0,1,.021-1.836l6.449-6.144H1.284A1.281,1.281,0,0,1,0,15.189V13.476a1.281,1.281,0,0,1,1.284-1.284h15.38L10.216,6.048A1.277,1.277,0,0,1,10.195,4.213Z"
					transform="translate(23.978 26.017) rotate(180)"
				/>
			</svg>
		),
	},
	ArrowRight: {
		class: 'arrow-right',
		icon: (
			<svg height="23.37" viewBox="0 0 23.978 23.37" width="23.978">
				<path
					d="M10.195,4.213l1.188-1.188a1.279,1.279,0,0,1,1.814,0l10.4,10.4a1.279,1.279,0,0,1,0,1.814L13.2,25.64a1.279,1.279,0,0,1-1.814,0l-1.188-1.188a1.286,1.286,0,0,1,.021-1.836l6.449-6.144H1.284A1.281,1.281,0,0,1,0,15.189V13.476a1.281,1.281,0,0,1,1.284-1.284h15.38L10.216,6.048A1.277,1.277,0,0,1,10.195,4.213Z"
					transform="translate(0 -2.647)"
				/>
			</svg>
		),
	},
	Buy: {
		class: 'buy',
		icon: (
			<svg height="24" viewBox="0 0 24 24" width="24">
				<path
					d="M10.906,11.468a1.548,1.548,0,1,0,2.19,0,1.548,1.548,0,0,0-2.19,0ZM12,.563a12,12,0,1,0,12,12A12,12,0,0,0,12,.563Zm6.1,7.164-3.192,6.985a1.545,1.545,0,0,1-.763.763L7.164,18.666A.957.957,0,0,1,5.9,17.4l3.193-6.985a1.545,1.545,0,0,1,.763-.763l6.984-3.192A.957.957,0,0,1,18.1,7.726Z"
					transform="translate(0 -0.563)"
				/>
			</svg>
		),
	},
	Car: {
		class: 'car',
		icon: (
			<svg height="21" viewBox="0 0 28 21" width="28">
				<path
					d="M27.344,10.625H24.069l-.91-2.275A6.094,6.094,0,0,0,17.473,4.5H10.527A6.1,6.1,0,0,0,4.84,8.35l-.91,2.275H.657a.656.656,0,0,0-.637.815l.328,1.313a.656.656,0,0,0,.637.5h1.1A3.476,3.476,0,0,0,.875,15.875V18.5a3.472,3.472,0,0,0,.875,2.293V23.75A1.75,1.75,0,0,0,3.5,25.5H5.25A1.75,1.75,0,0,0,7,23.75V22H21v1.75a1.75,1.75,0,0,0,1.75,1.75H24.5a1.75,1.75,0,0,0,1.75-1.75V20.793a3.469,3.469,0,0,0,.875-2.293V15.875a3.477,3.477,0,0,0-1.207-2.625h1.1a.656.656,0,0,0,.637-.5l.328-1.313a.657.657,0,0,0-.637-.815ZM8.09,9.65A2.625,2.625,0,0,1,10.527,8h6.945A2.625,2.625,0,0,1,19.91,9.65L21,12.375H7L8.09,9.65ZM5.25,18.489A1.651,1.651,0,0,1,3.5,16.745,1.651,1.651,0,0,1,5.25,15a3.367,3.367,0,0,1,2.625,2.617C7.875,18.664,6.3,18.489,5.25,18.489Zm17.5,0c-1.05,0-2.625.174-2.625-.872A3.367,3.367,0,0,1,22.75,15a1.651,1.651,0,0,1,1.75,1.745A1.651,1.651,0,0,1,22.75,18.489Z"
					transform="translate(0 -4.5)"
				/>
			</svg>
		),
	},
	ChevronDown: {
		class: 'chevron-down',
		icon: (
			<svg
				className="stroke-current"
				height="20"
				viewBox="0 0 17.1 10.7"
				width="20"
			>
				<path
					d="M1.9,1.9l6.6,6.2l6.7-6.2"
					fill="none"
					strokeLinecap="round"
					strokeWidth="4"
				/>
			</svg>
		),
	},
	ChevronUp: {
		class: 'chevron-up',
		icon: (
			<svg
				className="stroke-current"
				height="20"
				viewBox="0 0 17.1 10.7"
				width="20"
			>
				<path
					d="M15.2,8.8L8.6,2.6L1.9,8.8"
					fill="none"
					strokeLinecap="round"
					strokeWidth="4"
				/>
			</svg>
		),
	},
	Close: {
		class: 'close',
		icon: (
			<svg height="20" viewBox="0 0 15.7 15.7" width="20">
				<path d="M14.3,0.3l1.1,1.1c0.4,0.4,0.4,1,0,1.4L2.8,15.4c-0.4,0.4-1,0.4-1.4,0l-1.1-1.1c-0.4-0.4-0.4-1,0-1.4 L12.9,0.3C13.3-0.1,13.9-0.1,14.3,0.3z" />
				<path d="M15.4,14.3l-1.1,1.1c-0.4,0.4-1,0.4-1.4,0L0.3,2.8c-0.4-0.4-0.4-1,0-1.4l1.1-1.1c0.4-0.4,1-0.4,1.4,0 l12.6,12.6C15.8,13.3,15.8,13.9,15.4,14.3z" />
			</svg>
		),
	},
	Contact: {
		class: 'contact',
		icon: (
			<svg height="21" viewBox="0 0 20.924 21" width="20.924">
				<path
					d="M29.418,27.248a7.523,7.523,0,0,1-.638.638c-.332.332-.689.663-1.021,1.046a2.662,2.662,0,0,1-2.067.868h-.255a11.691,11.691,0,0,1-4.185-1.25A23.519,23.519,0,0,1,13.394,22.4a21.184,21.184,0,0,1-3.827-6.379,7.949,7.949,0,0,1-.612-3.368A2.517,2.517,0,0,1,9.72,11.02l1.812-1.812A1.362,1.362,0,0,1,12.4,8.8a1.15,1.15,0,0,1,.842.408c.332.306.638.612.97.944l.51.51,1.454,1.454a1.227,1.227,0,0,1,.434.868,1.285,1.285,0,0,1-.434.868l-.459.459c-.459.459-.868.893-1.327,1.3l-.026.026a1,1,0,0,0-.255,1.123c0,.026,0,.026.026.051a11.723,11.723,0,0,0,1.71,2.781,19.844,19.844,0,0,0,4.7,4.287c.2.128.434.255.638.357a5.138,5.138,0,0,1,.536.281c.026,0,.026.026.051.026a1.248,1.248,0,0,0,.485.128.951.951,0,0,0,.74-.357L24.8,22.5a1.2,1.2,0,0,1,.868-.408,1.323,1.323,0,0,1,.842.408l2.934,2.934A1.173,1.173,0,0,1,29.418,27.248Z"
					transform="translate(-8.939 -8.8)"
				/>
			</svg>
		),
	},
	Copy: {
		class: 'copy',
		icon: (
			<svg height="21.1" viewBox="0 0 18.4 21.1" width="18.5">
				<path d="M13.2,18.4v1.6c0,0.5-0.4,1-1,1h0H1c-0.5,0-1-0.4-1-1V4.9c0-0.5,0.4-1,1-1h3v12.2c0,1.3,1,2.3,2.3,2.3 L13.2,18.4z" />
				<g>
					<path d="M14.2,5.3c-0.5,0-1-0.4-1-1V0H6.3c-0.5,0-1,0.4-1,1v15.1c0,0.5,0.4,1,1,1h11.2c0.5,0,1-0.4,1-1V5.3H14.2z" />
					<path d="M18.4,3.7c0-0.3-0.1-0.5-0.3-0.7h0l-2.7-2.7C15.2,0.1,15,0,14.7,0h-0.2v4h4V3.7z" />
				</g>
			</svg>
		),
	},
	Download: {
		class: 'download',
		icon: (
			<svg height="20.772" viewBox="0 0 21.09 20.772" width="21.09">
				<g transform="translate(77.186 0.953)">
					<path d="M-66.6,9.6l5.3-5.3h-4V-1H-68v5.3h-4L-66.6,9.6z" />
					<path d="M-61.8,8.4l-1.5,1.5l5.4,2l-8.7,3.2l-8.7-3.2l5.4-2l-1.5-1.5l-5.8,2.2v5.3l10.5,4l10.5-4v-5.3 L-61.8,8.4z" />
				</g>
			</svg>
		),
	},
	Email: {
		class: 'email',
		icon: (
			<svg height="25.9" viewBox="0 0 22.7 25.9" width="22.7">
				<path
					d="M1.6,13.5l4.4,4.6c0,0,0,0.1,0,0.2L2,22.6c-0.3,0.3-0.3,0.7,0,1c0.3,0.3,0.7,0.3,1,0l8.2-8.8c0,0,0,0,0,0 c0.1-0.1,0.2-0.1,0.3,0l8.2,8.8c0.3,0.3,0.7,0.3,1,0c0.3-0.3,0.3-0.7,0-1l-4.1-4.4c0,0,0,0,0,0c0,0,0-0.1,0-0.1l4.4-4.6 c0.1-0.1,0.2-0.3,0.2-0.5c0.1-0.7-0.3-1.4-1-1.5H2.5c-0.2,0-0.4,0.1-0.6,0.2C1.4,12.1,1.2,12.9,1.6,13.5z"
					fill="none"
				/>
				<path
					d="M0.6,11.2C0.2,11.6,0,12.1,0,12.6v11.6c0,1,0.8,1.7,1.7,1.7H21c1,0,1.7-0.8,1.7-1.7V12.6 c0-0.5-0.2-1-0.6-1.4c0,0-0.1-0.1-0.1-0.1H0.7C0.6,11.1,0.6,11.2,0.6,11.2z M2.5,11.5h17.7c0.7,0.1,1.2,0.8,1,1.5 c0,0.2-0.1,0.3-0.2,0.5l-4.4,4.6c0,0,0,0.1,0,0.1c0,0,0,0,0,0l4.1,4.4c0.3,0.3,0.3,0.7,0,1c-0.3,0.3-0.7,0.3-1,0l-8.2-8.8 c-0.1-0.1-0.2-0.1-0.3,0c0,0,0,0,0,0L3,23.6c-0.3,0.3-0.7,0.3-1,0c-0.3-0.3-0.3-0.7,0-1l4.1-4.4c0,0,0-0.1,0-0.2l-4.4-4.6 c-0.4-0.6-0.3-1.3,0.3-1.7C2.1,11.6,2.3,11.5,2.5,11.5z"
					fill="#0067B1"
				/>
				<g>
					<path
						d="M16.5,4c-1.6-1.7-3-3.1-3-3.1c-1.2-1.2-3.1-1.2-4.2,0c0,0,0,0,0,0c0,0-1.4,1.4-3,3.1v5.6h10.3V4z"
						fill="#FFFFFF"
					/>
					<path
						d="M9.3,2.3C9.2,2.3,9.2,2.4,9.3,2.3c0,0-7.6,7.7-8.6,8.7H22c-1-1-8.5-8.7-8.5-8.7C12.3,1.2,10.4,1.2,9.3,2.3z"
						fill="#0067B1"
					/>
				</g>
			</svg>
		),
	},
	EmailInverted: {
		class: 'email',
		icon: (
			<svg height="25.9" viewBox="0 0 22.7 25.9" width="22.7">
				<g>
					<path
						d="M16.5,4c-1.6-1.7-3-3.1-3-3.1c-1.2-1.2-3.1-1.2-4.2,0c0,0,0,0,0,0c0,0-1.4,1.4-3,3.1v5.6h10.3V4z"
						fill="#0067B1"
					/>
					<path
						d="M9.3,2.3C9.2,2.3,9.2,2.4,9.3,2.3c0,0-7.6,7.7-8.6,8.7H22c-1-1-8.5-8.7-8.5-8.7C12.3,1.2,10.4,1.2,9.3,2.3z"
						fill="#ffffff"
					/>
				</g>
				<path
					d="M1.6,13.5l4.4,4.6c0,0,0,0.1,0,0.2L2,22.6c-0.3,0.3-0.3,0.7,0,1c0.3,0.3,0.7,0.3,1,0l8.2-8.8c0,0,0,0,0,0 c0.1-0.1,0.2-0.1,0.3,0l8.2,8.8c0.3,0.3,0.7,0.3,1,0c0.3-0.3,0.3-0.7,0-1l-4.1-4.4c0,0,0,0,0,0c0,0,0-0.1,0-0.1l4.4-4.6 c0.1-0.1,0.2-0.3,0.2-0.5c0.1-0.7-0.3-1.4-1-1.5H2.5c-0.2,0-0.4,0.1-0.6,0.2C1.4,12.1,1.2,12.9,1.6,13.5z"
					fill="none"
				/>
				<path
					d="M0.6,11.2C0.2,11.6,0,12.1,0,12.6v11.6c0,1,0.8,1.7,1.7,1.7H21c1,0,1.7-0.8,1.7-1.7V12.6 c0-0.5-0.2-1-0.6-1.4c0,0-0.1-0.1-0.1-0.1H0.7C0.6,11.1,0.6,11.2,0.6,11.2z M2.5,11.5h17.7c0.7,0.1,1.2,0.8,1,1.5 c0,0.2-0.1,0.3-0.2,0.5l-4.4,4.6c0,0,0,0.1,0,0.1c0,0,0,0,0,0l4.1,4.4c0.3,0.3,0.3,0.7,0,1c-0.3,0.3-0.7,0.3-1,0l-8.2-8.8 c-0.1-0.1-0.2-0.1-0.3,0c0,0,0,0,0,0L3,23.6c-0.3,0.3-0.7,0.3-1,0c-0.3-0.3-0.3-0.7,0-1l4.1-4.4c0,0,0-0.1,0-0.2l-4.4-4.6 c-0.4-0.6-0.3-1.3,0.3-1.7C2.1,11.6,2.3,11.5,2.5,11.5z"
					fill="#ffffff"
				/>
			</svg>
		),
	},
	EmailPlain: {
		class: 'email',
		icon: (
			<svg
				data-name="(FORM_ICON)_Email"
				height="34"
				id="_FORM_ICON__Email"
				viewBox="6 6 20 20"
				width="34"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle
					cx="17"
					cy="17"
					data-name="Ellipse 91473"
					fill="#0072ce"
					id="Ellipse_91473"
					r="17"
				/>
				<path
					d="M.072,14.6V5.091q0-.017.052-.314L5.993,9.564.141,14.928a1.341,1.341,0,0,1-.069-.33ZM.851,4.118a.779.779,0,0,1,.294-.05H16.952a1.024,1.024,0,0,1,.312.05l-5.886,4.8-.779.594-1.541,1.2-1.541-1.2L6.737,8.92ZM.868,15.571l5.9-5.4,2.285,1.766,2.285-1.766,5.9,5.4a.868.868,0,0,1-.294.05H1.145a.818.818,0,0,1-.277-.05ZM12.122,9.564l5.852-4.786a.943.943,0,0,1,.052.314V14.6a1.21,1.21,0,0,1-.052.33Z"
					data-name="Icon zocial-email"
					fill="#fff"
					id="Icon_zocial-email"
					transform="translate(7.951 7.155)"
				/>
			</svg>
		),
	},
	External: {
		class: 'external',
		icon: (
			<svg height="16.5" viewBox="0 0 16.5 16.5" width="16.5">
				<g transform="translate(-1076.096 -350.478)">
					<path d="M1076.1,350.5V367h16.5v-4.1h-2.1v2.1h-12.4v-12.4h2.1v-2.1H1076.1z" />
					<path d="M1082.8,350.5l3.7,3.7l-6.1,6.1l2.4,2.5l6.1-6.1l3.7,3.7v-9.8H1082.8z" />
				</g>
			</svg>
		),
	},
	Facebook: {
		class: 'facebook',
		icon: (
			<svg height="32.748" viewBox="0 0 15.115 32.748" width="15.115">
				<path
					d="M60.73,87.464H57.161c-1.26,0-1.523.516-1.523,1.819v3.149H60.73l-.49,5.528h-4.6v16.514H49.044V98.031H45.615v-5.6h3.429V88.025c0-4.137,2.213-6.3,7.119-6.3H60.73v5.738Z"
					transform="translate(-45.615 -81.727)"
				/>
			</svg>
		),
	},
	Flip: {
		class: 'flip',
		icon: (
			<svg height="23.899" viewBox="0 0 19.811 23.899" width="19.811">
				<path d="M13.207,21.726h2.2V19.554h-2.2ZM17.61,8.69h2.2V6.518h-2.2ZM0,4.345V19.554a2.193,2.193,0,0,0,2.2,2.173H6.6V19.554H2.2V4.345H6.6V2.173H2.2A2.193,2.193,0,0,0,0,4.345ZM17.61,2.173V4.345h2.2A2.193,2.193,0,0,0,17.61,2.173ZM8.8,23.9h2.2V0H8.8Zm8.8-6.518h2.2V15.208h-2.2Zm-4.4-13.036h2.2V2.173h-2.2Zm4.4,8.69h2.2V10.863h-2.2Zm0,8.69a2.193,2.193,0,0,0,2.2-2.173h-2.2Z" />
			</svg>
		),
	},
	FlipBack: {
		class: 'flip-back',
		icon: (
			<svg height="23.899" viewBox="0 0 19.811 23.899" width="19.811">
				<path
					d="M13.207,21.726h2.2V19.554h-2.2ZM17.61,8.69h2.2V6.518h-2.2ZM0,4.345V19.554a2.193,2.193,0,0,0,2.2,2.173H6.6V19.554H2.2V4.345H6.6V2.173H2.2A2.193,2.193,0,0,0,0,4.345ZM17.61,2.173V4.345h2.2A2.193,2.193,0,0,0,17.61,2.173ZM8.8,23.9h2.2V0H8.8Zm8.8-6.518h2.2V15.208h-2.2Zm-4.4-13.036h2.2V2.173h-2.2Zm4.4,8.69h2.2V10.863h-2.2Zm0,8.69a2.193,2.193,0,0,0,2.2-2.173h-2.2Z"
					transform="translate(19.811 23.899) rotate(180)"
				/>
			</svg>
		),
	},
	Globe: {
		class: 'globe',
		icon: (
			<svg height="25.55" viewBox="0 0 25.55 25.55" width="25.55">
				<path
					d="M20.775,8A12.775,12.775,0,1,0,33.55,20.775,12.784,12.784,0,0,0,20.775,8Zm-.627,1.274c.039,1.553.019,3.211.019,4.8H16.26C17.12,11.479,18.484,9.629,20.148,9.274Zm1.236.019c1.637.386,2.981,2.217,3.831,4.781H21.383ZM17.448,9.7a12.053,12.053,0,0,0-2.367,4.372H11.355A11.572,11.572,0,0,1,17.448,9.7Zm6.654,0a11.573,11.573,0,0,1,6.093,4.372H26.469A12.057,12.057,0,0,0,24.1,9.7ZM10.595,15.291h4.144a22.467,22.467,0,0,0-.646,4.867H9.236A11.493,11.493,0,0,1,10.595,15.291Zm5.313,0h4.258v4.867H15.281A22.5,22.5,0,0,1,15.908,15.291Zm5.475,0h4.182a22.523,22.523,0,0,1,.627,4.867h-4.81Zm5.428,0h4.144a11.5,11.5,0,0,1,1.359,4.867H27.457A22.467,22.467,0,0,0,26.811,15.291ZM9.236,21.374h4.857a22.5,22.5,0,0,0,.637,4.867H10.585A11.528,11.528,0,0,1,9.236,21.374Zm6.045,0h4.886v4.867H15.908A22.515,22.515,0,0,1,15.281,21.374Zm6.1,0h4.81a22.533,22.533,0,0,1-.627,4.867H21.383Zm6.074,0h4.857a11.533,11.533,0,0,1-1.35,4.867H26.82A22.5,22.5,0,0,0,27.457,21.374ZM11.336,27.457h3.735a12.055,12.055,0,0,0,2.367,4.382A11.563,11.563,0,0,1,11.336,27.457Zm4.924,0h3.907v4.809C18.493,31.921,17.123,30.066,16.26,27.457Zm5.123,0h3.831c-.85,2.569-2.192,4.4-3.831,4.791Zm5.095,0h3.735a11.563,11.563,0,0,1-6.1,4.382A12.055,12.055,0,0,0,26.478,27.457Z"
					transform="translate(-8 -8)"
				/>
			</svg>
		),
	},
	Instagram: {
		class: 'instagram',
		icon: (
			<svg height="29.787" viewBox="0 0 29.676 29.787" width="29.676">
				<path d="M23.851,29.787H5.825A5.864,5.864,0,0,1,0,23.961v-13.3H8.683a8.047,8.047,0,0,0-1.429,4.4,7.584,7.584,0,0,0,15.168,0,7.25,7.25,0,0,0-1.429-4.4h8.683v13.3A5.864,5.864,0,0,1,23.851,29.787Zm-9.013-8.9a5.864,5.864,0,0,1-5.825-5.825,6.083,6.083,0,0,1,1.978-4.4,6.232,6.232,0,0,1,1.429-.879,5.99,5.99,0,0,1,2.418-.55,5.99,5.99,0,0,1,2.418.55,6.232,6.232,0,0,1,1.429.879,5.692,5.692,0,0,1,1.978,4.4A5.864,5.864,0,0,1,14.838,20.884Zm0-10.112a4.287,4.287,0,1,0,4.287,4.287,4.223,4.223,0,0,0-4.287-4.287Zm-5.386-1.1H0V5.825A5.611,5.611,0,0,1,2.528,1.1V7.364H3.737V.44A2.587,2.587,0,0,1,4.616.22V7.474H5.825V0h.769V7.364H7.8V0H23.851a5.864,5.864,0,0,1,5.825,5.825V9.672H20.224a7.768,7.768,0,0,0-5.386-2.308A8.05,8.05,0,0,0,9.453,9.672ZM22.862,2.418a.985.985,0,0,0-.989.989V6.595a.985.985,0,0,0,.989.989H26.05a.985.985,0,0,0,.989-.989V3.407a.985.985,0,0,0-.989-.989H22.862Z" />
			</svg>
		),
	},
	LinkedIn: {
		class: 'linkedin',
		icon: (
			<svg height="13.232" viewBox="0 0 13.226 13.232" width="13.226">
				<path
					d="M421.353,92.512h2.731v8.822h-2.731Zm1.352-1.155a1.628,1.628,0,1,0-1.615-1.627A1.621,1.621,0,0,0,422.7,91.356Zm5.743,5.346c0-1.241.571-1.979,1.664-1.979,1,0,1.487.709,1.487,1.979v4.631h2.717V95.747c0-2.363-1.339-3.505-3.21-3.505a3.076,3.076,0,0,0-2.658,1.457V92.512h-2.619v8.822h2.619Z"
					fill="#fff"
					transform="translate(-421.09 -88.101)"
				/>
			</svg>
		),
	},
	Load: {
		class: 'load',
		icon: (
			<svg
				className="stroke-current"
				height="19.879"
				viewBox="0 0 19.879 19.879"
				width="19.879"
			>
				<g transform="translate(0.75 0.75)">
					<path
						d="M18,3V9"
						fill="none"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1.5"
						transform="translate(-8.811 -3)"
					/>
					<path
						d="M18,27v6"
						fill="none"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1.5"
						transform="translate(-8.811 -14.621)"
					/>
					<path
						d="M7.395,7.395,11.64,11.64"
						fill="none"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1.5"
						transform="translate(-4.983 -4.983)"
					/>
					<path
						d="M24.36,24.36,28.6,28.6"
						fill="none"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1.5"
						transform="translate(-12.638 -12.638)"
					/>
					<path
						d="M3,18H9"
						fill="none"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1.5"
						transform="translate(-3 -8.811)"
					/>
					<path
						d="M27,18h6"
						fill="none"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1.5"
						transform="translate(-14.621 -8.811)"
					/>
					<path
						d="M7.395,28.6,11.64,24.36"
						fill="none"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1.5"
						transform="translate(-4.983 -12.638)"
					/>
					<path
						d="M24.36,11.64,28.6,7.395"
						fill="none"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1.5"
						transform="translate(-12.638 -4.983)"
					/>
				</g>
			</svg>
		),
	},
	Location: {
		class: 'location',
		icon: (
			<svg
				baseProfile="tiny"
				fill="currentColor"
				height="24px"
				stroke="currentColor"
				strokeWidth="0"
				version="1.2"
				viewBox="0 0 24 24"
				width="24px"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M12 5c1.609 0 3.12.614 4.254 1.73 1.126 1.107 1.746 2.579 1.746 4.14s-.62 3.03-1.745 4.139l-4.255 4.184-4.254-4.186c-1.125-1.107-1.745-2.576-1.745-4.139s.62-3.032 1.745-4.141c1.135-1.113 2.647-1.727 4.254-1.727m0-2c-2.047 0-4.096.768-5.657 2.305-3.124 3.074-3.124 8.057 0 11.131l5.657 5.563 5.657-5.565c3.124-3.072 3.124-8.056 0-11.129-1.561-1.537-3.609-2.305-5.657-2.305zM12 8.499c.668 0 1.296.26 1.768.731.976.976.976 2.562 0 3.537-.473.472-1.1.731-1.768.731s-1.295-.26-1.768-.731c-.976-.976-.976-2.562 0-3.537.473-.471 1.101-.731 1.768-.731m0-1c-.896 0-1.792.342-2.475 1.024-1.367 1.367-1.367 3.584 0 4.951.684.684 1.578 1.024 2.475 1.024s1.792-.342 2.475-1.024c1.366-1.367 1.366-3.584 0-4.951-.683-.683-1.579-1.024-2.475-1.024z" />
			</svg>
		),
	},
	Nav: {
		class: 'nav',
		icon: (
			<svg height="20" viewBox="0 0 20 20" width="20">
				<g transform="translate(0.296)">
					<rect
						height="20"
						rx="1"
						transform="translate(19.704 15) rotate(90)"
						width="5"
					/>
					<rect
						height="16"
						rx="1"
						transform="translate(19.704 8) rotate(90)"
						width="4"
					/>
					<rect
						height="20"
						rx="1"
						transform="translate(19.704) rotate(90)"
						width="5"
					/>
				</g>
			</svg>
		),
	},
	Plane: {
		class: 'plane',
		icon: (
			<svg height="19.533" viewBox="0 0 19.535 19.533" width="19.535">
				<path
					d="M23.422,4.547,4.745,12.687a.428.428,0,0,0,.015.778l5.052,2.854a.815.815,0,0,0,.931-.092L20.7,7.64c.066-.056.224-.163.285-.1s-.036.219-.092.285L12.28,17.53a.812.812,0,0,0-.081.972l3.3,5.3a.43.43,0,0,0,.773-.01L24,5.111A.428.428,0,0,0,23.422,4.547Z"
					transform="translate(-4.503 -4.503)"
				/>
			</svg>
		),
	},
	Play: {
		class: 'play',
		icon: (
			<svg height="19.5" viewBox="0 0 17 19.5" width="17">
				<path
					d="M16,8c0.9,0.6,1.3,1.8,0.7,2.7c-0.2,0.3-0.4,0.5-0.7,0.7L3,19.2c-0.9,0.6-2.2,0.3-2.7-0.7 c-0.2-0.3-0.3-0.7-0.3-1V2c0-1.1,0.9-2,2-2c0.4,0,0.7,0.1,1,0.3L16,8z"
					id="Play_Icon"
				/>
			</svg>
		),
	},
	Print: {
		class: 'print',
		icon: (
			<svg height="20" viewBox="0 0 22.2 20" width="22.2">
				<rect height="4.4" width="13.3" x="4.4" y="0" />
				<path d="M6.7,12.2h8.9v3.3h6.7V8.9c0-1.8-1.5-3.3-3.3-3.3c0,0,0,0,0,0H3.3C1.5,5.5,0,7,0,8.9c0,0,0,0,0,0v6.6h6.7 V12.2z M18.9,7.8c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1v0c-0.6,0-1.1-0.5-1.1-1.1S18.2,7.8,18.9,7.8z" />
				<polygon points="15.5,9.7 15.5,17.3 15.5,17.7 6.7,17.7 6.7,17.3 6.7,9.7 4.4,9.7 4.4,17.3 4.4,18.3 4.4,20 17.7,20 17.7,18.3 17.7,17.3 17.7,9.7 " />
			</svg>
		),
	},
	Read: {
		class: 'read',
		icon: (
			<svg height="16.6" viewBox="0 0 16.6 16.6" width="16.6">
				<path d="M14.9,0H5C4.1,0,3.3,0.7,3.3,1.7v10c0,0.9,0.7,1.7,1.7,1.7h10 c0.9,0,1.7-0.7,1.7-1.7v-10C16.6,0.7,15.9,0,14.9,0z M7.9,6.2c0,0.7-0.6,1.2-1.2,1.2c0,0,0,0,0,0H5.8v1.7H4.6v-5h2.1 c0.7,0,1.2,0.6,1.2,1.2c0,0,0,0,0,0V6.2z M12,7.9c0,0.7-0.6,1.2-1.2,1.2H8.7v-5h2.1c0.7,0,1.2,0.6,1.2,1.2c0,0,0,0,0,0L12,7.9z M15.4,5.4h-1.2v0.8h1.2v1.2h-1.2v1.7h-1.2v-5h2.5L15.4,5.4z M5.8,6.2h0.8V5.4H5.8V6.2z M10,7.9h0.8V5.4H10V7.9z" />
				<path d="M1.7,3.3H0v11.6c0,0.9,0.7,1.7,1.7,1.7h11.6v-1.7H1.7V3.3z" />
			</svg>
		),
	},
	Register: {
		class: 'register',
		icon: (
			<svg height="18.229" viewBox="0 0 16.406 18.229" width="16.406">
				<path
					d="M19.083,3.323h-3.81a2.724,2.724,0,0,0-5.141,0H6.323A1.828,1.828,0,0,0,4.5,5.146v12.76a1.828,1.828,0,0,0,1.823,1.823h12.76a1.828,1.828,0,0,0,1.823-1.823V5.146A1.828,1.828,0,0,0,19.083,3.323Zm-6.38,0a.911.911,0,1,1-.911.911A.914.914,0,0,1,12.7,3.323Zm-.379,12.76H8.146V14.26h4.178Zm2.719-3.646h-6.9V10.615h6.9ZM13.615,8.792H8.146V6.969h5.469Z"
					transform="translate(-4.5 -1.5)"
				/>
			</svg>
		),
	},
	Search: {
		class: 'search',
		icon: (
			<svg height="14.14" viewBox="0 0 14.137 14.14" width="14.137">
				<path d="M13.945,12.225,11.192,9.472a.662.662,0,0,0-.469-.193h-.45a5.741,5.741,0,1,0-.994.994v.45a.662.662,0,0,0,.193.469l2.753,2.753a.66.66,0,0,0,.936,0l.781-.781A.666.666,0,0,0,13.945,12.225Zm-8.2-2.946A3.535,3.535,0,1,1,9.278,5.744,3.533,3.533,0,0,1,5.744,9.278Z" />
			</svg>
		),
	},
	Share: {
		class: 'share',
		icon: (
			<svg height="50.32" viewBox="0 0 44.03 50.32" width="44.03">
				<path d="M34.6,31.44c-2.14-0.01-4.21,0.72-5.88,2.05l-10.07-6.3c0.3-1.35,0.3-2.75,0-4.1l10.07-6.3 c4.07,3.24,10,2.56,13.23-1.51s2.56-10-1.51-13.23s-10-2.56-13.23,1.51c-1.77,2.22-2.44,5.13-1.82,7.9l-10.07,6.3 c-4.08-3.25-10.01-2.57-13.26,1.51s-2.57,10.01,1.51,13.26c3.44,2.74,8.31,2.74,11.75,0l10.07,6.3c-1.14,5.09,2.07,10.13,7.15,11.27 c5.09,1.14,10.13-2.07,11.27-7.15c1.14-5.09-2.07-10.13-7.15-11.27C35.98,31.52,35.29,31.44,34.6,31.44z" />
			</svg>
		),
	},
	Twitter: {
		class: 'twitter',
		icon: (
			<svg
				fill="none"
				height="1227"
				viewBox="0 0 1200 1227"
				width="1200"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
					fill="white"
				/>
			</svg>
		),
	},
	YouTube: {
		class: 'youtube',
		icon: (
			<svg height="31.599" viewBox="0 0 27.382 31.599" width="27.382">
				<g transform="translate(-139.062 -281.799)">
					<path
						d="M164.071,295.541a2.645,2.645,0,0,0,1.934-1.123v.994h1.671v-9h-1.671v6.827c-.2.256-.658.674-.984.674-.359,0-.446-.243-.446-.6v-6.9H162.9v7.52C162.9,294.822,163.175,295.541,164.071,295.541Z"
						fill="#fff"
						transform="translate(-7.32 -1.417)"
					/>
					<path
						d="M153.849,293.134a2.163,2.163,0,0,0,2.451,2.411,2.29,2.29,0,0,0,2.4-2.411v-4.408a2.347,2.347,0,0,0-2.4-2.417,2.3,2.3,0,0,0-2.451,2.417Zm1.721-4.26c0-.493.227-.858.7-.858.513,0,.732.354.732.858v4.185c0,.49-.249.851-.7.851a.759.759,0,0,1-.73-.851Z"
						fill="#fff"
						transform="translate(-4.54 -1.385)"
					/>
					<path
						d="M147.1,293.994h1.884v-4.947l2.191-7.248h-1.913l-1.207,4.874-1.3-4.874h-1.9l2.24,7.248Z"
						fill="#fff"
						transform="translate(-1.78)"
					/>
					<path
						d="M162.609,302.35H142.9a3.834,3.834,0,0,0-3.834,3.836v9.688a3.833,3.833,0,0,0,3.834,3.834h19.713a3.833,3.833,0,0,0,3.834-3.834v-9.688A3.834,3.834,0,0,0,162.609,302.35Zm-17.086,14.487H143.73V306.91h-1.857v-1.687h5.506v1.687h-1.856Zm6.38,0H150.31v-.945a3.532,3.532,0,0,1-.916.8c-.859.491-2.037.482-2.037-1.257v-7.16h1.593v6.565c0,.345.084.578.423.578.31,0,.744-.4.937-.642v-6.5H151.9Zm6.131-1.775c0,1.06-.4,1.883-1.456,1.883a1.822,1.822,0,0,1-1.513-.766v.658h-1.608V305.223h1.608v3.74a1.909,1.909,0,0,1,1.416-.8c1.167,0,1.554.985,1.554,2.147Zm5.888-2.308h-3.047v1.616c0,.643.054,1.2.7,1.2.673,0,.712-.452.712-1.2v-.6h1.64v.644c0,1.649-.71,2.65-2.388,2.65-1.521,0-2.3-1.109-2.3-2.65v-3.845a2.354,2.354,0,0,1,2.418-2.52c1.528,0,2.267.97,2.267,2.52Z"
						fill="#fff"
						transform="translate(0 -6.31)"
					/>
					<path
						d="M171.252,312.746c-.59,0-.712.416-.712,1v.866h1.407v-.866C171.947,313.169,171.823,312.746,171.252,312.746Z"
						fill="#fff"
						transform="translate(-9.664 -9.501)"
					/>
					<path
						d="M162.484,312.785a1.184,1.184,0,0,0-.329.261v5.338a1.381,1.381,0,0,0,.378.3.675.675,0,0,0,.814-.088.972.972,0,0,0,.139-.59v-4.424a1.086,1.086,0,0,0-.17-.658A.662.662,0,0,0,162.484,312.785Z"
						fill="#fff"
						transform="translate(-7.09 -9.487)"
					/>
				</g>
			</svg>
		),
	},
}
