interface IconProps extends React.SVGProps<SVGSVGElement> {
	className?: string
}

export const CheckMarkIcon = ({
	className,
	...props
}: IconProps): JSX.Element => (
	<svg className={className} viewBox="0 0 56 41.76" {...props}>
		<path
			className="translate-x-[-45px] translate-y-[-54.25px]"
			d="M64,95.19,45.82,77a2.81,2.81,0,0,1,0-4h0l4-4a2.81,2.81,0,0,1,4,0h0L66,81.33,92.26,55.07a2.81,2.81,0,0,1,4,0h0l4,4a2.81,2.81,0,0,1,0,4h0L68,95.19a2.79,2.79,0,0,1-4,0Z"
		/>
	</svg>
)

export const CrossMarkIcon = ({
	className,
	...props
}: IconProps): JSX.Element => (
	<svg className={className} viewBox="0 0 21.5 21.5" {...props}>
		<path
			className="translate-x-0 translate-y-[-0.5px]"
			d="M2.265,21.707.293,19.734a1,1,0,0,1,0-1.415l7.071-7.07L.293,4.18a1,1,0,0,1,0-1.414L2.266.793a1,1,0,0,1,1.414,0l7.07,7.07L17.819.794a1,1,0,0,1,1.414,0l1.973,1.973a1,1,0,0,1,0,1.414L14.137,11.25l7.07,7.07a1,1,0,0,1,0,1.414l-1.973,1.973a1,1,0,0,1-1.414,0l-7.07-7.07L3.68,21.707a1,1,0,0,1-1.415,0Z"
		/>
	</svg>
)

export const CheckMarkStarIcon = ({
	className,
	...props
}: IconProps): JSX.Element => (
	<svg
		className={className}
		viewBox="0 0 144 137.737"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g transform="translate(13918 -8150)">
			<g transform="translate(-13918 8150)">
				<path
					d="M65.717,4.79,48.14,40.4,8.816,46.134A8.61,8.61,0,0,0,4.052,60.82L32.5,88.526l-6.729,39.137a8.607,8.607,0,0,0,12.489,9.065l35.179-18.479,35.179,18.479a8.613,8.613,0,0,0,12.489-9.065L114.38,88.526,142.83,60.82a8.61,8.61,0,0,0-4.764-14.687L98.742,40.4,81.166,4.79a8.623,8.623,0,0,0-15.45,0Z"
					fill="#00ab9c"
					transform="translate(-1.441 0.001)"
				/>
			</g>
			<path
				d="M19.02,45.517.82,27.317a2.8,2.8,0,0,1,0-3.96L4.78,19.4a2.8,2.8,0,0,1,3.96,0L21,31.658,47.26,5.4a2.8,2.8,0,0,1,3.96,0l3.96,3.96a2.8,2.8,0,0,1,0,3.96l-32.2,32.2A2.8,2.8,0,0,1,19.02,45.517Z"
				fill="#fff"
				transform="translate(-13873 8199.674)"
			/>
		</g>
	</svg>
)

export const CrossMarkStarIcon = ({
	className,
	...props
}: IconProps): JSX.Element => (
	<svg
		className={className}
		viewBox="0 0 143.98 137.72"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			className="translate-x-[-0.01px] fill-[#ea0101]"
			d="M64.28,4.79,46.7,40.4,7.38,46.13A8.61,8.61,0,0,0,2.61,60.82L31.06,88.53l-6.73,39.13a8.61,8.61,0,0,0,12.49,9.07L72,118.25l35.18,18.48a8.62,8.62,0,0,0,12.49-9.07l-6.73-39.13,28.45-27.71a8.62,8.62,0,0,0-4.76-14.69L97.3,40.4,79.73,4.79A8.62,8.62,0,0,0,68.17.9a8.52,8.52,0,0,0-3.89,3.89Z"
		/>
		<path
			className="translate-x-[-0.01px] fill-white"
			d="M91.62,53.62l3.13,3.13a2.75,2.75,0,0,1,0,4L58.87,96.62a2.75,2.75,0,0,1-4,0l-3.13-3.13a2.75,2.75,0,0,1,0-4L87.63,53.62A2.75,2.75,0,0,1,91.62,53.62Z"
		/>
		<path
			className="translate-x-[-0.01px] fill-white"
			d="M94.75,93.49l-3.13,3.13a2.75,2.75,0,0,1-4,0L51.75,60.74a2.75,2.75,0,0,1,0-4l3.13-3.13a2.75,2.75,0,0,1,4,0L94.75,89.5A2.75,2.75,0,0,1,94.75,93.49Z"
		/>
	</svg>
)

export const StarShapedIcon = ({
	className,
	...props
}: IconProps): JSX.Element => (
	<svg
		className={className}
		viewBox="0 0 92 87.999"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M42.506,3.06,31.277,25.813,6.153,29.474a5.5,5.5,0,0,0-3.044,9.383l18.176,17.7-4.3,25a5.5,5.5,0,0,0,7.979,5.791L47.441,75.547,69.917,87.354A5.5,5.5,0,0,0,77.9,81.562l-4.3-25,18.176-17.7a5.5,5.5,0,0,0-3.044-9.383l-25.124-3.66L52.377,3.06a5.509,5.509,0,0,0-9.871,0Z"
			transform="translate(-1.441 0.001)"
		/>
	</svg>
)
