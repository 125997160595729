import type {LegacyRef} from 'react'
import {useState, useRef, useCallback} from 'react'

interface Dimensions {
	width: number
	height: number
}

/**
 * Utility hook to get the dimensions of a DOM element.
 * This works for images that resize over time, such as a text box.
 *
 * @example
 * ```
 * import {useMeasure} from '@/_new-code/utilities/elements'
 *
 * const MyComponent = () => {
 *  const {ref, dimensions: {height}} = useMeasure()
 *  return (
 *      <>
 *          <h1>The below image is {height}px tall</h1>
 *          <img ref={ref} src="https://assets.example.com/img.png" />
 *      </>
 *  )
 * }
 * ```
 * @returns
 */
export function useMeasure<ElementType extends HTMLElement>(): {
	ref: LegacyRef<ElementType>
	dimensions: Dimensions
} {
	const [dimensions, setDimensions] = useState<Dimensions>({
		width: 0,
		height: 0,
	})

	const previousObserver = useRef<ResizeObserver | null>(null)

	const customRef: LegacyRef<ElementType> = useCallback(
		(node: ElementType | null) => {
			if (previousObserver.current) {
				previousObserver.current.disconnect()
				previousObserver.current = null
			}

			if (node?.nodeType === Node.ELEMENT_NODE) {
				const observer = new ResizeObserver(([entry]) => {
					if (entry?.borderBoxSize[0]) {
						const {inlineSize: width, blockSize: height} =
							entry.borderBoxSize[0]

						setDimensions({width, height})
					}
				})

				observer.observe(node)
				previousObserver.current = observer
			}
		},
		[]
	)

	return {ref: customRef, dimensions}
}
