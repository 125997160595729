import {BoardMembers, Member} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'
import type {CardContentItem} from '../card'
import {CardBlock} from '../card'

type GovernanceBlockFeatureContentItem = IContentItem<{
	title: Elements.TextElement
	description: Elements.TextElement
	image: Elements.AssetsElement
}>

export type GovernanceBlockContentItem = IContentItem<{
	title: Elements.TextElement
	description: Elements.RichTextElement
	featuresTitle: Elements.TextElement
	features: Elements.LinkedItemsElement<GovernanceBlockFeatureContentItem>
	cardTitle: Elements.TextElement
	card: Elements.LinkedItemsElement<CardContentItem>
	tabTitle: Elements.TextElement
}>

export const GovernanceBlockBlock: Block<GovernanceBlockContentItem> = ({
	block: {
		elements: {
			title,
			description,
			featuresTitle,
			features,
			cardTitle,
			card,
		},
	},
	...context
}) => {
	return (
		<div className="md:flex">
			<div
				className={clsx(
					'pb-6, md:pb-0',
					card[0] ? 'md:w-1/2 lg:w-3/5' : null,
					'md:border-r-2 md:pr-8'
				)}
			>
				<div className="content">
					<h3 data-kontent-element-codename="title">{title}</h3>
					<RichTextRenderer
						data-kontent-element-codename="content"
						element={description}
						{...context}
					/>
				</div>
				<h4
					className="mb-2 mt-8 text-lg"
					data-kontent-element-codename="features_title"
				>
					{featuresTitle}
				</h4>
				<BoardMembers>
					{features.map((boardMember) => {
						const {
							title: featureTitle,
							description: featureDescription,
							image,
						} = boardMember.elements
						return (
							<Member
								brand="primary"
								jobRole={featureDescription}
								key={boardMember.system.id}
								name={featureTitle}
								showFullWidth
								showLargeIcon
								thumbnailUrl={image[0]?.url}
							/>
						)
					})}
				</BoardMembers>
			</div>
			{card[0] ? (
				<div className="lg:2/5 md:w-1/2 md:pl-8">
					<div className="mx-auto pb-2 md:max-w-sm">
						<h4
							className="mb-4 text-lg"
							data-kontent-element-codename="card_title"
						>
							{cardTitle}
						</h4>
						<CardBlock block={card[0]} {...context} />
					</div>
				</div>
			) : null}
		</div>
	)
}
