import {FC, PropsWithChildren} from 'react'

interface FieldGroupProps {
	name: string
	className?: string
	groupClassName?: string
}

const FieldGroup: FC<PropsWithChildren<FieldGroupProps>> = ({
	className = '',
	groupClassName = '',
	name,
	children,
}) => (
	<fieldset className={groupClassName} id={name}>
		<div className={`${className}`}>{children}</div>
	</fieldset>
)

export default FieldGroup
