import {useRouter} from 'next/router'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import type {PetTraitGroupContentItem} from '@/_new-code/products/pet-name-finder/models'
import type {LinkContentItem} from '../../flexible-web-toolkit/blocks/link'
import {
	RichTextBlock,
	type RichTextContentItem,
} from '../../flexible-web-toolkit/blocks/rich-text'
import {useNameGenerator, useTraits} from './hooks'
import {NamesResultBlock} from './names-result'
import {NamesFormBlock} from './names-form'

export type PetNameFormContentItem = IContentItem<{
	resetButtonText: Elements.TextElement
	submitButtonText: Elements.TextElement
	shuffleButtonText: Elements.TextElement
	changeAttributesButtonText: Elements.TextElement
	noResultsErrorText: Elements.TextElement
	topMatchesTitle: Elements.TextElement
	selectedAttributesTitle: Elements.TextElement
	otherNamesTitle: Elements.TextElement
	otherTopNamesTitle: Elements.TextElement
	viewAllLink: Elements.LinkedItemsElement<LinkContentItem>
	loadingModalContent: Elements.LinkedItemsElement<RichTextContentItem>
	topNameIcon: Elements.AssetsElement
	topNameBackground: Elements.AssetsElement
	traitGroups: Elements.LinkedItemsElement<PetTraitGroupContentItem>
}>

export const PetNameFormBlock: Block<PetNameFormContentItem> = ({
	block,
	...context
}) => {
	const router = useRouter()

	const onNoResults = (): void => {
		const props = block.elements.viewAllLink[0]
		if (!props) return

		const url = props.elements.url

		if (url) void router.push(url)
	}

	const {
		toggleTrait,
		reset,
		activeTraitGroups,
		selectedTraitsByGroup,
		isButtonDisabled,
	} = useTraits(block.elements.traitGroups)

	const {
		isLoading,
		otherNames,
		topNames,
		searchNames,
		shuffle,
		resetResults,
	} = useNameGenerator(selectedTraitsByGroup, onNoResults)

	// Instead of passing the whole block, pass the exact props, even if it increases the amount of props
	const mainBlock =
		!isLoading && (topNames.length > 0 || otherNames.length > 0) ? (
			<NamesResultBlock
				block={block}
				otherNames={otherNames}
				resetResults={resetResults}
				selectedTraitsByGroup={selectedTraitsByGroup}
				shuffle={shuffle}
				topNames={topNames}
				{...context}
			/>
		) : (
			<NamesFormBlock
				activeTraitGroups={activeTraitGroups}
				block={block}
				isButtonDisabled={isButtonDisabled}
				reset={reset}
				searchNames={searchNames}
				selectedTraitsByGroup={selectedTraitsByGroup}
				toggleTrait={toggleTrait}
				{...context}
			/>
		)
	return (
		<div
			className="container-narrow mx-auto mb-10 flex scroll-mt-[100px] flex-col items-center justify-center md:w-2/3"
			id="PetNameForm"
		>
			{block.elements.loadingModalContent[0] && isLoading ? (
				<RichTextBlock
					block={block.elements.loadingModalContent[0]}
					className="fixed left-[50%] top-[50%] z-[1] ml-[-250px] mt-[-200px] flex h-[400px] w-[500px] flex-col justify-between rounded-[54px] bg-white p-12 shadow-[0px_3px_6px_#00000029]"
					{...context}
				/>
			) : null}
			{mainBlock}
		</div>
	)
}
