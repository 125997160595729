import type {FC} from 'react'

interface PetNameFormProps {
	title: string
	children: React.ReactNode
}

export const CardWrapper: FC<PetNameFormProps> = ({title, children}) => (
	<div
		className="mb-4 md:p-8"
		style={{
			width: '100%',
			background: '#FCFCFC',
			boxShadow: '0px 6px 12px #00000010',
			borderRadius: '6px',
		}}
	>
		<div style={{width: '100%', margin: '20px auto'}}>
			<h2 className="text-center">{title}</h2>
			<div
				className="flex"
				style={{
					justifyContent: 'center',
					gap: '3.5rem',
					flexWrap: 'wrap',
					marginTop: '40px',
				}}
			>
				{children}
			</div>
		</div>
	</div>
)
