import {FC, ReactNode, useEffect} from 'react'
import {useFormContext} from 'react-hook-form'
import {ErrorMessage} from '../Form'

interface DateFieldProps {
	type?: string
	label: ReactNode
	name: string
	className?: string
	isOptional?: boolean
	maxLength?: number
	requiredErrorMsg?: string
	invalidErrorMsg?: string
	defaultValue?: string
	minDate?: string
	maxDate?: string
	onChange?: (value: string) => void
	disabled?: boolean
}

const DateField: FC<DateFieldProps> = ({
	defaultValue = '',
	minDate = '',
	maxDate = '',
	className = '',
	label,
	name,
	isOptional = false,
	requiredErrorMsg = '',
	invalidErrorMsg = '',
	maxLength = 100,
	onChange = () => {},
	disabled = false,
}) => {
	const requiredMsg = requiredErrorMsg || `${label} is required`
	const invalidMsg = invalidErrorMsg || `Please enter a valid ${label}`
	const {register, trigger, formState, clearErrors} = useFormContext()
	useEffect(() => {
		let ignore = false

		async function validation() {
			if (defaultValue && !ignore) {
				await trigger(name)
			}
		}

		validation()

		return () => {
			ignore = true
		}
	}, [defaultValue])

	useEffect(() => {
		clearErrors()
	}, [minDate, maxDate])

	const {onChange: libOnChange, ...registerProps} = register(name, {
		required: !isOptional,
		min: minDate,
		max: maxDate,
	})

	return (
		<div className={`relative mb-4 ${className}`}>
			<label htmlFor={name} className="w-full text-xs text-pet-primary">
				<span>
					{label}
					{!isOptional && <span className="text-red-600">*</span>}
				</span>
				<div className="relative mt-2">
					<input
						{...registerProps}
						onChange={(e) => {
							libOnChange(e)
							onChange(e.target.value)
						}}
						defaultValue={defaultValue}
						disabled={disabled}
						type="date"
						id={name}
						name={name}
						className={`${
							formState.errors[name] ? 'invalid' : ''
						} text-sky-900 rounded-md !bg-white py-3 text-sm font-normal text-pet-primary`}
						maxLength={maxLength}
						min={minDate}
						max={maxDate}
					/>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="25.204"
						height="28.09"
						viewBox="0 0 25.204 28.09"
						className="mr-[10px] mt-[10px]"
					>
						<g
							id="Group_947"
							data-name="Group 947"
							transform="translate(0.75 0.75)"
						>
							<g
								id="Icon_feather-calendar"
								data-name="Icon feather-calendar"
								transform="translate(0 0)"
							>
								<path
									id="Path_335"
									data-name="Path 335"
									d="M7.134,6H25.57A2.654,2.654,0,0,1,28.2,8.674V27.39a2.654,2.654,0,0,1-2.634,2.674H7.134A2.654,2.654,0,0,1,4.5,27.39V8.674A2.654,2.654,0,0,1,7.134,6Z"
									transform="translate(-4.5 -3.473)"
									fill="none"
									stroke="#000"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="1.5"
								/>
								<path
									id="Path_336"
									data-name="Path 336"
									d="M24,3V8.054"
									transform="translate(-6.88 -3)"
									fill="none"
									stroke="#000"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="1.5"
								/>
								<path
									id="Path_337"
									data-name="Path 337"
									d="M12,3V8.054"
									transform="translate(-5.416 -3)"
									fill="none"
									stroke="#000"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="1.5"
								/>
								<path
									id="Path_338"
									data-name="Path 338"
									d="M4.5,15H28.2"
									transform="translate(-4.5 -4.364)"
									fill="none"
									stroke="#000"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="1.5"
								/>
							</g>
							<rect
								id="Rectangle_869"
								data-name="Rectangle 869"
								width="3"
								height="3"
								transform="translate(7.837 12.769)"
								fill="#000"
							/>
							<rect
								id="Rectangle_870"
								data-name="Rectangle 870"
								width="3"
								height="3"
								transform="translate(12.837 12.769)"
								fill="#000"
							/>
							<rect
								id="Rectangle_871"
								data-name="Rectangle 871"
								width="3"
								height="3"
								transform="translate(17.837 12.769)"
								fill="#000"
							/>
							<rect
								id="Rectangle_873"
								data-name="Rectangle 873"
								width="3"
								height="3"
								transform="translate(7.837 16.769)"
								fill="#000"
							/>
							<rect
								id="Rectangle_874"
								data-name="Rectangle 874"
								width="3"
								height="3"
								transform="translate(12.837 16.769)"
								fill="#000"
							/>
							<rect
								id="Rectangle_875"
								data-name="Rectangle 875"
								width="3"
								height="3"
								transform="translate(17.837 16.769)"
								fill="#000"
							/>
							<rect
								id="Rectangle_876"
								data-name="Rectangle 876"
								width="3"
								height="3"
								transform="translate(2.837 16.769)"
								fill="#000"
							/>
							<rect
								id="Rectangle_877"
								data-name="Rectangle 877"
								width="3"
								height="3"
								transform="translate(7.837 20.769)"
								fill="#000"
							/>
							<rect
								id="Rectangle_878"
								data-name="Rectangle 878"
								width="3"
								height="3"
								transform="translate(12.837 20.769)"
								fill="#000"
							/>
							<rect
								id="Rectangle_880"
								data-name="Rectangle 880"
								width="3"
								height="3"
								transform="translate(2.837 20.769)"
								fill="#000"
							/>
						</g>
					</svg>
					{formState.errors[name] && (
						<ErrorMessage
							message={
								formState.errors[name]?.type === 'required'
									? requiredMsg
									: invalidMsg
							}
						/>
					)}
				</div>
			</label>
		</div>
	)
}

export default DateField
