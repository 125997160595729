import {ErrorResponse} from '@/models/ResponseType'
import http from '@/utils/axios'

type LocalisedDate = {
	localisedDate: string
}

const isLocalisedDate = (
	data: LocalisedDate | ErrorResponse
): data is LocalisedDate => {
	return Object.prototype.hasOwnProperty.call(data, 'localisedDate')
}

export const fetchLocalisedDate = async (date: string, locale: string) => {
	const dateFormatted = date.split('T')[0]
	const endpoint = `/api/getLocalisedDate?date=${dateFormatted}&locale=${locale}`
	const {data} = await http<LocalisedDate | ErrorResponse>(endpoint)

	if (isLocalisedDate(data)) {
		return data.localisedDate
	}

	throw new Error(data.error)
}
