import {Button} from '@elanco/component-library-v2'
import Link from 'next/link'
import Image from 'next/future/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useRouter} from 'next/router'
import {addLocalePrefix} from '@/components/BlockMapper/utils'
import type {CtaButtonContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'
import {pushToDataLayer} from '@/utils/analytics'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import type {IconWithTextContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/icon-with-text'
import {useWindowSize} from '@/_new-code/utilities/hooks/use-window-size'

type BenefitsHeroContentItem = IContentItem<{
	title: Elements.RichTextElement
	iconsWithText: Elements.LinkedItemsElement<IconWithTextContentItem>
	ctaButton: Elements.LinkedItemsElement<CtaButtonContentItem>
	image: Elements.AssetsElement
	mobileImage: Elements.AssetsElement
}>

type BenefitsContentContentItem = IContentItem<{
	image: Elements.AssetsElement
	mobileImage: Elements.AssetsElement
	title: Elements.TextElement
	description: Elements.TextElement
	ctaButton: Elements.LinkedItemsElement<CtaButtonContentItem>
	benefitsTitle: Elements.RichTextElement
}>

export type BenefitsModuleContentItem = IContentItem<{
	benefitsHero: Elements.LinkedItemsElement<BenefitsHeroContentItem>
	benefitsContent: Elements.LinkedItemsElement<BenefitsContentContentItem>
}>

export const BenefitsModuleBlock: Block<BenefitsModuleContentItem> = ({
	block,
}) => {
	const size = useWindowSize()

	const {locale} = useRouter()

	const benefitsHero = block.elements.benefitsHero[0]
	const heroCtaButton = benefitsHero?.elements.ctaButton[0]
	const heroDesktopImage = benefitsHero?.elements.image[0]
	const heroMobileImage = benefitsHero?.elements.mobileImage[0]

	const benefitsContent = block.elements.benefitsContent[0]
	const contentDesktopImage = benefitsContent?.elements.image[0]
	const contentMobileImage = benefitsContent?.elements.mobileImage[0]
	const contentCtaButton = benefitsContent?.elements.ctaButton[0]
	const contentRichText = benefitsContent?.elements.benefitsTitle

	const hasMobileImage = size.isMobile && contentMobileImage?.url !== ''

	return (
		<div className="-mt-8 flex flex-col py-6">
			<div className="container-wide relative my-2 flex flex-col md:h-[450px] md:flex-row md:justify-between md:px-12 lg:px-14">
				<div className="absolute -left-12 top-2 z-0 h-[230px] w-[230px] rounded-full bg-[#CFEAC640] sm:-top-6 md:left-12 md:top-14 md:block lg:left-12 lg:top-6 lg:h-[280px] lg:w-[280px] xl:left-20" />
				<div className="z-20 ml-6 mt-6 flex flex-col md:mt-20 lg:ml-8 lg:w-1/2 lg:px-16 xl:ml-12">
					<div
						className="mb-4 ml-2"
						dangerouslySetInnerHTML={{
							__html: benefitsHero?.elements.title.value ?? '',
						}}
					/>
					{benefitsHero?.elements.iconsWithText.map((item) => (
						<div className="flex flex-col" key={item.system.id}>
							<div className="flex flex-row items-center justify-start">
								<div>
									{item.elements.icon[0] ? (
										<Image
											alt={
												item.elements.icon[0]
													.description ?? ''
											}
											className="h-10 w-10 p-2"
											height={40}
											loader={loadImageFromKontentAI}
											src={item.elements.icon[0].url}
											width={40}
										/>
									) : null}
								</div>
								<div className="flex items-center text-lg">
									{item.elements.title}
								</div>
							</div>
						</div>
					))}
					<div className="ml-2 mt-8">
						<Link
							href={addLocalePrefix(
								heroCtaButton?.elements.url ?? '',
								locale ?? ''
							)}
							passHref
						>
							<Button
								as="a"
								className="!px-12 !py-2 uppercase"
								href={addLocalePrefix(
									heroCtaButton?.elements.url ?? '',
									locale ?? ''
								)}
								onClick={() => {
									pushToDataLayer({
										event: 'cta_click',
										cta_name: heroCtaButton?.elements.text,
										cta_category:
											heroCtaButton?.system.type,
									})
								}}
								title={heroCtaButton?.elements.text}
								variant={
									heroCtaButton?.elements.variant[0]
										?.codename || 'pet_primary_green'
								}
							>
								{heroCtaButton?.elements.text}
							</Button>
						</Link>
					</div>
				</div>
				<div className="relative mx-auto mt-20 md:mt-12 lg:mr-6 lg:mt-0">
					<div className="absolute -left-6 -top-6 -z-20 h-[150px] w-[150px] rounded-full bg-[#258821AA]/[0.4] md:-left-12 md:-top-12 md:block md:h-[200px] md:w-[200px]" />
					<div className="absolute -left-20 bottom-6 z-0 h-[100px] w-[100px] rounded-full bg-[#CFEAC640] md:bottom-20 md:block" />
					<div className="block w-full max-w-[300px] md:max-w-[420px]">
						{hasMobileImage ? (
							<Image
								alt={heroMobileImage?.description ?? ''}
								height={heroMobileImage?.height ?? 300}
								loader={loadImageFromKontentAI}
								priority
								src={heroMobileImage?.url ?? ''}
								width={heroMobileImage?.width ?? 300}
							/>
						) : (
							<Image
								alt={heroDesktopImage?.description ?? ''}
								height={heroDesktopImage?.height ?? 420}
								loader={loadImageFromKontentAI}
								priority
								src={heroDesktopImage?.url ?? ''}
								width={heroDesktopImage?.width ?? 420}
							/>
						)}
					</div>
				</div>
			</div>

			<div className="container-wide relative my-2 flex flex-col items-start md:flex-row">
				<div className="hidden md:flex">
					<div className="relative mt-8 w-full max-w-[590px] sm:mt-12 md:mt-4">
						{contentDesktopImage ? (
							<Image
								alt={contentDesktopImage.description ?? ''}
								height={contentDesktopImage.height ?? 400}
								loader={loadImageFromKontentAI}
								priority
								src={contentDesktopImage.url}
								width={contentDesktopImage.width ?? 590}
							/>
						) : null}
					</div>
				</div>
				<div className="z-20 mt-0 flex flex-col items-start md:my-6 md:w-1/2">
					<div className="mr-6 px-6 sm:pt-4">
						<h2 className="mb-2">
							{benefitsContent?.elements.title}
						</h2>
					</div>
					<div className="mr-6 px-6">
						<p className="my-2">
							{benefitsContent?.elements.description}
						</p>
					</div>
					<div className="mb-12 mt-6 px-6">
						<Link
							href={addLocalePrefix(
								contentCtaButton?.elements.url ?? '',
								locale ?? ''
							)}
						>
							<Button
								as="a"
								className="!px-12 !py-2 uppercase"
								onClick={() => {
									pushToDataLayer({
										event: 'cta_click',
										cta_name:
											contentCtaButton?.elements.text,
										cta_category:
											contentCtaButton?.system.type,
									})
								}}
								title={contentCtaButton?.elements.text}
								variant={
									contentCtaButton?.elements.variant[0]
										?.codename || 'pet_primary_green'
								}
							>
								{contentCtaButton?.elements.text}
							</Button>
						</Link>
					</div>
					<div className="my-6 mr-6 hidden flex-row justify-center px-6 md:flex">
						<div className="my-2">
							<div
								className="content-rich-text mb-4 ml-2"
								dangerouslySetInnerHTML={{
									__html: contentRichText?.value ?? '',
								}}
							/>
						</div>
					</div>
				</div>
				<div className="mt-12 flex flex-col px-6 md:hidden">
					<div className="my-2">
						<div
							className="content-rich-text mb-4 ml-2"
							dangerouslySetInnerHTML={{
								__html: contentRichText?.value ?? '',
							}}
						/>
					</div>
					<div className="my-2 block w-full max-w-[480px]">
						{contentMobileImage ? (
							<Image
								alt={contentMobileImage.description ?? ''}
								height={contentMobileImage.height ?? 360}
								loader={loadImageFromKontentAI}
								src={contentMobileImage.url}
								width={contentMobileImage.width ?? 480}
							/>
						) : null}
					</div>
				</div>
			</div>
		</div>
	)
}
