import React from 'react'

interface TableRowData {
	id: string
	content: React.ReactNode
}

interface TableRow {
	id: string
	data: TableRowData[]
}

interface TableBlockWithMetaIconsProps {
	table: {
		rows: TableRow[]
	}
}

export const TableBlockWithMetaIcons: React.FC<
	TableBlockWithMetaIconsProps
> = ({table}) => {
	return (
		<div>
			<table className="mx-auto w-full max-w-sm md:hidden">
				<tbody>
					{table.rows[0]?.data.slice(1).map((_, index) => (
						<tr key={table.rows[0]?.data[index + 1]?.id}>
							<td className="p-4">
								{table.rows.map((row) => (
									<span
										className={`flex items-end ${
											row !==
											table.rows[table.rows.length - 1]
												? 'mb-2'
												: ''
										}`}
										key={row.id}
									>
										<span className="mr-2 block w-4/5">
											{row.data[0]?.content}
											<span className="font-bold">:</span>
										</span>
										<span className="w-1/5">
											{row.data[index + 1]?.content}
										</span>
									</span>
								))}
							</td>
						</tr>
					))}
				</tbody>
			</table>

			<table className="hidden w-full md:table">
				<tbody>
					{table.rows.map((row, i) => (
						<tr key={row.id}>
							{row.data.map((data, j) => (
								<td
									className={`p-4 text-center ${
										i === 0 && j !== 0 ? 'align-bottom' : ''
									}`}
									key={data.id}
								>
									{data.content}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}
