import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {
	TableBlockWithMetaIcons,
	TableHeader,
	TableMetaIcon,
} from '../../components/table'

type TableBlockWithIconsRowContentItem = IContentItem<{
	rowTitle: Elements.TextElement
	rowItemIconTwo: Elements.AssetsElement
	rowItemTextTwo: Elements.TextElement
	rowItemIconThree: Elements.AssetsElement
	rowItemTextThree: Elements.TextElement
	rowItemIconFour: Elements.AssetsElement
	rowItemTextFour: Elements.TextElement
	rowItemIconFive: Elements.AssetsElement
	rowItemTextFive: Elements.TextElement
	rowItemIconSix: Elements.AssetsElement
	rowItemTextSix: Elements.TextElement
}>

export type TableBlockWithIconsContentItem = IContentItem<{
	title: Elements.TextElement
	rowData: Elements.LinkedItemsElement<TableBlockWithIconsRowContentItem>
	description: Elements.TextElement
	tabTitle: Elements.TextElement
}>

export const TableBlockWithIconsBlock: Block<
	TableBlockWithIconsContentItem
> = ({
	block: {
		elements: {title, rowData, description},
	},
}) => {
	return (
		<div>
			<h3 className="mb-4" data-kontent-element-codename="title">
				{title}
			</h3>
			<TableBlockWithMetaIcons
				table={{
					rows: rowData.map((row) => ({
						id: row.system.id,
						data: [
							{
								content: (
									<TableHeader text={row.elements.rowTitle} />
								),
								id: row.system.id,
							},
							row.elements.rowItemTextTwo && {
								content: (
									<TableMetaIcon
										iconUrl={
											row.elements.rowItemIconTwo[0]?.url
										}
										text={row.elements.rowItemTextTwo}
									/>
								),
								id: `${row.system.id}-item-two`,
							},
							row.elements.rowItemTextThree && {
								content: (
									<TableMetaIcon
										iconUrl={
											row.elements.rowItemIconThree[0]
												?.url
										}
										text={row.elements.rowItemTextThree}
									/>
								),
								id: `${row.system.id}-item-three`,
							},
							row.elements.rowItemTextFour && {
								content: (
									<TableMetaIcon
										iconUrl={
											row.elements.rowItemIconFour[0]?.url
										}
										text={row.elements.rowItemTextFour}
									/>
								),
								id: `${row.system.id}-item-four`,
							},
							row.elements.rowItemTextFive && {
								content: (
									<TableMetaIcon
										iconUrl={
											row.elements.rowItemIconFive[0]?.url
										}
										text={row.elements.rowItemTextFive}
									/>
								),
								id: `${row.system.id}-item-five`,
							},
							row.elements.rowItemTextSix && {
								content: (
									<TableMetaIcon
										iconUrl={
											row.elements.rowItemIconSix[0]?.url
										}
										text={row.elements.rowItemTextSix}
									/>
								),
								id: `${row.system.id}-item-six`,
							},
						].filter(Boolean),
					})),
				}}
			/>
			<p
				className="mx-8 my-10"
				data-kontent-element-codename="description"
			>
				{description}
			</p>
		</div>
	)
}
