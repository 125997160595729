import {FullscreenVideo, HeaderCard} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useTrustArcCookieValue} from '@/utils/analytics'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {
	ReusableCTAButtonComponent,
	type CtaButtonContentItem,
} from '../cta-button'

export type HomeVideoBannerContentItem = IContentItem<{
	displayText: Elements.TextElement
	backgroundColour: Elements.TextElement
	videoId: Elements.TextElement
	headerTitle: Elements.TextElement
	posterUrl: Elements.AssetsElement
	buttons: Elements.LinkedItemsElement<CtaButtonContentItem>
}>

export const HomeVideoBannerBlock: Block<HomeVideoBannerContentItem> = ({
	block: {
		elements: {
			backgroundColour,
			buttons: [button],
			displayText,
			headerTitle,
			posterUrl: [posterImage],
			videoId,
		},
	},
	...context
}) => {
	const trustArcCookie = useTrustArcCookieValue()
	const hideVideo =
		trustArcCookie === '1:' || trustArcCookie === '0:' ? 'hide' : ''

	return (
		<FullscreenVideo
			backgroundColor={backgroundColour}
			controls={1}
			id="header"
			posterFrameAlignment="top"
			posterFrameImageAltText={posterImage?.description}
			posterFrameImageUrl={posterImage?.url}
			videoId={hideVideo === 'hide' ? '' : videoId}
		>
			<HeaderCard className="bg-blue-900 text-white" title={headerTitle}>
				<p
					className="mb-4 mt-3"
					data-kontent-element-codename="display_text"
				>
					{displayText}
				</p>
				{button ? (
					<ReusableCTAButtonComponent block={button} {...context} />
				) : null}
			</HeaderCard>
		</FullscreenVideo>
	)
}
