import {Steps, Step, Image} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {CtaButtonContentItem} from '../cta-button'
import {ReusableCTAButtonComponent} from '../cta-button'

type StepContentItem = IContentItem<{
	stepImg: Elements.AssetsElement
	title: Elements.TextElement
	paragraph: Elements.TextElement
}>

export type StepsContentItem = IContentItem<{
	stepsTitle: Elements.TextElement
	stepsName: Elements.LinkedItemsElement<StepContentItem>
	stepsDescription: Elements.TextElement
	ctaButton: Elements.LinkedItemsElement<CtaButtonContentItem>
	tabTitle: Elements.TextElement
}>

export const StepsBlock: Block<StepsContentItem> = ({block, ...context}) => {
	return (
		<>
			{Boolean(block.elements.stepsTitle) && (
				<h1
					className="container-wide text-center"
					data-kontent-element-codename="steps_title"
				>
					{block.elements.stepsTitle}
				</h1>
			)}
			<Steps>
				{block.elements.stepsName.map((step, idx) => (
					<Step
						data-kontent-item-id={step.system.id}
						key={step.system.id}
						step={`${idx + 1}`}
						title={step.elements.title}
					>
						{step.elements.stepImg[0] ? (
							<Image
								className="absolute inset-0 m-auto"
								data-kontent-element-codename="step_img"
								height={150}
								loader={loadImageFromKontentAI}
								src={step.elements.stepImg[0].url}
								width={150}
							/>
						) : null}
						<p data-kontent-element-codename="paragraph">
							{step.elements.paragraph}
						</p>
					</Step>
				))}
			</Steps>
			{Boolean(block.elements.stepsDescription) && (
				<p
					className="content mt-6"
					data-kontent-element-codename="steps_description"
				>
					{block.elements.stepsDescription}
				</p>
			)}
			{block.elements.ctaButton.length > 0 ? (
				<div className="justify-center text-center sm:flex md:flex-row">
					{block.elements.ctaButton.map((button) => (
						<ReusableCTAButtonComponent
							block={button}
							className="mt-6 w-2/3 max-w-xs md:w-1/2"
							key={button.system.id}
							{...context}
						/>
					))}
				</div>
			) : null}
		</>
	)
}
