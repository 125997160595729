/** Kontent.ai codename -\> Clean name for display */
const DATA = {
	agricultural_merchant___farm_store: 'Agricultural Merchant / Farm Store',
	allied_industry: 'Allied Industry',
	animal_health_advisory_service: 'Animal Health Advisory Service',
	animal_shelter___charity: 'Animal Shelter / Charity',
	aqua_breeding_company: 'Aqua Breeding Company',
	aquaculture_company: 'Aquaculture Company',
	backgrounder_lot: 'Backgrounder Lot',
	beef_producer: 'Beef Producer',
	brick: 'Brick',
	broilers: 'Broilers',
	buying_group: 'Buying Group',
	calf_production: 'Calf Production',
	calf_ranch___developer: 'Calf Ranch / Developer',
	cattle_buyer: 'Cattle Buyer',
	co_operative: 'Co Operative',
	competitor: 'Competitor',
	consulting_services: 'Consulting Services',
	contract_grower: 'Contract Grower',
	contract_research_organization: 'Contract Research Organization',
	corporate_office: 'Corporate Office',
	cow___calf_operation: 'Cow / Calf Operation',
	custom_applicator: 'Custom Applicator',
	dairy_production: 'Dairy Production',
	diagnostic___laboratory_svcs: 'Diagnostic / Laboratory Svcs',
	disinfection_company: 'Disinfection Company',
	distributor___agent: 'Distributor / Agent',
	feed_mill: 'Feed Mill',
	feedyard: 'Feedyard',
	financial_institution: 'Financial Institution',
	fish_health_services: 'Fish Health Services',
	grower___growyard: 'Grower / Growyard',
	hatchery: 'Hatchery',
	heifer_production: 'Heifer Production',
	human_medical: 'Human Medical',
	industry_group: 'Industry Group',
	internal_employee: 'Internal Employee',
	layer_integration: 'Layer Integration',
	layers: 'Layers',
	live_exporter: 'Live Exporter',
	marine_site: 'Marine Site',
	media: 'Media',
	milk_cooperative___buyer: 'Milk Cooperative / Buyer',
	mixed___other_producer: 'Mixed / Other Producer',
	nutrient_supplier: 'Nutrient Supplier',
	online: 'Online',
	organization_vod: 'Organization_vod',
	packer___processor: 'Packer / Processor',
	pastoral_company: 'Pastoral Company',
	pet_retail_outlet: 'Pet Retail Outlet',
	pet_retail_outlet___brick___mortar: 'Pet Retail Outlet - Brick & Mortar',
	pet_retail_outlet___e_commerce: 'Pet Retail Outlet - E-Commerce',
	pet_retail_outlet___food_drug_mass: 'Pet Retail Outlet - Food Drug Mass',
	pharmacy: 'Pharmacy',
	poultry_breeder: 'Poultry Breeder',
	poultry_integrator: 'Poultry Integrator',
	publishing_house: 'Publishing House',
	restaurant___food_svcs: 'Restaurant / Food Svcs',
	satellite_office: 'Satellite Office',
	sheep_producer: 'Sheep Producer',
	specialty: 'Specialty',
	stocker_operation: 'Stocker Operation',
	stockyard___salebarn: 'Stockyard / Salebarn',
	swine_producer: 'Swine Producer',
	turkeys: 'Turkeys',
	university___education: 'University / Education',
	vaccination_company: 'Vaccination Company',
	veterinary_practice: 'Veterinary Practice',
	wholesaler___retailer: 'Wholesaler / Retailer',
	wool_buyer: 'Wool Buyer',
}

export const BUSINESS_TYPE_MAP = new Map(Object.entries(DATA))
