export const ErrorTooltip = ({message}: {message: string}): JSX.Element => {
	return (
		<div className="absolute left-0 top-0 z-10 -ml-2 mt-10 flex rotate-45 items-center rounded-lg border-2 border-red-500 bg-white px-2 py-2 text-xs not-italic text-red-500 before:absolute before:left-0 before:top-0 before:-mt-2 before:ml-4 before:block before:h-3 before:w-3 before:border-2 before:border-b-0 before:border-r-0 before:border-red-500 before:bg-white before:content-['']">
			<svg
				className="inline-block fill-current"
				height="20"
				viewBox="0 0 27 27"
				width="20"
			>
				<path d="M13.3,26.8c-3.6,0-7-1.4-9.5-4c-2.5-2.5-4-6-4-9.5c0-3.6,1.4-7,4-9.5c2.5-2.5,6-4,9.5-4c3.6,0,7,1.4,9.5,4c2.5,2.5,4,6,4,9.5c0,3.6-1.4,7-4,9.5C20.3,25.4,16.8,26.8,13.3,26.8z M13.6,17.8c-0.7,0-1.4,0.2-1.9,0.6c-0.4,0.4-0.7,.1-0.7,1.7c0,0.6,0.2,1.2,0.7,1.7c0.5,0.4,1.2,0.6,1.9,0.6c0.7,0,1.3-0.2,1.8-0.6c0.5-0.4,0.7-1.1,0.7-1.7c0-0.6-0.2-1.2-0.6-1.7C14.9,18,14.2,17.8,13.6,17.8L13.6,17.8z M11,4.6L11,4.6l0.6,11.4h4l0.6-11.4H11z" />
			</svg>
			<span className="pl-2 text-black">{message}</span>
		</div>
	)
}
