import {useEffect, useState} from 'react'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import NextImage from 'next/future/image'
import {clsx} from 'clsx'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block, TersedAsset} from '@/_new-code/services/kontent-ai/types'
import {spacing} from '@/_new-code/products/flexible-web-toolkit/styles'
import {useWindowSize} from '@/_new-code/utilities/hooks/use-window-size'

export type FloatingComponentContentItem = IContentItem<{
	item: Elements.LinkedItemsElement
	position: Elements.MultipleChoiceElement
	itemBgColor: Elements.MultipleChoiceElement
	image: Elements.AssetsElement
	mobileImage: Elements.AssetsElement
	snippetSpacingSpacing: Elements.MultipleChoiceElement
}>

export const FloatingComponentBlock: Block<FloatingComponentContentItem> = ({
	block,
	BlockMapper,
	...context
}) => {
	const blockItem = block.elements.item[0]
	const itemPosition =
		block.elements.position[0]?.codename === 'right'
			? 'md:flex-row-reverse'
			: null
	const itemBackgroundColor =
		// eslint-disable-next-line no-nested-ternary -- Readable enough
		block.elements.itemBgColor[0]?.codename === 'light'
			? 'bg-white'
			: block.elements.itemBgColor[0]?.codename === 'dark'
				? 'bg-black'
				: null

	const size = useWindowSize() as {isMobile: boolean; width: number}

	const [image, setImage] = useState<TersedAsset | null>(null)
	useEffect(() => {
		if (size.isMobile && block.elements.mobileImage[0]) {
			setImage(block.elements.mobileImage[0] || null)
		} else {
			setImage(block.elements.image[0] || null)
		}
	}, [block.elements.image, block.elements.mobileImage, size.isMobile])

	// Sets the height of Background image so it fills without cropping and maintains aspect ratio of image
	const [imageHeight, setImageHeight] = useState<number | null>(null)
	useEffect(() => {
		// Calculate or reset height based on screen size (desktop vs mobile)
		if (!size.isMobile && image?.width && image.height) {
			const aspectRatio = image.width / image.height
			setImageHeight(size.width / aspectRatio)
		} else {
			setImageHeight(null) // Reset height to auto for mobile
		}
	}, [size.width, size.isMobile, image])

	const spacingChoice =
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not unnecessary as key may evaluate to undefined
		spacing[
			block.elements.snippetSpacingSpacing[0]
				?.codename as keyof typeof spacing
		] || spacing.default

	return (
		<div
			className={clsx(
				`relative flex w-full items-center justify-center p-3 md:justify-start md:p-10`,
				itemPosition,
				spacingChoice
			)}
			style={{height: imageHeight || 'auto'}}
		>
			<NextImage
				alt={image?.description || ''}
				className="w-full object-cover"
				fill
				loader={loadImageFromKontentAI}
				quality={100}
				sizes="100vw"
				src={image?.url || ''}
			/>
			{blockItem && image ? (
				<div
					className={clsx(
						itemBackgroundColor,
						`z-10 max-h-[100%] flex-shrink overflow-auto p-8 md:max-w-[70%] md:flex-none lg:max-w-[45%]`
					)}
				>
					<BlockMapper blocks={block.elements.item} {...context} />
				</div>
			) : null}
		</div>
	)
}
