import {QueryFunctionContext} from 'react-query'
import http from '@/utils/axios'
import {FaqsOutput} from '@/pages/api/v2/faqs-by-codenames'
import {FaqItemContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/faq'
import {Tersed} from '@/_new-code/services/kontent-ai/types'

type FaqsByCodenamesQueryKey = [string, {locale: string; codenames?: string[]}]

type FaqsByCodenameError = {
	message: string
}

export async function fetchFaqsByCodenames(
	context: QueryFunctionContext<FaqsByCodenamesQueryKey>
) {
	const [, params] = context.queryKey
	const searchParams = new URLSearchParams({
		locale: params.locale,
	})

	if (params.codenames) {
		searchParams.set(
			'codenames',
			encodeURIComponent(params.codenames.join())
		)
	}

	const {data} = await http<FaqsOutput<Tersed<FaqItemContentItem>>>(
		`/api/v2/faqs-by-codenames?${searchParams.toString()}`
	)

	if (!data.success) {
		throw new Error(data.message) as FaqsByCodenameError
	}

	// We filter out any faqs that have zero content in them (empty rich text always includes '<p><br></p>')
	return {
		...data,
		items: data.items.filter(
			(item) =>
				item.elements.title.trim().length > 0 &&
				item.elements.content.value !== '<p><br></p>'
		),
	}
}
