import type {Tersed} from '@/_new-code/services/kontent-ai/types'
import type {
	SharedQuestionProps,
	SingleSelectionContentItem,
} from '@/_new-code/products/campaign-forms/cdc-screen-set-wrapper/models'
import {insertAsterisk} from '../../../../../utils/helpers'
import {useCampaignQuestion} from './hooks'

const defaultSelectionPrompt = 'Please select an option'

interface SingleSelectDropdownProps extends SharedQuestionProps {
	question: Tersed<SingleSelectionContentItem>
}

export const SingleSelectDropdown = ({
	question,
	selectedOptionsCallback,
	injectedElementsCallback,
	errorsCallback,
	validationEvent,
}: SingleSelectDropdownProps): JSX.Element => {
	const {
		required,
		questionKontentItemId,
		displayError,
		errorClass,
		setSelectedItems,
	} = useCampaignQuestion({
		question,
		selectedOptionsCallback,
		injectedElementsCallback,
		errorsCallback,
		validationEvent,
	})

	const labelId = `label-${questionKontentItemId}`
	const selectId = `select-${questionKontentItemId}`
	const spanId = `span-${questionKontentItemId}`
	injectedElementsCallback(labelId)
	injectedElementsCallback(selectId)
	injectedElementsCallback(spanId)

	return (
		<div
			className="gigya-composite-control gigya-composite-control-dropdown"
			id={questionKontentItemId}
		>
			<label className="gigya-label" htmlFor={selectId} id={labelId}>
				<span className="gigya-label-text" id={spanId}>
					<div
						dangerouslySetInnerHTML={{
							__html: insertAsterisk(
								question.elements.questionText.value,
								required
							),
						}}
					/>
				</span>
				{/* eslint-disable-next-line jsx-a11y/label-has-associated-control -- necessary */}
				{required ? <label className="hidden">*</label> : null}
			</label>
			<select
				className={errorClass}
				id={selectId}
				name={selectId}
				onChange={(evt) => {
					const {options} = evt.target
					const value: string[] = []
					for (const option of options) {
						if (option.selected && option.value) {
							value.push(option.value)
						}
					}
					setSelectedItems(value)
				}}
			>
				<option key="default-option" value="">
					{question.elements.selectionPrompt ||
						defaultSelectionPrompt}{' '}
					{required ? '*' : null}
				</option>

				{question.elements.selections.map((selection) => (
					<option
						id={selection.system.id}
						key={selection.system.id}
						value={selection.elements.selectionValue}
					>
						{selection.elements.displayValue}
					</option>
				))}
			</select>
			{displayError ? (
				<span className="gigya-error-msg gigya-error-msg-active">
					This field is required
				</span>
			) : null}
		</div>
	)
}
