import type {FC} from 'react'
import {useEffect, useState} from 'react'
import {CalendarBlock} from '@/_new-code/products/your-pet-and-you/blocks/dashboard-page/module-my-reminders/calendar-block'
import type {Reminder} from '@/fetchers/fetchReminders'
import {getMonthlyReminders} from '@/_new-code/products/your-pet-and-you/blocks/dashboard-page/module-my-reminders/get-monthly-reminders'

interface CalendarViewProps {
	heading: string
	reminders: Reminder[]
	defaultDate: Date
	activePet: string
}

export const CalendarView: FC<CalendarViewProps> = ({
	heading = '',
	reminders,
	defaultDate,
	activePet,
}) => {
	const [calendarDates, setCalendarDates] = useState<string[]>([])
	useEffect(() => {
		let reminderData = reminders
		if (activePet)
			reminderData = reminders.filter(({pet}) => pet?.id === activePet)

		const monthlyReminders = getMonthlyReminders(reminderData)
		const datesArray = monthlyReminders.flatMap((rem) => [
			...(rem.pastDates ?? []),
			...(rem.recurringDates ?? []),
		])

		setCalendarDates(datesArray)
	}, [reminders, activePet])

	return (
		<div className="flex flex-1 flex-col rounded rounded-b-none border border-pet-midnight md:rounded-b md:rounded-r-none">
			<div className="bg-pet-midnight">
				<h4 className="pb-4 pt-4 text-center font-semibold uppercase text-white">
					{heading}
				</h4>
			</div>
			<CalendarBlock
				activeDateColor="bg-[#EBEBEB]"
				btnVariant="pet_primary_green"
				dates={calendarDates}
				hideNeighboringMonth
				iconActiveColor="border-pet-midnight"
				selectedDate={defaultDate}
			/>
		</div>
	)
}
