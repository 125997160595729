import {Tab as CTab, Tabs as CTabs} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'

type FullTabbedBlockTabContentItem = IContentItem<{
	heading: Elements.TextElement
	body: Elements.RichTextElement
	pinned: Elements.MultipleChoiceElement
}>

export type FullTabbedBlockContentItem = IContentItem<{
	tabs: Elements.LinkedItemsElement<FullTabbedBlockTabContentItem>
}>

export const FullTabbedBlockBlock: Block<FullTabbedBlockContentItem> = ({
	block,
	...context
}) => {
	return (
		<div className="mt-12 md:mt-16">
			<CTabs aria-label="Footer tabs" name="fullTabs">
				{block.elements.tabs.map(
					({elements: {heading, body, pinned}, system: {id}}) => (
						<CTab
							data-kontent-item-id={id}
							id={`full-tab-${id}`}
							key={id}
							pinned={pinned[0]?.codename === 'yes'}
							title={
								<span data-kontent-element-codename="heading">
									{heading}
								</span>
							}
						>
							<RichTextRenderer
								className="flex-g break-words"
								data-kontent-element-codename="body"
								element={body}
								{...context}
							/>
						</CTab>
					)
				)}
			</CTabs>
		</div>
	)
}
