import {useState, useEffect} from 'react'
import {ShareBlock as CShareBlock} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'

export type ShareBlockContentItem = IContentItem<{
	shareTitle: Elements.TextElement
	facebookShareUrl: Elements.TextElement
	twitterShareUrl: Elements.TextElement
	linkedinShareUrl: Elements.TextElement
	emailShareSubject: Elements.TextElement
	emailShareBody: Elements.TextElement
}>

export const ShareBlockBlock: Block<ShareBlockContentItem> = ({block}) => {
	const [articleLink, setArticleLink] = useState('')

	useEffect(() => {
		if (typeof window !== 'undefined') {
			setArticleLink(window.location.href)
		}
	}, [])

	return (
		<CShareBlock
			data-kontent-element-codename="share_block"
			facebookShareUrl={block.elements.facebookShareUrl}
			linkedinShareUrl={block.elements.linkedinShareUrl}
			mailBody={`${block.elements.emailShareBody}\n${articleLink}`}
			mailSubject={block.elements.emailShareSubject}
			title={block.elements.shareTitle}
			twitterShareUrl={block.elements.twitterShareUrl}
		/>
	)
}
