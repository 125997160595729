import {setupBusinessSearch, validateBusiness} from './business-search'
import {
	confirmEmailFieldsMatch,
	setupEmailConfirmation,
} from './email-confirmation'

export class CDCScreenSetValidator {
	emailMatch = false

	businessIsValid = false

	setup(locale: string, noResultsText?: string): void {
		setupEmailConfirmation()
		setupBusinessSearch(locale, noResultsText)
	}

	isValid(): boolean {
		return this.emailMatch && this.businessIsValid
	}

	validate(): void {
		this.emailMatch = confirmEmailFieldsMatch()
		this.businessIsValid = validateBusiness()
	}
}
