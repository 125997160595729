import {LoadingSpinner} from '@/_new-code/products/flexible-web-toolkit/components/loading-spinner'
import {FC} from 'react'

interface LoadingStateProps {
	theme: string
	isLoading: boolean
}

const LoadingState: FC<LoadingStateProps> = ({
	theme,
	isLoading,
}): JSX.Element => {
	// eslint-disable-next-line react/jsx-no-useless-fragment
	if (!isLoading) return <></>

	return (
		<div className="absolute bottom-0 left-0 right-0 top-0 z-20 flex items-center justify-center bg-white/70">
			<LoadingSpinner theme={theme} />
		</div>
	)
}

export default LoadingState
