import {Button} from '@elanco/component-library-v2'
import Link from 'next/link'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useRouter} from 'next/router'
import {addLocalePrefix} from '@/components/BlockMapper/utils'
import type {CtaButtonContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'
import type {LinkContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/link'
import type {Block} from '@/_new-code/services/kontent-ai/types'

export type RebatesBlockContentItem = IContentItem<{
	heading: Elements.TextElement
	redeemRebateCta: Elements.LinkedItemsElement<CtaButtonContentItem>
	trackRebateCta: Elements.LinkedItemsElement<CtaButtonContentItem>
	viewAllRebatesLink: Elements.LinkedItemsElement<LinkContentItem>
}>

export const RebatesBlockBlock: Block<RebatesBlockContentItem> = ({
	block: {
		elements: {
			heading,
			redeemRebateCta,
			trackRebateCta,
			viewAllRebatesLink,
		},
	},
}) => {
	const {locale = ''} = useRouter()

	return (
		<div className="w-full bg-[#ebebeb] py-8">
			<div className="container-wide">
				{heading ? (
					<h2 className="text-2xl font-semibold text-midnight-blue">
						{heading}
					</h2>
				) : null}
				<div className="my-4 flex flex-col space-y-4 uppercase md:flex-row md:space-x-4 md:space-y-0">
					<div className="relative flex h-[111px] w-full flex-col items-center justify-center rounded border border-green-600 bg-white md:max-w-[373px] md:basis-1/3">
						{redeemRebateCta.map((button) => (
							<Link
								href={addLocalePrefix(
									button.elements.url,
									locale
								)}
								key={button.system.id}
							>
								<Button
									as="a"
									className="flex h-full w-full items-center justify-center !bg-white text-center font-semibold !text-green-600"
									rel="noreferrer"
									target={
										button.elements.openLinkInNewTab[0]
											?.codename === 'yes'
											? '_blank'
											: '_self'
									}
								>
									{button.elements.text}
								</Button>
							</Link>
						))}
					</div>
					<div className="relative flex h-[111px] w-full flex-col items-center justify-center rounded border border-green-600 bg-white md:max-w-[373px] md:basis-1/3">
						{trackRebateCta.map((button) => (
							<Link
								href={addLocalePrefix(
									button.elements.url,
									locale
								)}
								key={button.system.id}
							>
								<Button
									as="a"
									className="flex h-full w-full items-center justify-center !bg-white text-center font-semibold !text-green-600"
									rel="noreferrer"
									target={
										button.elements.openLinkInNewTab[0]
											?.codename === 'yes'
											? '_blank'
											: '_self'
									}
								>
									{button.elements.text}
								</Button>
							</Link>
						))}
					</div>
				</div>
				{viewAllRebatesLink.map((link) => (
					<Link
						href={addLocalePrefix(link.elements.url, locale)}
						key={link.system.id}
						passHref
					>
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid -- TODO: remove when next/link updates */}
						<a
							className="text-sm uppercase text-green-600"
							rel="noreferrer"
							target={
								link.elements.openInNewTab[0]?.codename ===
								'yes'
									? '_blank'
									: '_self'
							}
						>
							{link.elements.text}
							<span className="ml-1 text-xl">&rsaquo;</span>
						</a>
					</Link>
				))}
			</div>
		</div>
	)
}
