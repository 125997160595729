export const LoadingSpinner = ({theme}: {theme: string}): JSX.Element => (
	<div className={`lds-spinner ${theme}`}>
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
	</div>
)
