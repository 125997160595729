import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useRouter} from 'next/router'
import {addLocalePrefix} from '@/components/BlockMapper/utils'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {CarouselCTA} from '../../components/carousel/carousel-cta'
import {CarouselCTABlock as Carousel} from '../../components/carousel/carousel-cta-block'

export type CarouselCtaContentItem = IContentItem<{
	title: Elements.TextElement
	link: Elements.TextElement
	icon: Elements.AssetsElement
}>

export type CarouselCtaBlockContentItem = IContentItem<{
	title: Elements.TextElement
	subtitle: Elements.TextElement
	carouselCtas: Elements.LinkedItemsElement<CarouselCtaContentItem>
}>

export const CarouselCTABlockBlock: Block<CarouselCtaBlockContentItem> = ({
	block,
}) => {
	const {locale = ''} = useRouter()
	return (
		<Carousel
			subtitle={block.elements.subtitle}
			title={block.elements.title}
		>
			{block.elements.carouselCtas.map((cta) => (
				<CarouselCTA
					icon={cta.elements.icon}
					key={cta.elements.title}
					link={addLocalePrefix(cta.elements.link, locale)}
					title={cta.elements.title}
				/>
			))}
		</Carousel>
	)
}
