/* eslint-disable prettier/prettier -- prettier was being really weird */
import Link from 'next/link'
import NextImage from 'next/future/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import {useEffect, useState} from 'react'
import {getContainerClass} from '@/components/BlockMapper/utils'
import {spacing} from '@/_new-code/products/flexible-web-toolkit/styles'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block, TersedAsset} from '@/_new-code/services/kontent-ai/types'
import {useWindowSize} from '@/_new-code/utilities/hooks/use-window-size'

enum ImageWidth {
	Small = 'w-20',
	Medium = 'w-40',
	Large = 'w-80',
	Full = 'w-full',
}

export type ImageContentItem = IContentItem<{
	image: Elements.AssetsElement
	mobileImage: Elements.AssetsElement
	imageWidth: Elements.MultipleChoiceElement
	snippetSpacingSpacing: Elements.MultipleChoiceElement
	snippetContainerResize: Elements.MultipleChoiceElement
	imageLink: Elements.TextElement
	openInNewTab: Elements.MultipleChoiceElement
}>

export const ImageBlock: Block<ImageContentItem> = ({block}) => {
	// The string replace is used to remove the top & bottom margin on mobile (without editing the highly reused spacing const)
	const spacingChoice = (
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not unnecessary, snippetSpacingSpacing may be null
		spacing[
			block.elements.snippetSpacingSpacing[0]
				?.codename as keyof typeof spacing
		] || spacing.default
	).replace('my', 'my-0 sm:my')
	const imageWidth = block.elements.imageWidth[0]?.codename

	const sizes: Record<string, string> = {
		small: ImageWidth.Small,
		medium: ImageWidth.Medium,
		large: ImageWidth.Large,
		full: ImageWidth.Full,
	}

	
	const {isMobile} = useWindowSize()

	const imgSize =
		sizes[imageWidth as keyof typeof imageWidth] || ImageWidth.Medium
	const containerSize = getContainerClass(
		block.elements.snippetContainerResize[0]?.codename ?? '',
		'container-narrow'
	)

	const [image, setImage] = useState<TersedAsset | null>(null)

	useEffect(() => {
		if (isMobile && block.elements.mobileImage[0]) {
			setImage(block.elements.mobileImage[0])
		} else {
			setImage(block.elements.image[0] ?? null)
		}
	}, [block.elements.image, block.elements.mobileImage, isMobile])

	return (
		<div
			className={clsx(
				`flex rounded-md sm:py-4 sm:px-8`,
				containerSize,
				spacingChoice
			)}
			data-kontent-element-codename="card_image"
		>
			{image ? (
				<>
					{block.elements.imageLink ? (
						<Link href={block.elements.imageLink || ''}>
							{/* eslint-disable-next-line jsx-a11y/anchor-is-valid -- necessary for next/link */}
							<a
								className="block h-full w-full"
								rel="noreferrer"
								target={
									block.elements.openInNewTab[0]?.codename ===
									'yes'
										? '_blank'
										: '_self'
								}
							>
								<NextImage
									alt={image.description || ''}
									className={imgSize}
									height={image.height || 0}
									loader={loadImageFromKontentAI}
									src={image.url}
									width={image.width || 0}
								/>
							</a>
						</Link>
					) : (
						<NextImage
							alt={image.description || ''}
							className={imgSize}
							height={image.height || 0}
							loader={loadImageFromKontentAI}
							src={image.url}
							width={image.width || 0}
						/>
					)}
				</>
			) : null}
		</div>
	)
}
