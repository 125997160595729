import Image from 'next/future/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block, Tersed} from '@/_new-code/services/kontent-ai/types'
import type {IconContentItem} from '../icon'
import {RichTextRenderer} from '../../components/rich-text-renderer'
import {Icon} from '../../components/common/icon'

export type SimpleContentBlockWithIconContentItem = IContentItem<{
	title: Elements.TextElement
	description: Elements.RichTextElement
	image: Elements.AssetsElement
	iconHeading: Elements.TextElement
	icons: Elements.LinkedItemsElement<IconContentItem>
	borderColor: Elements.MultipleChoiceElement
}>

const allowedSizes = ['none', 'xs', 'sm', 'md', 'lg'] as const

function isValidIconSize(size: string): size is (typeof allowedSizes)[number] {
	return (allowedSizes as readonly string[]).includes(size)
}

const SimpleContentBlockIcon = ({
	icon,
}: {
	icon: Tersed<IconContentItem>
}): JSX.Element => {
	const iconSize = icon.elements.size[0]?.codename ?? ''

	return (
		<div data-kontent-item-id={icon.system.id}>
			<Icon
				className="mr-2"
				size={isValidIconSize(iconSize) ? iconSize : 'sm'}
			>
				<div className="relative h-full w-full">
					{icon.elements.icon[0] ? (
						<Image
							alt={icon.elements.icon[0].description || ''}
							data-kontent-element-codename="icon"
							fill
							loader={loadImageFromKontentAI}
							src={icon.elements.icon[0].url}
						/>
					) : null}
				</div>
			</Icon>
		</div>
	)
}

export const SimpleContentBlockWithIconBlock: Block<
	SimpleContentBlockWithIconContentItem
> = ({block, ...context}) => {
	return (
		<div
			className={clsx(
				'p-4 md:p-8',
				(block.elements.borderColor[0]?.codename ?? 'none') ===
					'gray' && 'border border-gray-500'
			)}
		>
			<div className="flex">
				<Icon size={block.elements.title ? 'lg' : 'md'}>
					{block.elements.image[0] ? (
						<Image
							alt={block.elements.image[0].description || ''}
							height={block.elements.image[0].height || 107}
							loader={loadImageFromKontentAI}
							src={block.elements.image[0].url}
							width={block.elements.image[0].width || 107}
						/>
					) : (
						<div />
					)}
				</Icon>
				<div
					className={clsx(
						block.elements.image[0] && 'ml-4 md:ml-6',
						'flex-1'
					)}
				>
					<h3 className="text-xl md:text-2xl">
						<div className="mt-4 md:mt-0">
							{block.elements.title}
						</div>
					</h3>
					<div className="mt-4">
						<RichTextRenderer
							className="mb-4"
							element={block.elements.description}
							{...context}
						/>
					</div>
					<div className="flex flex-wrap items-center">
						{block.elements.iconHeading ? (
							<span className="mr-4 font-bold">
								{block.elements.iconHeading}
							</span>
						) : null}
						<div className="my-2 flex">
							{block.elements.icons.map((icon) => (
								<SimpleContentBlockIcon
									icon={icon}
									key={icon.system.id}
								/>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
