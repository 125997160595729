import {WorldMap as OfficeLocation} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {env} from '@/utils/env/client.mjs'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import type {CtaButtonContentItem} from '../cta-button'

interface MarkerButton {
	url: string
	text: string
	isExternal: boolean
}

interface FormattedMarker {
	key: number
	lat: number
	lng: number
	country: string
	title: string
	description: string
	marker_description: string
	primaryContactNumber?: string
	secondaryContactNumber?: string
	emailAddress?: string
	ctaTitle?: string
	primaryCta?: MarkerButton
	secondaryCta?: MarkerButton
}

type MarkerContentItem = IContentItem<{
	title: Elements.TextElement
	description: Elements.TextElement
	markerDescription: Elements.RichTextElement
	country: Elements.TextElement
	ctaTitle: Elements.TextElement
	primaryContactNumber: Elements.TextElement
	secondaryContactNumber: Elements.TextElement
	emailAddress: Elements.TextElement
	lat: Elements.NumberElement
	lng: Elements.NumberElement
	button: Elements.LinkedItemsElement<CtaButtonContentItem>
}>

export type OfficeLocationContentItem = IContentItem<{
	title: Elements.TextElement
	description: Elements.TextElement
	showCountryFilter: Elements.MultipleChoiceElement
	countryDropdownLabel: Elements.TextElement
	countryDropdownPlaceholder: Elements.TextElement
	// There's more than one marker but the name wasn't pluralised
	marker: Elements.LinkedItemsElement<MarkerContentItem>
}>

export const OfficeLocationBlock: Block<OfficeLocationContentItem> = ({
	block,
}) => {
	const {
		marker: markers,
		title,
		description,
		showCountryFilter,
	} = block.elements
	const formattedMarkers = markers.map((marker, index) => {
		const formattedMarker: FormattedMarker = {
			key: index + 1,
			lat: marker.elements.lat ?? 0,
			lng: marker.elements.lng ?? 0,
			country: marker.elements.country,
			title: marker.elements.title,
			description: marker.elements.description,
			marker_description: marker.elements.markerDescription.value,
		}

		if (marker.elements.primaryContactNumber) {
			formattedMarker.primaryContactNumber =
				marker.elements.primaryContactNumber
		}

		if (marker.elements.secondaryContactNumber) {
			formattedMarker.secondaryContactNumber =
				marker.elements.secondaryContactNumber
		}

		if (marker.elements.emailAddress) {
			formattedMarker.emailAddress = marker.elements.emailAddress
		}

		if (marker.elements.ctaTitle) {
			formattedMarker.ctaTitle = marker.elements.ctaTitle
		}

		if (marker.elements.button.length) {
			marker.elements.button.forEach((button, i) => {
				const property = i === 0 ? 'primaryCta' : 'secondaryCta'
				formattedMarker[property] = {
					url: button.elements.url,
					text: button.elements.text,
					isExternal:
						button.elements.openLinkInNewTab[0]?.codename === 'yes',
				}
			})
		}

		return formattedMarker
	})

	const officeLocationsProps = {
		title,
		description,
		markers: formattedMarkers,
		showCountryFilter: showCountryFilter[0]?.codename === 'yes',
		apiKey: env.NEXT_PUBLIC_MAP_API_KEY,
	}

	return <OfficeLocation {...officeLocationsProps} />
}
