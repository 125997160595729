import {useEffect, useState} from 'react'
import Image from 'next/future/image'
import Link from 'next/link'
import {clsx} from 'clsx'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {Lister} from '@/_new-code/products/flexible-web-toolkit/blocks/card-lister'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block, Tersed} from '@/_new-code/services/kontent-ai/types'
import {useWindowSize} from '@/_new-code/utilities/hooks/use-window-size'

type ClickableImageContentItem = IContentItem<{
	image: Elements.AssetsElement
	url: Elements.TextElement
	openInNewTab: Elements.MultipleChoiceElement
}>

export type ScrollingImagesContentItem = IContentItem<{
	images: Elements.LinkedItemsElement<ClickableImageContentItem>
	autoplay: Elements.MultipleChoiceElement
	autoplaySpeed: Elements.NumberElement
	columns: Elements.NumberElement
	mobileColumns: Elements.NumberElement
	effects: Elements.MultipleChoiceElement
	effectsOnMobile: Elements.MultipleChoiceElement
	snippetContainerResize: Elements.MultipleChoiceElement
	removeHyperlink: Elements.MultipleChoiceElement
}>

export const ScrollingImagesBlock: Block<ScrollingImagesContentItem> = ({
	block,
}) => {
	const {isMobile, width} = useWindowSize()

	const effects = {
		grayscale: 'hover:grayscale',
		grayscale_inverted: 'grayscale hover:grayscale-0',
	}

	const effectClasses = block.elements.effects
		.map((choice) => effects[choice.codename as keyof typeof effects])
		.filter(Boolean)
		.join(' ')

	const [effectCls, setEffectCls] = useState(effectClasses)

	useEffect(() => {
		// If we're on desktop, always use effect classes
		if (!isMobile) {
			setEffectCls(effectClasses)
			return
		}

		// If we're on mobile, and effects should apply, set them
		if (block.elements.effectsOnMobile[0]?.codename === 'yes') {
			setEffectCls(effectClasses)
			return
		}

		// Else, they should not be set
		setEffectCls('')
	}, [block.elements.effectsOnMobile, effectClasses, isMobile])

	return (
		<Lister
			autoplay={block.elements.autoplay[0]?.codename === 'yes'}
			autoplaySpeed={block.elements.autoplaySpeed ?? 2000}
			columns={
				(isMobile
					? block.elements.mobileColumns
					: block.elements.columns) ?? 5
			}
			showAsSlider
			snippetContainerResize={
				block.elements.snippetContainerResize[0]?.codename
			}
		>
			{/* If autoplaying, repeat the images enough time to make scrolling seamless */}
			{new Array(
				block.elements.autoplay[0]?.codename === 'yes'
					? Math.floor(Math.max((width ?? 1) / 320, 1))
					: 1
			)
				.fill(block.elements.images)
				.flat()
				.map(
					({
						elements: {image, openInNewTab, url},
					}: Tersed<ClickableImageContentItem>) => {
						const ImageContent = (
							<div
								className="flex h-full"
								data-kontent-element-codename="images"
							>
								{image[0] ? (
									<Image
										alt={image[0]?.description ?? ''}
										className={clsx(
											'my-auto w-60 transition-all',
											effectCls
										)}
										height={image[0]?.height ?? 100}
										loader={loadImageFromKontentAI}
										priority
										src={image[0]?.url}
										style={{
											filter: 'var(--tw-grayscale)',
										}}
										width={400}
									/>
								) : null}
							</div>
						)

						return block.elements.removeHyperlink[0]?.codename ===
							'yes' ? (
							<div key={image[0]?.url}>{ImageContent}</div>
						) : (
							<Link href={url} key={image[0]?.url}>
								{/* eslint-disable-next-line jsx-a11y/anchor-is-valid -- Necessary for next/link */}
								<a
									target={
										openInNewTab[0]?.codename === 'yes'
											? '_blank'
											: '_self'
									}
								>
									{ImageContent}
								</a>
							</Link>
						)
					}
				)}
		</Lister>
	)
}
