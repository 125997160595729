import {MapLegends} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'

type LegendContentItem = IContentItem<{
	legendName: Elements.TextElement
	color: Elements.TextElement
	lowerBound: Elements.NumberElement
	upperBound: Elements.NumberElement
}>

export type LegendsContentItem = IContentItem<{
	legend: Elements.LinkedItemsElement<LegendContentItem>
	subTitle: Elements.TextElement
}>

export const LegendsBlock: Block<LegendsContentItem> = ({
	block: {
		elements: {legend, subTitle},
	},
}) => {
	// Map to format needed for component
	const legends = legend.map(
		({elements: {color, legendName, lowerBound, upperBound}}) => ({
			color,
			legend_name: legendName,
			lower_bound: lowerBound,
			upper_bound: upperBound,
		})
	)

	return (
		<div>
			<MapLegends legends={legends} subTitle={subTitle} />
		</div>
	)
}
