export const defaultMargins = 'my-12 md:my-16'

export const spacing = {
	default: 'my-6 md:my-8',
	top: 'mt-6 md:mt-8',
	bottom: 'mb-6 md:mb-8',
	none: 'my-0 py-0',
} as const

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- implicit is fine
export function getValidSpacingOrNone(choice?: string) {
	if (!choice) {
		return spacing.none
	}

	if (!Object.keys(spacing).includes(choice)) {
		return spacing.none
	}

	// We know the key exists so we can cast it here
	return spacing[choice as keyof typeof spacing]
}

export const spacingPadding = {
	default: 'py-12 md:py-16',
	top: 'pt-12 md:pt-16',
	bottom: 'pb-12 md:pb-16',
	none: 'my-0 py-0',
} as const
