import {
	RebateHistory as RebateHistoryComponent,
	Locales,
} from '@elanco/rebate-components'
import {getCookie} from 'cookies-next'
import {useQuery} from 'react-query'
import {useRouter} from 'next/router'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useAuth} from '@/_new-code/products/auth/auth-provider'
import {env} from '@/utils/env/client.mjs'
import {populateToken} from '@/fetchers/populateToken'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {LoadingSpinner} from '@/_new-code/products/flexible-web-toolkit/components/loading-spinner'

export type RebateHistoryContentItem = IContentItem<{
	notLoggedInText: Elements.TextElement
	noCrmIdText: Elements.TextElement
	enableLearnMore: Elements.MultipleChoiceElement
	learnMoreUrl: Elements.TextElement
	learnMoreImage: Elements.AssetsElement
	previousQuaterInText: Elements.TextElement // typo is intentional as code is already in place
}>

export const RebateHistoryBlock: Block<RebateHistoryContentItem> = ({
	block,
	globalConfig,
}) => {
	const {locale = 'us'} = useRouter()

	const {data, isLoading} = useQuery({
		queryFn: () => populateToken(locale),
		staleTime: Infinity,
		refetchOnWindowFocus: false,
	})
	const authState = useAuth()
	const {authenticated, userDetails} = authState
	// Extract cdc_user cookie and ensure it's a string and not null.
	const userCookie = getCookie('cdc_user')?.toString() ?? ''
	// The cookie is stored as a base64 encoded string.  Decode it.
	const strCookie = Buffer.from(userCookie, 'base64').toString()
	// The shape of the cookie is defined and populated in JWTService (triggered by the populateToken function above).
	// The token is unpacked from that jwtresponse and used in the rebates component for authentication.
	const token =
		strCookie === ''
			? '{}'
			: (JSON.parse(strCookie) as {token?: string}).token

	if (!authenticated) {
		return (
			<div className="m-8">
				{block.elements.notLoggedInText ||
					'Please log in to view Rebate History'}
			</div>
		)
	}

	const config = {
		rebateHistoryKontentProps: {
			learnMore: {
				imageSrc: block.elements.learnMoreImage[0]?.url,
				link: block.elements.learnMoreUrl,
				enable: block.elements.enableLearnMore[0]?.codename === 'yes',
			},
			previousQuarterText: block.elements.previousQuaterInText,
		},
	}

	if (isLoading || userDetails.id === null) {
		return (
			<div className="mx-auto w-full text-center">
				<LoadingSpinner theme="blue" />
			</div>
		)
	}

	if (!userDetails.crmId) {
		return (
			<div className="m-8">
				{block.elements.noCrmIdText ||
					'Your account is not configured for the Rebate History page.	Please contact your Rep for more information'}
			</div>
		)
	}

	const Locale = locale === 'en_gb' ? Locales.UK : Locales.US

	const userAccessToken = token ?? data?.cdcToken

	return (
		<div>
			{userAccessToken ? (
				<RebateHistoryComponent
					apiUrl={env.NEXT_PUBLIC_REBATES_API_URL ?? ''}
					cdcApiKey={globalConfig.elements.cdcKey}
					clinicId={userDetails.crmId}
					config={config}
					earliestPaymentHistoryYear={undefined}
					locale={Locale}
					userAccessToken={userAccessToken}
				/>
			) : null}
		</div>
	)
}
