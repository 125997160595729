import {Header} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'

export type HeaderBlockWithCopyContentItem = IContentItem<{
	heading: Elements.TextElement
	subheading: Elements.TextElement
	image: Elements.AssetsElement
}>

export const HeaderBlockWithCopyBlock: Block<
	HeaderBlockWithCopyContentItem
> = ({
	block: {
		elements: {
			heading,
			subheading,
			image: [image],
		},
	},
}) => {
	return (
		<Header noTheme>
			<h1 data-kontent-element-codename="heading">{heading}</h1>
			<p className="pt-6" data-kontent-element-codename="subheading">
				{subheading}
			</p>
			{image ? (
				<Header.Image alt={image.description} src={image.url} />
			) : null}
		</Header>
	)
}
