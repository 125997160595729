import {ColumnCardsLister as CLColumnCardLister} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {spacingPadding} from '@/_new-code/products/flexible-web-toolkit/styles'
import type {ArticleListerBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/article-lister-block'
import type {Block} from '@/_new-code/services/kontent-ai/types'

export type ColumnCardListerContentItem = IContentItem<{
	title: Elements.TextElement
	articleBlocks: Elements.LinkedItemsElement<ArticleListerBlockContentItem> // TODO: add enhanced card
	snippetSpacingSpacing: Elements.MultipleChoiceElement
}>

export const ColumnCardListerBlock: Block<ColumnCardListerContentItem> = ({
	block,
	BlockMapper,
	...context
}) => {
	const componentSpacing = (() => {
		switch (block.elements.snippetSpacingSpacing[0]?.codename) {
			case 'default': {
				return spacingPadding.default
			}
			case 'top': {
				return spacingPadding.top
			}
			case 'bottom': {
				return spacingPadding.bottom
			}
			case 'none': {
				return spacingPadding.none
			}
			default: {
				return spacingPadding.none
			}
		}
	})()

	return (
		<CLColumnCardLister
			articleBlockClass="article-block !p-0 my-4"
			className={componentSpacing}
			title={block.elements.title}
		>
			{block.elements.articleBlocks.map((articleBlock) => {
				return (
					<BlockMapper
						blocks={[articleBlock]}
						key={articleBlock.system.id}
						{...context}
					/>
				)
			})}
		</CLColumnCardLister>
	)
}
