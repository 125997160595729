/* eslint-disable @typescript-eslint/explicit-function-return-type -- TypeScript can infer the type */
import type {Tersed} from '@/_new-code/services/kontent-ai/types'
import type {WistiaContentItem} from './wistia'
import type {VideoBlockContentItem} from './video-block'

const VIDEO_TYPES = {
	YOUTUBE: 'youtube',
	WISTIA: 'wistia',
} as const

export const getVideoDetails = (
	video: Tersed<WistiaContentItem | VideoBlockContentItem>
) => {
	if (video.system.type === 'video_block') {
		const youTubeVideo = video as unknown as VideoBlockContentItem
		return {
			videoId: youTubeVideo.elements.videoId,
			type: VIDEO_TYPES.YOUTUBE,
		} as const
	}
	if (video.system.type === 'wistia') {
		const wistiaVideo = video as unknown as WistiaContentItem
		return {
			videoId: wistiaVideo.elements.id,
			type: VIDEO_TYPES.WISTIA,
		} as const
	}

	throw new RangeError(
		`Received an invalid video type, got ${video.system.type}, expected either 'video_block' or 'wistia'`
	)
}
