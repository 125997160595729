import http from '@/utils/axios'

export interface DeletePet {
	petId: string
}

export const deletePetProfile = async (
	{petId}: DeletePet,
	locale: string
): Promise<void> => {
	await http(`/api/pets/${petId}?locale=${locale}`, {
		method: 'DELETE',
	})
}
