import type {Dispatch, SetStateAction} from 'react'
import {useEffect, useState} from 'react'
import type {SharedQuestionProps} from '@/_new-code/products/campaign-forms/cdc-screen-set-wrapper/models'
import {REQUIRED} from '@/_new-code/products/campaign-forms/cdc-screen-set-wrapper/model-as-code/common-types'

interface CampaignQuestionHookResults {
	required: boolean
	questionKontentItemId: string
	displayError: boolean
	errorClass: string
	setSelectedItems: Dispatch<SetStateAction<string[]>>
}

export function useCampaignQuestion({
	errorsCallback,
	question,
	selectedOptionsCallback,
	validationEvent,
}: SharedQuestionProps): CampaignQuestionHookResults {
	const [displayError, setDisplayError] = useState(false)
	const [selectedItems, setSelectedItems] = useState<string[]>([])
	const [errorClass, setErrorClass] = useState('')
	const required =
		question.elements.requiredOrOptional[0]?.codename === REQUIRED

	useEffect(() => {
		errorsCallback(false) // no errors
	}, [errorsCallback])

	useEffect(() => {
		if (
			validationEvent.validateNow &&
			required &&
			selectedItems.length === 0
		) {
			setDisplayError(true)
			setErrorClass('gigya-error')
		} else {
			setDisplayError(false)
			setErrorClass('')
		}
	}, [validationEvent, required, selectedItems])

	useEffect(() => {
		if (selectedItems.length === 0) {
			selectedOptionsCallback([''])
		} else {
			selectedOptionsCallback(selectedItems)
		}
	}, [selectedItems, selectedOptionsCallback])

	useEffect(() => {
		const hasErrors = required && selectedItems.length === 0
		errorsCallback(hasErrors)
	}, [errorsCallback, required, selectedItems])

	return {
		required,
		questionKontentItemId: question.system.id,
		displayError,
		errorClass,
		setSelectedItems,
	}
}
