import http from '@/utils/axios'

interface Token {
	cdcToken: string
}

export const populateToken = async (locale: string): Promise<Token> => {
	const endpoint = `/api/getToken?locale=${locale}`
	const {data} = await http<Token>({url: endpoint})

	return data
}
