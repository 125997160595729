import {QueryFunctionContext} from 'react-query'
import {env} from '@/utils/env/client.mjs'
import http from '@/utils/axios'

interface IFaqCodenamesFromCognitiveSearchParameters {
	searchEndpoint: string
	searchQuery: string
}

type TFaqCodenamesFromCognitiveSearchQueryKey = [
	string,
	IFaqCodenamesFromCognitiveSearchParameters,
]

interface IFaqCodenamesFromCognitiveSearchApiOutput {
	value: {id: string}[]
}

export const fetchFaqCodenamesFromCognitiveSearch = async (
	context: QueryFunctionContext<TFaqCodenamesFromCognitiveSearchQueryKey>
): Promise<string[]> => {
	const [, params] = context.queryKey
	const {searchEndpoint, searchQuery} = params
	const endpoint = searchEndpoint + searchQuery
	const {data} = await http<IFaqCodenamesFromCognitiveSearchApiOutput>(
		endpoint,
		{
			headers: {
				'api-key': env.NEXT_PUBLIC_FAQ_COGNITIVE_SEARCH_API_KEY ?? '',
			},
		}
	)
	return data.value.map((faq) => faq.id)
}
