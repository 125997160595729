import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import type {TileWithIconContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/tile-with-icon'
import {TileWithIconBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/tile-with-icon'

export type TileContainerContentItem = IContentItem<{
	heading: Elements.TextElement
	tiles: Elements.LinkedItemsElement<TileWithIconContentItem>
}>

export const TileContainerBlock: Block<TileContainerContentItem> = ({
	block,
	...context
}) => {
	return (
		<section className="container-wide my-6">
			<div className="flex flex-col">
				<h2 className="mb-4 text-left text-2xl font-semibold text-midnight-blue">
					{block.elements.heading}
				</h2>
				<ul className="grid w-full grid-cols-2 gap-4 lg:grid-cols-3">
					{block.elements.tiles.map((tile) => (
						<TileWithIconBlock
							block={tile}
							key={block.system.id}
							{...context}
						/>
					))}
				</ul>
			</div>
		</section>
	)
}
