import {createContext, useState, PropsWithChildren} from 'react'
import {IGlobalState, TGlobalState} from '@/models/GlobalState'

type GlobalClientStateProps = PropsWithChildren<{
	initialGlobalState?: IGlobalState
}>

export const GlobalContext = createContext<TGlobalState | null>(null)

const GlobalClientState = ({
	children,
	initialGlobalState,
}: GlobalClientStateProps): JSX.Element => {
	const [globalState, setGlobalState] = useState<IGlobalState | null>(
		initialGlobalState ?? null
	)

	return (
		<GlobalContext.Provider
			// eslint-disable-next-line react/jsx-no-constructed-context-values
			value={[globalState, setGlobalState] as TGlobalState}
		>
			{children}
		</GlobalContext.Provider>
	)
}

export default GlobalClientState
