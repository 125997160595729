import {
	ConfirmEmailField,
	EmailField,
	ConfirmEmailLabel,
	ConfirmEmailErrorLabel,
	setErrorLabel,
} from './form-elements'

function setEmailError(message: string): void {
	setErrorLabel(ConfirmEmailErrorLabel(), message)
}

function clearEmailError(): void {
	setErrorLabel(ConfirmEmailErrorLabel(), '')
}

class EmailConfirmation {
	private confirmationDisabled = false

	constructor() {
		const found =
			Boolean(ConfirmEmailField()) && Boolean(ConfirmEmailLabel())
		this.confirmationDisabled = !found
	}

	confirmEmailFieldsMatch(): boolean {
		if (this.confirmationDisabled) return true

		const emailValue = EmailField()?.value ?? ''
		const confirmEmailValue = ConfirmEmailField()?.value
		if (emailValue.length === 0) {
			// CDCScreenSet automatically sets the error in this case
			return false
		}
		if (emailValue !== confirmEmailValue) {
			setEmailError('Email addresses must match.')
			return false
		}
		if (confirmEmailValue.length === 0) {
			setEmailError('Please confirm your email')
			return false
		}
		clearEmailError()
		return true
	}

	setupEmailConfirmation(): void {
		if (this.confirmationDisabled) return

		const email = EmailField()
		const confirmEmail = ConfirmEmailField()
		const confirmEmailLabel = ConfirmEmailLabel()
		const doneTypingInterval = 1000
		let typingTimer: NodeJS.Timeout

		const asterisk = document.createElement('label')
		asterisk.setAttribute('class', 'gigya-required-display')
		asterisk.innerText = '*'
		confirmEmailLabel?.after(asterisk)
		const checkEmail = (): void => {
			clearTimeout(typingTimer)
			typingTimer = setTimeout(
				() => this.confirmEmailFieldsMatch(),
				doneTypingInterval
			)
		}
		email?.addEventListener('input', () => {
			checkEmail()
		})
		confirmEmail?.addEventListener('input', () => {
			checkEmail()
		})
	}
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class -- was built this way, not changing it right now
class EmailConfirmationFactory {
	static instance: EmailConfirmation

	constructor() {
		throw new Error('Use EmailConfirmationFactory.getInstance()')
	}

	static getInstance(): EmailConfirmation {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Not true
		if (!EmailConfirmationFactory.instance) {
			EmailConfirmationFactory.instance = new EmailConfirmation()
		}

		return EmailConfirmationFactory.instance
	}
}

function setupEmailConfirmation(): void {
	EmailConfirmationFactory.getInstance().setupEmailConfirmation()
}

function confirmEmailFieldsMatch(): boolean {
	return EmailConfirmationFactory.getInstance().confirmEmailFieldsMatch()
}

export {setupEmailConfirmation, confirmEmailFieldsMatch}
