import type {Tersed} from '@/_new-code/services/kontent-ai/types'
import type {
	SharedQuestionProps,
	MultipleSelectionContentItem,
} from '@/_new-code/products/campaign-forms/cdc-screen-set-wrapper/models'
import {insertAsterisk} from '../../../../../utils/helpers'
import {useCampaignQuestion} from './hooks'

interface MultiSelectCheckboxesProps extends SharedQuestionProps {
	question: Tersed<MultipleSelectionContentItem>
}

export const MultiSelectCheckboxes = ({
	question,
	selectedOptionsCallback,
	injectedElementsCallback,
	errorsCallback,
	validationEvent,
}: MultiSelectCheckboxesProps): JSX.Element => {
	const {required, questionKontentItemId, displayError, setSelectedItems} =
		useCampaignQuestion({
			question,
			selectedOptionsCallback,
			injectedElementsCallback,
			errorsCallback,
			validationEvent,
		})

	const spanId = `span-${questionKontentItemId}`
	injectedElementsCallback(spanId)

	return (
		<div
			className="gigya-composite-control gigya-composite-control-multi-choice opt-group"
			id={questionKontentItemId}
		>
			<span className="gigya-label-text" id={spanId}>
				<div
					dangerouslySetInnerHTML={{
						__html: insertAsterisk(
							question.elements.questionText.value,
							required
						),
					}}
				/>
			</span>
			{required ? (
				// eslint-disable-next-line jsx-a11y/label-has-associated-control -- necessary
				<label aria-hidden="true" className="hidden">
					*
				</label>
			) : null}
			{question.elements.selections.map((selection) => {
				const checkboxId = `checkbox-${selection.system.id}`
				const labelId = `label-${selection.system.id}`
				injectedElementsCallback(checkboxId)
				injectedElementsCallback(labelId)

				return (
					<div
						className="gigya-multi-choice-item"
						key={selection.system.id}
					>
						<input
							className="gigya-input-checkbox fd-checkbox"
							id={checkboxId}
							name={checkboxId}
							onChange={(evt) => {
								const {checked} = evt.target
								if (checked) {
									setSelectedItems((options: string[]) => [
										...options,
										evt.target.value,
									])
								} else {
									setSelectedItems((options: string[]) =>
										options.filter(
											(s) => s !== evt.target.value
										)
									)
								}
							}}
							type="checkbox"
							value={selection.elements.selectionValue}
						/>
						<label htmlFor={checkboxId} id={labelId}>
							{selection.elements.displayValue}
						</label>
					</div>
				)
			})}
			{displayError ? (
				<span className="gigya-error-msg gigya-error-msg-active">
					This field is required
				</span>
			) : null}
		</div>
	)
}
