import dynamic from 'next/dynamic'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import type {CouponIframeContentItem} from '@/_new-code/products/where-to-buy/revtrax/coupon-iframe'

const CouponIframeComponent = dynamic(
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error -- Necessary to fix build
	// @ts-ignore -- Necessary to fix build
	() => import('./coupon-iframe').then((mod) => mod.CouponIframeComponent),
	{
		ssr: false,
	}
)

export const CouponIframeBlock: Block<CouponIframeContentItem> = (props) => {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error -- Necessary to fix build
	// @ts-ignore -- Necessary to fix build
	return <CouponIframeComponent {...props} />
}
