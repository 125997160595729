import dynamic from 'next/dynamic'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import type {CartComponentProps} from './click-to-cart-block'

const CartComponent = dynamic<CartComponentProps>(
	() => import('./click-to-cart-block').then((mod) => mod.ClickToCartBlock),
	{
		ssr: false,
	}
)

type ClickToCartPetWeightOptionContentItem = IContentItem<{
	petWeight: Elements.TextElement
	masterProductId: Elements.TextElement
}>

export type ClickToCartContentItem = IContentItem<{
	dataSourceId: Elements.TextElement
	defaultMasterProductId: Elements.TextElement
	petWeightOptions: Elements.LinkedItemsElement<ClickToCartPetWeightOptionContentItem>
	dataSourceType: Elements.TextElement
	clientCode: Elements.TextElement
	clientBrand: Elements.TextElement
}>

export const ClickToCartBlock: Block<ClickToCartContentItem> = ({
	block: {
		elements: {
			clientCode,
			clientBrand,
			defaultMasterProductId,
			dataSourceId,
			dataSourceType,
			petWeightOptions,
		},
	},
}) => {
	const BASE_SMART_URL =
		'https://s3-us-west-2.amazonaws.com/static.smartcommerce.co/assets/client/'

	return (
		<CartComponent
			brandJsUrl={`${BASE_SMART_URL}${clientCode}/js/smartcart_${clientBrand}_prod.js`}
			cssUrl={`${BASE_SMART_URL}${clientCode}/css/smartcart_${clientBrand}_prod.css`}
			defaultProductId={defaultMasterProductId}
			sourceId={dataSourceId}
			sourceType={dataSourceType}
			weightOptions={petWeightOptions.map((option) => ({
				master_product_id: option.elements.masterProductId,
				pet_weight: option.elements.petWeight,
			}))}
		/>
	)
}
