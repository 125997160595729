import {useState} from 'react'
import {Lightbox} from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import {Image} from '@elanco/component-library-v2'
import {clsx} from 'clsx'

interface LargeVisualBlockProps {
	image: {
		src: string | undefined
		alt: string
	}
	largeImageUrl: string
	className?: string
	shouldDisplayInteractiveClasses?: boolean
	openFullscreenImageAriaLabel?: string
}

export const LargeVisualBlock = ({
	largeImageUrl,
	image,
	className = '',
	shouldDisplayInteractiveClasses = true,
	openFullscreenImageAriaLabel = 'Open image in popup',
}: LargeVisualBlockProps): JSX.Element => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<div className={clsx(className, 'leading-[0]')}>
			<div className="relative inline-block w-full">
				<Image
					alt={image.alt}
					className={clsx(
						'w-full',
						shouldDisplayInteractiveClasses &&
							'rounded-lg shadow-secondary',
						className
					)}
					height={300}
					src={image.src}
					width={450}
				/>
				<button
					aria-label={openFullscreenImageAriaLabel}
					className="absolute right-2.5 top-2.5 z-10 h-8 w-8 rounded-full bg-primary hover:bg-primary-dark focus:bg-primary-dark"
					onClick={() => {
						setIsOpen(true)
					}}
					title={openFullscreenImageAriaLabel}
					type="button"
				>
					<div className="flex items-center justify-center">
						<svg
							aria-hidden="true"
							focusable="false"
							height="15.07"
							viewBox="0 0 14.92 15.07"
							width="14.92"
							xmlns="http://www.w3.org/2000/svg"
						>
							<title>icon-expand</title>
							<path
								className="fill-white"
								d="M7.18,9.43,4.1,12.51l2.56,2.56H0V8.41L2.56,11,5.64,7.89Z"
								transform="translate(0)"
							/>
							<path
								className="fill-white"
								d="M14.92,0V6.67L12.36,4.1,9.28,7.18,7.74,5.64l3.08-3.08L8.25,0Z"
								transform="translate(0)"
							/>
						</svg>
					</div>
				</button>
			</div>
			{isOpen ? (
				<Lightbox
					carousel={{
						finite: true,
					}}
					close={() => {
						setIsOpen(false)
					}}
					open={isOpen}
					slides={[{src: largeImageUrl}]}
				/>
			) : null}
		</div>
	)
}
