import {Reminder} from '@/fetchers/fetchReminders'

export const groupReminders = (reminders: Reminder[]) => {
	const groupedMedicationsDictionary: {[key: string]: Reminder} = {}
	reminders.forEach((reminder) => {
		groupedMedicationsDictionary[
			`${reminder.pet?.id}-${reminder.marketingBrand?.id}`
		] = reminder
	})
	return Object.values(groupedMedicationsDictionary)
}

export const isProductSeresto = (productId: string) =>
	productId === 'SER_Dog_Product' || productId === 'SER_Cat_Product'

export const isProductAdvantageXD = (productId: string) =>
	productId === 'AXD_Cat_Product'

export const isProductGalliprant = (productId: string) =>
	productId === 'GAL_Dog_Product'

/**
 * @public
 * Disable because eslint error is a bug that has since been fixed in newer versions of the TSLint-to-ESTLint plugin
 */
// eslint-disable-next-line no-shadow, @typescript-eslint/naming-convention
export enum PRODUCTS_REMINDER_FREQUENCY_MONTHS {
	SER_Dog_Product = 8,
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values -- honestly who even knows how this code works at this point
	SER_Cat_Product = 8,
	AXD_Cat_Product = 2,
}
export const DEFAULT_PRODUCT_REMINDER_FREQUENCY = 1
