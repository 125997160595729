import {
	Icon as CIcon,
	Card as ClBrandTableCard,
} from '@elanco/component-library-v2'
import Image from 'next/future/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import {customBrandChoices, customBrands} from '@/components/BlockMapper/utils'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {IconWithTextContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/icon-with-text'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'

export type BrandCardContentItem = IContentItem<{
	iconsWithText: Elements.LinkedItemsElement<IconWithTextContentItem>
}>

export const BrandTableCardBlock: Block<BrandCardContentItem> = ({
	block,
	...context
}) => {
	return (
		<ClBrandTableCard
			className="h-full py-6"
			data-kontent-element-codename="brand_card"
		>
			{block.elements.iconsWithText.map((icon) => (
				<div
					className="flex items-start pb-5 text-black last:pb-0"
					key={icon.system.id}
					role="listitem"
				>
					<CIcon
						brand={
							icon.elements.brand[0] &&
							customBrandChoices.includes(
								icon.elements.brand[0].codename
							)
								? 'none'
								: icon.elements.brand[0]?.codename || 'none'
						}
						iconCustomClasses={clsx(
							'p-1',
							icon.elements.brand[0] &&
								customBrandChoices.includes(
									icon.elements.brand[0]?.codename
								)
								? customBrands[icon.elements.brand[0].codename]
										?.border
								: ''
						)}
						noBorder
						size={icon.elements.iconSize[0]?.codename || 'xs'}
					>
						<div className="relative h-full w-full object-contain">
							{icon.elements.icon[0] ? (
								<Image
									alt={
										icon.elements.icon[0].description ?? ''
									}
									data-kontent-element-codename="icon"
									fill
									loader={loadImageFromKontentAI}
									src={icon.elements.icon[0].url}
								/>
							) : null}
						</div>
					</CIcon>

					<div className="content ml-4">
						<strong>{icon.elements.title}</strong>
						<RichTextRenderer
							element={icon.elements.description}
							{...context}
						/>
					</div>
				</div>
			))}
		</ClBrandTableCard>
	)
}
