export const blowfly = {
	formSchema: {
		default: {
			type: 'object',
			required: ['strikeDate', 'casePostcode', 'checkbox'],
			properties: {
				strikeDate: {
					type: 'string',
					title: 'Your blowfly strike date',
					format: 'date',
				},
				casePostcode: {
					type: 'string',
					title: 'Postcode of reported case',
					pattern:
						'^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$',
				},
				country: {
					type: 'string',
					default: '',
				},
				practiceName: {
					type: 'string',
					enum: ['Prescriber', 'Farmer', 'Other'],
					title: 'Are you a prescriber or farmer?',
				},
				checkbox: {
					type: 'boolean',
					title: 'Terms and conditions?',
				},
			},
			dependencies: {
				practiceName: {
					oneOf: [
						{
							properties: {
								practiceName: {
									enum: ['Other'],
								},
								other: {
									type: 'string',
									title: 'Other',
								},
							},
						},
					],
				},
			},
		},
		en_gb: {
			type: 'object',
			required: ['strikeDate', 'casePostcode'],
			properties: {
				strikeDate: {
					type: 'string',
					title: 'Your Blowfly Strike Date',
					format: 'date',
				},
				casePostcode: {
					type: 'string',
					title: 'Postcode of Reported Case',
					pattern:
						'^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$',
				},
				practiceName: {
					type: 'string',
					enum: ['Prescriber', 'Farmer', 'Other'],
					title: 'Are you a Prescriber or Farmer?',
				},
				country: {
					type: 'string',
					default: 'GB',
				},
			},
			dependencies: {
				practiceName: {
					oneOf: [
						{
							properties: {
								practiceName: {
									enum: ['Other'],
								},
								other: {
									type: 'string',
									title: 'Other',
								},
							},
						},
					],
				},
			},
		},
	},
	uiSchema: {
		'ui:rootFieldId': 'blowfly',
		'ui:order': [
			'strikeDate',
			'casePostcode',
			'practiceName',
			'other',
			'country',
		],
		country: {
			'ui:widget': 'hidden',
		},
		casePostcode: {
			'ui:help':
				'N.B. Your postcode will not be displayed - the strike will be reported to county level.',
		},
		description:
			'<span class="content">I agree for Elanco to use the data I submit for the purpose of populating the Blowfly Strike map, and understand that this data will be used in accordance with Elanco’s <a href="https://www.elancostatements.com/uk/en/privacy/elanco-uk">Privacy Policy</a> and <a href="https://www.elancostatements.com/uk/en/terms-of-use/elanco-uk">Terms and Conditions</a>. Submissions are sent for approval by our team.</span>',
	},
}
