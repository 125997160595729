import {useState} from 'react'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import Modal from '@/components/Modal'
import {
	ReusableCTAButtonComponent,
	type CtaButtonContentItem,
} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'

export type ModuleRebatesHeaderContentContentItem = IContentItem<{
	title: Elements.TextElement
	heading: Elements.TextElement
	subHeading: Elements.TextElement
	modalHeading: Elements.TextElement
	modalSubheading: Elements.TextElement
	modalStepOneTitle: Elements.TextElement
	modalStepOneDescription: Elements.RichTextElement
	modalStepTwoTitle: Elements.TextElement
	modalStepTwoDescription: Elements.RichTextElement
	modalStepThreeTitle: Elements.TextElement
	modalStepThreeDescription: Elements.RichTextElement
	ctaButton: Elements.LinkedItemsElement<CtaButtonContentItem>
}>

export const ModuleRebatesHeaderContentBlock: Block<
	ModuleRebatesHeaderContentContentItem
> = ({
	block: {
		elements: {
			ctaButton,
			heading,
			modalHeading,
			modalStepOneDescription,
			modalStepOneTitle,
			modalStepThreeDescription,
			modalStepThreeTitle,
			modalStepTwoDescription,
			modalStepTwoTitle,
			modalSubheading,
			subHeading,
			title,
		},
	},
	...context
}) => {
	const [showModal, setShowModal] = useState<boolean>(false)
	return (
		<div className="my-8 md:my-16 md:pr-8">
			<div>
				<h1 className="text-xl font-semibold uppercase text-pet-green">
					{title}
				</h1>
			</div>
			<div className="mb-2 mt-2">
				<h2 className="text-4xl font-semibold">{heading}</h2>
			</div>
			<div className="mb-4 mt-2">
				<p>{subHeading}</p>
			</div>
			{ctaButton.map((button) => (
				<ReusableCTAButtonComponent
					block={button}
					className="px-6 py-1 text-sm"
					key={button.system.id}
					onClick={(e) => {
						e.preventDefault()
						setShowModal(true)
					}}
					variant={
						button.elements.variant[0]?.codename ||
						'inverted_pet_primary_green'
					}
					{...context}
				/>
			))}
			{showModal ? (
				<Modal
					onClose={() => {
						setShowModal(false)
					}}
					size="xl"
				>
					<span className="italic">{modalHeading}</span>
					<h2 className="font-semibold">{modalSubheading}</h2>
					<div className="my-8 flex flex-col border border-midnight md:flex-row">
						<div className="flex flex-1 flex-col items-center bg-[#F6FBF4] p-4">
							<span className="mb-2 text-5xl">1</span>
							<span className="mb-4 text-sm font-semibold uppercase">
								{modalStepOneTitle}
							</span>
							<RichTextRenderer
								className="content text-sm"
								element={modalStepOneDescription}
								{...context}
							/>
						</div>
						<div className="flex flex-1 flex-col items-center bg-[#E2F2DD] p-4">
							<span className="mb-2 text-5xl">2</span>
							<span className="mb-4 text-sm font-semibold uppercase">
								{modalStepTwoTitle}
							</span>
							<RichTextRenderer
								className="content text-sm"
								element={modalStepTwoDescription}
								{...context}
							/>
						</div>
						<div className="flex flex-1 flex-col items-center bg-[#CFEAC6] p-4">
							<span className="mb-2 text-5xl">3</span>
							<span className="mb-4 text-sm font-semibold uppercase">
								{modalStepThreeTitle}
							</span>
							<RichTextRenderer
								className="content text-sm"
								element={modalStepThreeDescription}
								{...context}
							/>
						</div>
					</div>
				</Modal>
			) : null}
		</div>
	)
}
