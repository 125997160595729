import {useState, useEffect} from 'react'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import type {ExtendedBlock} from '@/_new-code/services/kontent-ai/types'
import {env} from '@/utils/env/client.mjs'

export type ModuleRebatesFilterContentItem = IContentItem<{
	heading: Elements.TextElement
	allText: Elements.TextElement
	sortByText: Elements.TextElement
	forDogsText: Elements.TextElement
	forCatsText: Elements.TextElement
	forBeefText: Elements.TextElement
	forSwineText: Elements.TextElement
	forDairyText: Elements.TextElement
	forPoultryText: Elements.TextElement
	mixAndMatchText: Elements.TextElement
	selectBrandText: Elements.TextElement
}>

export const filter = {
	ALL: 'all',
	DOGS: 'for_dogs',
	CATS: 'for_cats',
	MIXMAX: 'mix_and_match_offers',
	BEEF: 'for_beef',
	SWINE: 'for_swine',
	DAIRY: 'for_dairy',
	POULTRY: 'for_poultry',
} as const

export type FilterType = (typeof filter)[keyof typeof filter]

export const ModuleRebatesFilterBlock: ExtendedBlock<
	ModuleRebatesFilterContentItem,
	{
		activeType?: FilterType
		activeBrand?: string
		setActiveType: (type: FilterType) => void
		setActiveBrand: (brand: string) => void
	}
> = ({
	block: {
		elements: {
			allText,
			forCatsText,
			forDogsText,
			forBeefText,
			forDairyText,
			forPoultryText,
			forSwineText,
			heading,
			mixAndMatchText,
			selectBrandText,
			sortByText,
		},
	},
	activeType = filter.ALL,
	activeBrand = '',
	setActiveType,
	setActiveBrand,
}) => {
	const [brands, setBrands] = useState<[{codename: string; name: string}]>([
		{codename: '', name: ''},
	])

	// TODO: refactor this to be more type-safe
	useEffect(() => {
		void fetch('/api/brands')
			.then((res) => res.json())
			.then((data) => {
				setBrands(
					JSON.parse(data as string) as [
						{codename: string; name: string},
					]
				)
			})
	}, [])

	return (
		<div>
			<div className="my-10 flex flex-col items-center gap-6 border-b border-light-grey pb-2 md:flex-row md:justify-between">
				<h4 className="text-xl font-normal">{heading}</h4>
				<div className="flex flex-col gap-2 md:flex-row md:items-center lg:gap-3">
					<span className="text-xs italic">{sortByText}</span>
					<button
						className={clsx(
							activeType === filter.ALL
								? 'bg-pet-midnight text-white'
								: null,
							'rounded-3xl border-2 border-pet-midnight px-2 py-0.5 text-sm font-semibold lg:px-5'
						)}
						onClick={() => {
							setActiveType(filter.ALL)
						}}
						type="button"
					>
						{allText}
					</button>
					{env.NEXT_PUBLIC_TOWER === 'pet' ? (
						<>
							<button
								className={clsx(
									activeType === filter.DOGS
										? 'bg-pet-midnight text-white'
										: null,
									'rounded-3xl border-2 border-pet-midnight px-2 py-0.5 text-sm font-semibold lg:px-5'
								)}
								onClick={() => {
									setActiveType(filter.DOGS)
								}}
								type="button"
							>
								{forDogsText}
							</button>
							<button
								className={clsx(
									activeType === filter.CATS
										? 'bg-pet-midnight text-white'
										: null,
									'rounded-3xl border-2 border-pet-midnight px-2 py-0.5 text-sm font-semibold lg:px-5'
								)}
								onClick={() => {
									setActiveType(filter.CATS)
								}}
								type="button"
							>
								{forCatsText}
							</button>
							<button
								className={clsx(
									activeType === filter.MIXMAX
										? 'bg-pet-midnight text-white'
										: null,
									'rounded-3xl border-2 border-pet-midnight px-2 py-0.5 text-sm font-semibold lg:px-5'
								)}
								onClick={() => {
									setActiveType(filter.MIXMAX)
								}}
								type="button"
							>
								{mixAndMatchText}
							</button>
						</>
					) : null}
				</div>
				{env.NEXT_PUBLIC_TOWER === 'farm' && (
					<div className="flex gap-1">
						<button
							className={clsx(
								activeType === filter.BEEF
									? 'bg-pet-midnight text-white'
									: null,
								`rounded-3xl border-2 border-pet-midnight px-2 py-0.5 text-sm font-semibold lg:px-5`
							)}
							onClick={() => {
								setActiveType(filter.BEEF)
							}}
							type="button"
						>
							{forBeefText}
						</button>
						<button
							className={clsx(
								activeType === filter.SWINE
									? 'bg-pet-midnight text-white'
									: null,
								`rounded-3xl border-2 border-pet-midnight px-2 py-0.5 text-sm font-semibold lg:px-5`
							)}
							onClick={() => {
								setActiveType(filter.SWINE)
							}}
							type="button"
						>
							{forSwineText}
						</button>
						<button
							className={clsx(
								activeType === filter.DAIRY
									? 'bg-pet-midnight text-white'
									: null,
								`rounded-3xl border-2 border-pet-midnight px-2 py-0.5 text-sm font-semibold lg:px-5`
							)}
							onClick={() => {
								setActiveType(filter.DAIRY)
							}}
							type="button"
						>
							{forDairyText}
						</button>
						<button
							className={clsx(
								activeType === filter.POULTRY
									? 'bg-pet-midnight text-white'
									: null,
								`rounded-3xl border-2 border-pet-midnight px-2 py-0.5 text-sm font-semibold lg:px-5`
							)}
							onClick={() => {
								setActiveType(filter.POULTRY)
							}}
							type="button"
						>
							{forPoultryText}
						</button>
					</div>
				)}
				{selectBrandText ? (
					<div className="relative">
						<select
							className="mb-0 max-w-[200px] appearance-none rounded-md border border-pet-midnight !bg-white py-1 text-sm font-semibold text-pet-primary"
							name="brand-dropdown"
							onChange={(e) => {
								setActiveBrand(e.target.value)
							}}
							value={activeBrand}
						>
							<option value="">{selectBrandText}</option>
							{brands.map((brand) => (
								<option
									key={brand.codename}
									value={brand.codename}
								>
									{brand.name}
								</option>
							))}
						</select>
						<svg
							aria-hidden="true"
							className="mr-[7px] mt-[5px] !fill-pet-midnight"
							focusable="false"
							height="20"
							viewBox="0 0 20 20"
							width="20"
						>
							<path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
						</svg>
					</div>
				) : null}
			</div>
		</div>
	)
}
