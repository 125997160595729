import {EllipseButton, ButtonIcons} from '@elanco/component-library-v2'

interface ShowSearchButtonProps {
	showSearch: boolean
	toggleSearchPopup: () => void
	searchAccessibilityText: string
}

export const ShowSearchButton = ({
	showSearch,
	toggleSearchPopup,
	searchAccessibilityText,
}: ShowSearchButtonProps): JSX.Element => (
	<>
		{showSearch ? (
			<EllipseButton
				as="button"
				className="ml-4 border border-white"
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- TODO: fix component library types
				icon={ButtonIcons.Search}
				onClick={toggleSearchPopup}
				size="sm"
				title={searchAccessibilityText}
			/>
		) : undefined}
	</>
)
