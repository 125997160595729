/**
 * Checks if the kontent string exists and returns a supplied string otherwise
 *
 * @param kontentText - Kontent string
 * @param text - fallback string if Kontent string doesn't exist
 * @returns kontent string if it exists, fallback string otherwise
 */
export const textChecker = (kontentText: string, text: string): string =>
	!kontentText || kontentText === '<p><br></p>' ? text : kontentText

/**
 * Replaces all the values in a string based on an array of paired values
 *
 * @param text - text to have elements replaced
 * @param arr[][] - array of string pairs, first string for what to look for in string and second what to replace with
 * @returns string of text replaced based on the contents of arr
 */
export const replaceString = (text: string, arr: string[][]): string =>
	arr.reduce((acc, cur) => acc.replace(cur[0]!, cur[1]!), text)
