import type {SyntheticEvent} from 'react'
import {useEffect, useState} from 'react'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {
	ReusableCTAButtonComponent,
	type CtaButtonContentItem,
} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'
import type {LinkContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/link'
import type {SmallTextContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/small-text'
import type {ImageContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/image'
import type {ExtendedBlock, Tersed} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'
import {RebatesRowBlock} from './rebates-row'

const ITEM_DISPLAY_COUNT = 6

export type RebatesTableRowContentItem = IContentItem<{
	brandImage: Elements.LinkedItemsElement<ImageContentItem>
	brand: Elements.TaxonomyElement
	offerCode: Elements.TextElement
	offerDetails: Elements.RichTextElement
	type: Elements.MultipleChoiceElement
	rebateValue: Elements.RichTextElement
	validPurchaseDates: Elements.TextElement
	submissionDeadline: Elements.TextElement
	offerInfoText: Elements.TextElement
	claimRebateCta: Elements.LinkedItemsElement<CtaButtonContentItem>
	safetyLinks: Elements.LinkedItemsElement<LinkContentItem>
}>

export type RebatesTableContentItem = IContentItem<{
	columnHeadings: Elements.LinkedItemsElement<SmallTextContentItem>
	rebatesTableData: Elements.LinkedItemsElement<RebatesTableRowContentItem>
	noRebatesText: Elements.RichTextElement
	loadMoreButton: Elements.LinkedItemsElement<CtaButtonContentItem>
}>

export const RebatesTableBlock: ExtendedBlock<
	RebatesTableContentItem,
	{
		rebates: Tersed<RebatesTableRowContentItem>[]
	}
> = ({
	block: {
		elements: {columnHeadings, noRebatesText, loadMoreButton},
	},
	rebates,
	...context
}) => {
	const [rebatesInView, setRebatesInView] = useState(
		[...rebates].slice(0, ITEM_DISPLAY_COUNT)
	)
	const [count, setCount] = useState(ITEM_DISPLAY_COUNT)

	const handleLoadMore = (e: SyntheticEvent): void => {
		e.preventDefault()
		setRebatesInView([...rebates].slice(0, count + ITEM_DISPLAY_COUNT))
		setCount((prev) => prev + ITEM_DISPLAY_COUNT)
	}

	const isLoadMoreVisible = rebatesInView.length !== rebates.length

	useEffect(() => {
		setRebatesInView([...rebates].slice(0, count))
	}, [count, rebates])

	return (
		<div className="relative" id="rebates-table">
			<table className="block w-full md:table">
				<thead>
					<tr className="absolute left-[-9999px] top-[-9999px] block border-light-grey md:static md:left-auto md:top-auto md:table-row md:border-b">
						{columnHeadings.map((col) => (
							<th
								className="border-0 px-4 py-3 text-left text-sm italic first-of-type:pl-0"
								key={col.system.id}
							>
								{col.elements.content}
							</th>
						))}
					</tr>
				</thead>
				<tbody className="block border-t-2 md:table-row-group md:border-0">
					{rebatesInView.length > 0 ? (
						<>
							{rebatesInView.map((rebate) => (
								<RebatesRowBlock
									block={{
										elements: {
											authenticationGateComponentUserRoles:
												[],
											columnHeadings,
											rebate: [rebate],
										},
										system: rebate.system,
									}}
									key={rebate.system.id}
									{...context}
								/>
							))}
						</>
					) : (
						<tr className="block bg-white px-4 py-2 md:table-row">
							<td className="py-10 text-center" colSpan={7}>
								<RichTextRenderer
									element={noRebatesText}
									{...context}
								/>
							</td>
						</tr>
					)}
				</tbody>
			</table>
			{isLoadMoreVisible ? (
				<div className="py-4 text-center">
					{loadMoreButton.map((button) => (
						<ReusableCTAButtonComponent
							block={button}
							key={button.system.id}
							onClick={(e: SyntheticEvent) => {
								handleLoadMore(e)
							}}
							variant={
								button.elements.variant[0]?.codename ||
								'pet_primary_green'
							}
							{...context}
						/>
					))}
				</div>
			) : null}
		</div>
	)
}
