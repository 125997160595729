import {FetchBusinessMapDataResponse} from '@/services/FetchBusinessMapData'
import http from '@/utils/axios'
import {QueryFunctionContext} from 'react-query'

interface IBusinessMapDataParameters {
	tagIdsString: string
	typeIdsString: string
}

type TBusinessMapDataQueryKey = [string, IBusinessMapDataParameters]

export const fetchBusinessMapMarkers = async (
	context: QueryFunctionContext<TBusinessMapDataQueryKey>
) => {
	const [, params] = context.queryKey
	const {tagIdsString, typeIdsString} = params

	let offset = 0

	const limitPerPage = 10000
	let hasNextPage = true

	const out = [] as FetchBusinessMapDataResponse['activeMarkers']

	while (hasNextPage) {
		const {data} = await http<
			undefined,
			{data: FetchBusinessMapDataResponse}
		>(
			`/api/find-a-business?tagIdsString=${tagIdsString}&typeIdsString=${encodeURIComponent(
				typeIdsString
			)}&limit=${limitPerPage}&offset=${offset}`,
			{timeout: 180000}
		)
		out.push(...data.activeMarkers)

		offset += limitPerPage
		hasNextPage = data.hasNextPage
	}

	return out
}
