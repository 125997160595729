import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {useAuth} from '@/_new-code/products/auth/auth-provider'
import {filterBlocksByUserRole} from '@/components/BlockMapper/utils'
import type {QuizContentItem} from '../quiz'
import type {ImageContentItem} from '../image'
import type {TabbedContentBlockContentItem} from '../tabbed-content-block'
import type {FullTabbedBlockContentItem} from '../full-tabbed-block'
import type {LargeBenefitsBlockContentItem} from '../large-benefits-block/large'
import type {RichTextContentItem} from '../rich-text'
import type {IconWithTextContentItem} from '../icon-with-text'
import type {VideoBlockContentItem} from '../videos/video-block'
import type {CtaButtonContentItem} from '../cta-button'
import type {ProductDirectoryCardContentItem} from '../product-directory-card'
import type {SmallTextContentItem} from '../small-text'
import type {IconListContentItem} from '../icon-list'
import type {WistiaContentItem} from '../videos/wistia'
import type {ScrollingImagesContentItem} from '../scrolling-images'
import type {CardContentItem} from '../card'

export type ProductDirectoryContentItem = IContentItem<{
	products: Elements.LinkedItemsElement<
		| QuizContentItem
		| ImageContentItem
		| TabbedContentBlockContentItem
		| CardContentItem
		| FullTabbedBlockContentItem
		| LargeBenefitsBlockContentItem
		| RichTextContentItem
		| IconWithTextContentItem
		| VideoBlockContentItem
		| CtaButtonContentItem
		| ProductDirectoryCardContentItem
		| SmallTextContentItem
		| IconListContentItem
		| WistiaContentItem
		| ScrollingImagesContentItem
	>
}>

export const ProductDirectoryBlock: Block<ProductDirectoryContentItem> = ({
	BlockMapper,
	block,
	page,
	globalConfig,
}) => {
	const auth = useAuth()

	return (
		<div className="container-wide grid auto-cols-fr auto-rows-max grid-cols-1 md:grid-cols-3">
			{filterBlocksByUserRole(block.elements.products, auth).map(
				(eachBlock) => (
					<div
						data-kontent-item-id={eachBlock.system.id}
						key={eachBlock.system.id}
					>
						{BlockMapper({
							blocks: [eachBlock],
							globalConfig,
							page,
						})}
					</div>
				)
			)}
		</div>
	)
}
