import http from '@/utils/axios'
import {
	MarketingBrandResponse,
	MarketingBrand,
} from '@/models/consumerEngagement/models'

export const fetchMarketingBrands = async (
	locale: string
): Promise<MarketingBrand[]> => {
	const endpoint = `/api/species/all/marketingbrands?locale=${locale}`
	const {data: response} = await http<MarketingBrandResponse>(endpoint)
	const {data} = response

	return data
}
