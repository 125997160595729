import type {Block} from '@/_new-code/services/kontent-ai/types'

export const NadisWidgetBlock: Block = () => {
	return (
		<div className="-mb-24 mt-6 flex justify-around md:mt-8">
			<iframe
				height="650"
				src="https://alerts.nadis.org.uk/alertservice?iframe=dontbreakout"
				title="NADIS Widget"
				width="630"
			/>
		</div>
	)
}
