import {
	SpeciesBreedResponse,
	SpeciesBreed,
} from '@/models/consumerEngagement/models'
import http from '@/utils/axios'

export const fetchPetBreeds = async (
	species: string,
	locale: string
): Promise<SpeciesBreed[]> => {
	const endpoint = `/api/species/${species}/breeds?locale=${locale}`
	const {data: response} = await http<SpeciesBreedResponse>(endpoint)

	return response?.data
}
