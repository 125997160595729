import {QueryFunctionContext} from 'react-query'
import http from '@/utils/axios'
import {ErrorResponse} from '@/models/ResponseType'

interface IParasiteCases {
	parasiteType?: string
	parasiteStartDate?: string
}

type TParasiteCasesQueryKey = [string, IParasiteCases]

type TParasiteCase = {
	count: number
	id: string
	name: string
}

export type TParasiteCaseResponse = TParasiteCase[] | ErrorResponse

export const fetchParasiteCases = async (
	context: QueryFunctionContext<TParasiteCasesQueryKey>
): Promise<TParasiteCaseResponse> => {
	const [, params] = context.queryKey
	const {parasiteType, parasiteStartDate} = params
	const endpoint = `/api/parasite-tracker-cases?parasiteType=${parasiteType}&parasiteStartDate=${parasiteStartDate}`
	const {data} = await http<TParasiteCaseResponse>(endpoint)
	return data
}
