import {useState, useEffect} from 'react'
import {setCookie} from 'cookies-next'
import {useAuth} from '@/_new-code/products/auth/auth-provider'
import type {PetFormData} from '@/fetchers/fetchPetProfiles'
import type {Pet} from '@/models/consumerEngagement/models'

interface AdoptPetWizardHookResults {
	formStep: number
	onPetCreate: (
		data: PetFormData,
		rawData: Pet,
		creatingNewPet: boolean
	) => void
	newPets: PetFormData[]
	handleLogout: () => void
	showErrorsModal: boolean
	closeErrorModal: () => void
}

export function useAdoptPetWizard(): AdoptPetWizardHookResults {
	const authState = useAuth()
	const [formStep, setFormStep] = useState(1)
	const [newPets, setNewPets] = useState<PetFormData[]>([])
	const [showErrorsModal, setShowErrorsModal] = useState(false)

	const closeErrorModal = (): void => {
		setShowErrorsModal(false)
	}

	const onPetCreate = (
		data: PetFormData,
		_rawData: Pet,
		creatingNewPet: boolean
	): void => {
		setNewPets((oldPets) => [...oldPets, data])
		if (!creatingNewPet) {
			if (authState.userDetails.light) {
				window.gigya?.accounts.logout()
			}
			setFormStep(3)
			window.scrollTo(0, 0)
		} else {
			// Resetting the form by going back one step and then forward
			setFormStep(1)
			setFormStep(2)
		}
	}

	const handleLogout = (): void => {
		window.gigya?.accounts.logout({
			callback: () => {
				setCookie('cdc_user', null)
				window.location.reload()
			},
		})
	}

	useEffect(() => {
		function addEventHooks(): void {
			const form = document.getElementById('registration-form')
			if (!form) return

			const emailField = form.querySelector("input[name='profile.email']")
			if (!emailField) return

			emailField.addEventListener('keyup', () => {
				const customMessage = form.getElementsByClassName(
					'custom-gigya-message'
				)
				for (let i = customMessage.length - 1; i >= 0; i -= 1) {
					customMessage[i]?.remove()
				}
			})
		}

		function onAfterScreenLoadFun(): void {
			addEventHooks()
		}

		function cleanUpCustomErrors(): void {
			const form = document.getElementById('registration-form')
			if (!form) return

			const customMessage = form.getElementsByClassName(
				'custom-gigya-message'
			)
			for (let i = customMessage.length - 1; i >= 0; i -= 1) {
				customMessage[i]?.remove()
			}
		}

		function onAfterSubmitFun(event: OnAfterSubmitEvent): void {
			cleanUpCustomErrors()
			if (event.response.UID && typeof event.response.UID === 'string') {
				authState.handleUserDetails(
					event.response.UID,
					true,
					event.profile,
					event.data
				)
				setFormStep(2)
			}
		}

		function registrationScreenLoad(): void {
			try {
				gigya?.accounts.showScreenSet({
					screenSet: 'Loyalty-Web-Portal-RegistrationLoginV2',
					startScreen: 'loyalty-shelter-registration',
					containerID: 'registration-form',
					onAfterScreenLoad: onAfterScreenLoadFun,
					onAfterSubmit: onAfterSubmitFun,
				})
			} catch (e) {
				setTimeout(() => {
					registrationScreenLoad()
				}, 100)
			}
		}

		registrationScreenLoad()
	}, [authState])

	useEffect(() => {
		if (authState.userDetails.id) {
			setFormStep(2)
		}
	}, [authState.userDetails.id])

	return {
		formStep,
		onPetCreate,
		newPets,
		handleLogout,
		showErrorsModal,
		closeErrorModal,
	}
}
