import {useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import Image from 'next/future/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useRouter} from 'next/router'
import {clsx} from 'clsx'
import type {PetFormData} from '@/fetchers/fetchPetProfiles'
import {pushToDataLayer} from '@/utils/analytics'
import Alert from '@/components/Alert'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {
	Block,
	PetIconContentItem,
	Tersed,
} from '@/_new-code/services/kontent-ai/types'
import type {Pet} from '@/models/consumerEngagement/models'
import type {PetIcon} from '@/_new-code/products/your-pet-and-you/models'
import {updatePetProfile} from '@/_new-code/products/your-pet-and-you/mutations/update-pet-profile'
import type {ModulePetCreateFormContentItem} from '../module-pet-create-form'
import {ModulePetCreateFormBlock} from '../module-pet-create-form'
import type {PetIconFormContentItem} from '../pet-icon-form'
import {PetIconFormBlock} from '../pet-icon-form'
import type {ModuleMedicationReminderModalContentItem} from '../module-medication-reminder-modal'
import {ModuleMedicationReminderModalBlock} from '../module-medication-reminder-modal'

export type PetCreationWizardContentItem = IContentItem<{
	titlePetProfileForm: Elements.TextElement
	descriptionPetProfileForm: Elements.TextElement
	titlePetIconForm: Elements.TextElement
	descriptionPetIconForm: Elements.TextElement
	titlePetReminderForm: Elements.TextElement
	descriptionPetReminderForm: Elements.TextElement
	backgroundImage: Elements.AssetsElement
	nextButtonText: Elements.TextElement
	backButtonText: Elements.TextElement
	skipReminderButtonText: Elements.TextElement
	createReminderButtonText: Elements.TextElement
	petProfileForm: Elements.LinkedItemsElement<ModulePetCreateFormContentItem>
	petIconForm: Elements.LinkedItemsElement<PetIconFormContentItem>
	petReminderForm: Elements.LinkedItemsElement<ModuleMedicationReminderModalContentItem>
	successUrl: Elements.TextElement
}>

export const PetCreateWizardBlock: Block<PetCreationWizardContentItem> = ({
	block,
	globalConfig,
	page,
	BlockMapper,
}) => {
	const queryClient = useQueryClient()
	const {locale = ''} = useRouter()

	const {isLoading, mutate} = useMutation({
		mutationFn: (data: PetFormData) => updatePetProfile(data, locale),
		mutationKey: 'petProfile/update',
		onSuccess: () => queryClient.invalidateQueries({queryKey: ['pets']}),
	})

	const [petData, setPetData] = useState<PetFormData>()
	const [rawPetData, setRawPetData] = useState<Pet>()
	const [iconFormError, setIconFormError] = useState(false)

	const mapIcon = (rawItem: Tersed<PetIconContentItem>): PetIcon => ({
		id: rawItem.system.id,
		species: rawItem.elements.species[0]?.codename,
		imageUrl: rawItem.elements.image[0]?.url,
	})

	const petIcons = globalConfig.elements.petIcons
		.map(mapIcon)
		.filter(
			(icon) =>
				icon.species?.toLowerCase() ===
				petData?.petSpecies.toLowerCase()
		)

	const defaultIcon =
		petData?.petSpecies === 'dog' &&
		globalConfig.elements.defaultDogIcon[0] &&
		globalConfig.elements.defaultCatIcon[0]
			? mapIcon(globalConfig.elements.defaultDogIcon[0])
			: // @ts-expect-error -- TODO: untangle ternary
				mapIcon(globalConfig.elements.defaultCatIcon[0])

	const [activeStep, setActiveStep] = useState(1)

	const updateImage = (imageReference: string): void => {
		setIconFormError(false)
		if (!petData) {
			return
		}

		try {
			mutate({
				...petData,
				id: rawPetData?.id,
				imageReference,
			})
			pushToDataLayer({
				event: 'pet_profile',
				step_completed: activeStep,
				step_name: block.elements.petIconForm[0]?.elements.title,
			})
			setActiveStep(3)

			window.scrollTo(0, 0)
		} catch {
			setIconFormError(true)
		}
	}

	let title
	let description

	switch (activeStep) {
		case 3:
			title = block.elements.titlePetReminderForm
			description = block.elements.descriptionPetReminderForm
			break
		case 2:
			title = block.elements.titlePetIconForm
			description = block.elements.descriptionPetIconForm
			break
		case 1:
		default:
			title = block.elements.titlePetProfileForm
			description = block.elements.descriptionPetProfileForm
			break
	}

	const petProfileForm = block.elements.petProfileForm[0]

	return (
		<div>
			<div className="container-wide relative flex flex-col md:h-[450px] md:flex-row md:justify-between">
				<div className="absolute -left-44 top-8 z-0 hidden h-[270px] w-[270px] rounded-full bg-[#CFEAC640] md:block" />
				<div className="relative z-20 mt-6 flex flex-col md:mt-20 md:max-w-xs lg:max-w-lg">
					<h1 className="mb-5 text-4xl font-semibold">{title}</h1>
					<h2 className="text-sm font-normal">{description}</h2>
				</div>
				{block.elements.backgroundImage[0] ? (
					<div className="flex justify-center md:absolute md:right-0">
						<div className="absolute -right-4 top-16 z-[-1] hidden h-[140px] w-[140px] rounded-full bg-[#258821AA]/[0.4] md:block" />
						<div className="block max-w-[180px] md:max-w-[450px]">
							<Image
								alt={
									block.elements.backgroundImage[0]
										.description ?? ''
								}
								className="relative"
								height={
									block.elements.backgroundImage[0]?.height ??
									450
								}
								loader={loadImageFromKontentAI}
								priority
								src={block.elements.backgroundImage[0].url}
								width={
									block.elements.backgroundImage[0]?.width ??
									450
								}
							/>
						</div>
						<div className="absolute -left-16 bottom-0 z-[-1] hidden h-[270px] w-[270px] rounded-full bg-[#CFEAC640] md:block" />
					</div>
				) : null}
			</div>
			<section className="container-wide relative z-auto mb-10 md:-mt-44">
				<div className="relative my-6 mt-2 max-w-xl rounded border border-light-grey bg-white p-8">
					<div className="relative mb-6 flex justify-center gap-8">
						<div className="absolute top-[11px] h-[2px] w-[130px] bg-pet-green" />
						{[...Array.from(Array(3).keys())].map((el) => {
							const step = el + 1
							return (
								<span
									className={clsx(
										'relative h-6 w-6 rounded-full border-2 text-center text-sm font-semibold text-white',
										step <= activeStep
											? 'border-pet-green bg-pet-green'
											: 'border-pet-green bg-white'
									)}
									key={el}
								>
									{step}
								</span>
							)
						})}
					</div>
					<div className={activeStep === 1 ? 'block' : 'hidden'}>
						{petProfileForm ? (
							<ModulePetCreateFormBlock
								BlockMapper={BlockMapper}
								block={petProfileForm}
								data={rawPetData}
								globalConfig={globalConfig}
								onSubmit={(
									data: PetFormData,
									newRawPetData: Pet
								) => {
									pushToDataLayer({
										event: 'pet_profile',
										step_completed: activeStep,
										step_name:
											petProfileForm.elements.heading,
									})
									setPetData(data)
									setRawPetData(newRawPetData)
									setActiveStep(2)
									window.scrollTo(0, 0)
								}}
								page={page}
							/>
						) : null}
					</div>
					<div className={activeStep === 2 ? 'block' : 'hidden'}>
						{block.elements.petIconForm[0] ? (
							<PetIconFormBlock
								BlockMapper={BlockMapper}
								block={block.elements.petIconForm[0]}
								error={
									iconFormError ? (
										<Alert
											message={
												globalConfig.elements
													.genericApiError
											}
											type="error"
										/>
									) : null
								}
								globalConfig={globalConfig}
								icon={defaultIcon}
								icons={petIcons}
								isEdit={false}
								isLoading={isLoading}
								onBack={() => {
									setActiveStep(1)
								}}
								onSubmit={(ref) => {
									updateImage(ref)
								}}
								page={page}
								petName={petData?.petName ?? ''}
							/>
						) : null}
					</div>
					<div className={activeStep === 3 ? 'block' : 'hidden'}>
						{block.elements.petReminderForm[0] ? (
							<ModuleMedicationReminderModalBlock
								BlockMapper={BlockMapper}
								block={block.elements.petReminderForm[0]}
								globalConfig={globalConfig}
								page={page}
								redirectUrl={block.elements.successUrl}
								selectedPet={rawPetData}
							/>
						) : null}
					</div>
				</div>
			</section>
		</div>
	)
}
