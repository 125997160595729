import {useState, useEffect} from 'react'
import {FullscreenVideo, HeaderCard} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useTrustArcCookieValue} from '@/utils/analytics'
import type {Block, TersedAsset} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'
import {useWindowSize} from '@/_new-code/utilities/hooks/use-window-size'
import type {CtaButtonContentItem} from '../cta-button'
import {ReusableCTAButtonComponent} from '../cta-button'

export type HomePageHeaderBlockContentItem = IContentItem<{
	title: Elements.RichTextElement
	text: Elements.RichTextElement
	videoId: Elements.TextElement
	backgroundImages: Elements.AssetsElement
	buttons: Elements.LinkedItemsElement<CtaButtonContentItem>
	backgroundColour: Elements.TextElement
	mobileImage: Elements.AssetsElement
}>

export const HomePageHeaderBlockBlock: Block<
	HomePageHeaderBlockContentItem
> = ({block, ...context}) => {
	const trustArcCookie = useTrustArcCookieValue()
	const hideVideo =
		trustArcCookie === '1:' || trustArcCookie === '0:' ? 'hide' : ''

	const [headerImage, setHeaderImage] = useState<TersedAsset | null>(null)

	const size = useWindowSize() as {isMobile: boolean}

	useEffect(() => {
		const mobileImage = block.elements.mobileImage[0] ?? null
		const backgroundImage = block.elements.backgroundImages[0] ?? null

		if (size.isMobile && mobileImage) {
			setHeaderImage(mobileImage)
		} else {
			setHeaderImage(backgroundImage)
		}
	}, [block.elements.backgroundImages, block.elements.mobileImage, size])

	return (
		<div>
			<FullscreenVideo
				backgroundColor={block.elements.backgroundColour}
				controls={1}
				id="header"
				posterFrameAlignment="top"
				posterFrameImageAltText={headerImage?.description}
				posterFrameImageUrl={headerImage?.url}
				videoId={hideVideo === 'hide' ? '' : block.elements.videoId}
			>
				<HeaderCard
					key={block.elements.title}
					title={
						<RichTextRenderer
							className="*:*:!mb-0"
							element={block.elements.title}
							{...context}
						/>
					}
				>
					<RichTextRenderer
						className="mb-4 mt-3"
						element={block.elements.text}
						removeEmptyLines
						{...context}
					/>
					{block.elements.buttons[0] ? (
						<div className="mt-10">
							{block.elements.buttons.map((button) => (
								<ReusableCTAButtonComponent
									block={button}
									className="mr-6 mt-6"
									key={button.system.id}
									{...context}
								/>
							))}
						</div>
					) : null}
				</HeaderCard>
			</FullscreenVideo>
		</div>
	)
}
