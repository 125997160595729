import {ProductSection} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {
	ReusableCTAButtonComponent,
	type CtaButtonContentItem,
} from '../cta-button'
import {IconListBlock, type IconListContentItem} from '../icon-list'

export type ProductSectionModContentItem = IContentItem<{
	titleText: Elements.TextElement
	paragraphText: Elements.TextElement
	imageBanner: Elements.AssetsElement
	button: Elements.LinkedItemsElement<CtaButtonContentItem>
	list: Elements.LinkedItemsElement<IconListContentItem>
}>

export const ProductSectionModBlock: Block<ProductSectionModContentItem> = ({
	block,
	...context
}) => {
	return (
		<div className="py-10">
			<ProductSection
				className="container-wide border-1 rounded-lg bg-gray-200 p-10 shadow-inner"
				imageSrc={block.elements.imageBanner[0]?.url}
			>
				<h4 className="mb-4" data-kontent-element-codename="title_text">
					{block.elements.titleText}
				</h4>
				<p className="mb-4">{block.elements.paragraphText}</p>
				{block.elements.list[0] ? (
					<IconListBlock
						block={block.elements.list[0]}
						{...context}
					/>
				) : null}
				{block.elements.button[0] ? (
					<ReusableCTAButtonComponent
						block={block.elements.button[0]}
						{...context}
					/>
				) : null}
			</ProductSection>
		</div>
	)
}
