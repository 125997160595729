import {Children, isValidElement} from 'react'
import {UIDFork, UIDConsumer} from 'react-uid'
import type {HistoryTimelineSectionProps} from './history-timeline-section'
import type {NavItemsProps} from './in-page-nav'
import {InPageNav} from './in-page-nav'

interface HistoryTimelineProps {
	children: React.ReactNode
	navOffset?: number
	closeNavAriaLabel?: string
	openNavAriaLabel?: string
}

export const HistoryTimeline: React.FC<HistoryTimelineProps> = ({
	children,
	navOffset = 0,
	closeNavAriaLabel = 'Close timeline navigation',
	openNavAriaLabel = 'Open timeline navigation',
}) => {
	const filteredChildren: React.ReactElement<HistoryTimelineSectionProps>[] =
		Children.toArray(children).filter(
			(item): item is React.ReactElement<HistoryTimelineSectionProps> =>
				isValidElement<HistoryTimelineSectionProps>(item)
		)

	const navItems = filteredChildren.map<NavItemsProps>((child) => {
		return {
			id: child.props.id,
			title: child.props.title,
		}
	})

	const storageKey = 'show-history-nav'

	return (
		<UIDFork>
			<UIDConsumer>
				{(uid) => {
					return (
						<div
							className="relative pt-12"
							id={`history-timeline-${uid}`}
							key={uid}
						>
							<InPageNav
								closeNavAriaLabel={closeNavAriaLabel}
								containerId={`history-timeline-${uid}`}
								navItems={navItems}
								navOffset={navOffset}
								openNavAriaLabel={openNavAriaLabel}
								showDropdownOnMobile
								storageKey={storageKey}
							/>

							{filteredChildren.map((child) => (
								<div key={uid + child.props.title}>{child}</div>
							))}
						</div>
					)
				}}
			</UIDConsumer>
		</UIDFork>
	)
}
