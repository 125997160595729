import {useCallback, useEffect, useState} from 'react'
import {useRouter} from 'next/router'
import type {GetAllPetNamesAPIResponse} from '@/pages/api/petNames/all'
import type {PetNameSearchAPIResponse} from '@/pages/api/petNames/search'

interface NameFilterHookResult {
	filterPetNames: (letterFrom: string, letterTo: string) => void
	filterBySpecie: (specie: string) => void
	loadMoreNames: (trait: string) => void
	loading: boolean
	loadMore: boolean
	showLoadMoreText: boolean
	selected: string
	names: string[]
	displayAllNames: () => void
}

export function useNameFilter(speciesText: string): NameFilterHookResult {
	const {locale = ''} = useRouter()
	const [petResultsList, setPetResultsList] = useState<string[]>([])
	const [loading, setLoading] = useState(false)
	const [loadMore, setLoadMore] = useState(false)
	const [showLoadMoreText, setShowLoadMoreText] = useState(true)
	const [selected, setSelected] = useState<string>()
	const [selectedLetters, setSelectedLetters] = useState(['A', 'B', 'C', 'D'])

	const fetchAllPetNames = useCallback(async () => {
		setLoading(true)
		try {
			const response = await fetch(`/api/petNames/all?locale=${locale}`)
			const results = (await response.json()) as GetAllPetNamesAPIResponse

			if ('error' in results) throw new Error(JSON.stringify(results))

			const noDuplicates = [...new Set(results.data)]
			setPetResultsList(noDuplicates)
		} catch (e) {
			//
		} finally {
			setLoading(false)
		}
	}, [locale])

	async function fetchSpeciesName(specie: string): Promise<void> {
		setLoading(true)
		try {
			const url = `/api/petNames/search?${new URLSearchParams({
				required: `${speciesText}:${specie}`,
				locale,
			}).toString()}`
			const response = await fetch(url)
			const data = (await response.json()) as PetNameSearchAPIResponse
			if (!data.data[0]) {
				return
			}
			const {names} = data.data[0]
			if (names.length === 0) {
				return
			}
			const noDuplicates = [...new Set(names)]
			setPetResultsList(noDuplicates)
		} catch (e) {
			//
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		void fetchAllPetNames()
	}, [fetchAllPetNames])

	const filterPetNames = (letterFrom: string, letterTo: string): void => {
		setLoadMore(false)
		setShowLoadMoreText(true)
		const alphabet = [
			'A',
			'B',
			'C',
			'D',
			'E',
			'F',
			'G',
			'H',
			'I',
			'J',
			'K',
			'L',
			'M',
			'N',
			'O',
			'P',
			'Q',
			'R',
			'S',
			'T',
			'U',
			'V',
			'W',
			'X',
			'Y',
			'Z',
		]
		const indexOfFrom = alphabet.indexOf(letterFrom)
		const indexOfTo = alphabet.indexOf(letterTo)
		setSelectedLetters(alphabet.slice(indexOfFrom, indexOfTo + 1))
	}

	const filterBySpecie = (specie: string): void => {
		setSelected(specie)
		void fetchSpeciesName(specie)
	}

	const loadMoreNames = (trait: string): void => {
		setSelected(trait)
		void fetchAllPetNames()
	}

	const displayAllNames = (): void => {
		setLoadMore(true)
		setShowLoadMoreText(false)
	}

	// Filter names by letter

	const filteredNames = petResultsList.filter((petName) =>
		selectedLetters.includes(petName.charAt(0))
	)

	return {
		filterPetNames,
		filterBySpecie,
		loadMoreNames,
		loading,
		loadMore,
		showLoadMoreText,
		selected: selected ?? '',
		names: filteredNames,
		displayAllNames,
	}
}
