import {useEffect} from 'react'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {defaultMargins} from '@/_new-code/products/flexible-web-toolkit/styles'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {HistoryTimeline as HistoryTimelineL} from '../../components/history-timeline'
import {HistoryTimelineSection} from '../../components/history-timeline/history-timeline-section'
import {HistoryTimelineItem} from '../../components/history-timeline/history-timeline-item'

type HistoryTimelineSectionContentItem = IContentItem<{
	circleText: Elements.TextElement
	hasLargeCircle: Elements.MultipleChoiceElement
	heading: Elements.TextElement
	content: Elements.TextElement
}>

export type HistoryTimelineContentItem = IContentItem<{
	sections: Elements.LinkedItemsElement<HistoryTimelineSectionContentItem>
}>

export const HistoryTimelineBlock: Block<HistoryTimelineContentItem> = ({
	block: {
		elements: {sections},
	},
}) => {
	useEffect(() => {
		const nav = document.querySelector<HTMLDivElement>(
			'.InPageNav-module_InPageNavShowDropdown__1cJRe'
		)
		if (nav) nav.style.display = 'none'
	}, [])

	return (
		<div className={defaultMargins}>
			<HistoryTimelineL data-kontent-element-codename="history_timeline">
				{sections.map((section, index) => (
					<HistoryTimelineSection
						data-kontent-element-codename="history_timeline_section"
						id={`history-timeline-section-${index}`}
						key={section.system.id}
						title={section.elements.heading}
					>
						<HistoryTimelineItem
							circleText={section.elements.circleText}
							data-kontent-element-codename="history_timeline_item"
							hasLargeCircle={
								section.elements.hasLargeCircle[0]?.codename ===
								'yes'
							}
							key={section.system.id}
						>
							<div className="content content--no-width-restriction content--remove-bottom-margin" />
							<h2
								className="mb-4"
								data-kontent-element-codename="heading"
							>
								{section.elements.heading}
							</h2>
							<p data-kontent-element-codename="content">
								{section.elements.content}
							</p>
						</HistoryTimelineItem>
					</HistoryTimelineSection>
				))}
			</HistoryTimelineL>
		</div>
	)
}
