import {ErrorTooltip} from './error-tooltip'

interface AnswerOptionProps {
	checked: boolean
	className: string
	id: string
	onAnswerSelected: (e: React.ChangeEvent<HTMLInputElement>) => void
	optionText: string
	questionId: string
	showError?: boolean
	wrongOptionTooltip?: string
}

export const AnswerOption = ({
	checked,
	className,
	id,
	onAnswerSelected,
	optionText,
	questionId,
	showError,
	wrongOptionTooltip,
}: AnswerOptionProps): JSX.Element => {
	return (
		<div className="relative">
			<input
				checked={checked}
				className={className}
				id={id}
				name={questionId}
				onChange={onAnswerSelected}
				type="radio"
				value={optionText}
			/>
			<label
				className="text-gray-800 block cursor-pointer rounded-lg p-4 peer-checked:border-primary peer-checked:bg-primary peer-checked:text-white"
				htmlFor={id}
			>
				{optionText}
			</label>
			{showError && wrongOptionTooltip ? (
				<ErrorTooltip message={wrongOptionTooltip} />
			) : null}
		</div>
	)
}
