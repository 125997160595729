import type {Tersed} from '@/_new-code/services/kontent-ai/types'
import type {
	SharedQuestionProps,
	DateSelectionContentItem,
} from '@/_new-code/products/campaign-forms/cdc-screen-set-wrapper/models'
import {insertAsterisk} from '../../../../../utils/helpers'
import {useCampaignQuestion} from './hooks'

interface DatePickerProps extends SharedQuestionProps {
	question: Tersed<DateSelectionContentItem>
}

export const DatePicker = ({
	question,
	selectedOptionsCallback,
	injectedElementsCallback,
	errorsCallback,
	validationEvent,
}: DatePickerProps): JSX.Element => {
	const {
		required,
		questionKontentItemId,
		displayError,
		errorClass,
		setSelectedItems,
	} = useCampaignQuestion({
		question,
		selectedOptionsCallback,
		injectedElementsCallback,
		errorsCallback,
		validationEvent,
	})

	const labelId = `label-${questionKontentItemId}`
	const spanId = `span-${questionKontentItemId}`
	const inputId = `input-${questionKontentItemId}`
	injectedElementsCallback(labelId)
	injectedElementsCallback(spanId)
	injectedElementsCallback(inputId)

	return (
		<div
			className="gigya-composite-control gigya-composite-control-textbox"
			id={questionKontentItemId}
		>
			<label className="gigya-label" htmlFor={inputId} id={labelId}>
				<span className="gigya-label-text" id={spanId}>
					<div
						dangerouslySetInnerHTML={{
							__html: insertAsterisk(
								question.elements.questionText.value,
								required
							),
						}}
					/>
				</span>
				{/* eslint-disable-next-line jsx-a11y/label-has-associated-control -- Necessary */}
				{required ? <label className="hidden">*</label> : null}
			</label>
			<div className="relative">
				<input
					className={`gigya-input-text ${errorClass}`}
					id={inputId}
					name={inputId}
					onChange={(evt) => {
						setSelectedItems([evt.target.value])
					}}
					type="date"
				/>
				{displayError ? (
					<span className="gigya-error-msg gigya-error-msg-active">
						This field is required
					</span>
				) : null}
			</div>
		</div>
	)
}
