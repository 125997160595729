import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {EnhancedCardBlock, type EnhancedCardContentItem} from '../enhanced-card'

export type PromotionCardGroupContentItem = IContentItem<{
	brandName: Elements.TextElement
	brandDescription: Elements.TextElement
	productPromotionCards: Elements.LinkedItemsElement<EnhancedCardContentItem>
}>

export const PromotionCardGroupBlock: Block<PromotionCardGroupContentItem> = ({
	block,
	...context
}) => {
	return (
		<div className="my-4">
			<div className="px-4">
				<h2>{block.elements.brandName}</h2>
				<h4>{block.elements.brandDescription}</h4>
			</div>
			<div className="flex-wrap md:flex">
				{block.elements.productPromotionCards.map((card) => (
					<div
						className="flex justify-center p-4 md:w-1/3"
						key={card.system.id}
					>
						<EnhancedCardBlock block={card} {...context} />
					</div>
				))}
			</div>
		</div>
	)
}
