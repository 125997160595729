import type {SVGProps} from 'react'

interface LoadingOverlayProps {
	color?: SVGProps<SVGPathElement>['fill']
}

export function LoadingOverlay({color = ''}: LoadingOverlayProps): JSX.Element {
	return (
		<div className="absolute bottom-0 left-0 right-0 top-0 z-30 flex items-center justify-center bg-black bg-opacity-5">
			<div>
				<svg
					className="h-10 w-10 animate-spin"
					fill="none"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle
						className="opacity-25"
						cx="12"
						cy="12"
						r="10"
						stroke="#fff"
						strokeWidth="4"
					/>
					<path
						d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
						fill={color}
					/>
				</svg>
				<span aria-busy="true" className="sr-only" role="alert">
					Loading
				</span>
			</div>
		</div>
	)
}
