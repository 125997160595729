import {useState} from 'react'
import {Button} from '@elanco/component-library-v2'
import type {AjvError, UiSchema, FormSubmit} from '@rjsf/core'
import Form from '@rjsf/core'
import type {JSONSchema7Object} from 'json-schema'
import FadeLoader from 'react-spinners/FadeLoader'
import {useRouter} from 'next/router'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {addLocalePrefix} from '@/components/BlockMapper/utils'
import type {CtaButtonContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'
import http from '@/utils/axios'
import {parvoPet} from '@/_new-code/products/disease-map/form-selector/form-json/parvo-pet'
import {parvoVet} from '@/_new-code/products/disease-map/form-selector/form-json/parvo-vet'
import type {UserDetails} from '@/services/CDCUserService'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {useAuth} from '@/_new-code/products/auth/auth-provider'
import {pushToDataLayer} from '@/utils/analytics'
import {logError} from '@/services/client-logger'
import {blowfly} from './form-json/blowfly'
import {lungwormVet} from './form-json/lungworm-vet'
import {lungwormPet} from './form-json/lungworm-pet'
import {findMySalesRep} from './form-json/find-my-sales-rep'
import {askAnExpert} from './form-json/ask-an-expert'
import {Checkbox} from './custom-widgets/checkbox'
import {DatePicker} from './custom-widgets/date-picker'

type Obj = Record<string, string>

type FormData = Record<
	string,
	{
		formSchema: Record<string, JSONSchema7Object>
		uiSchema: UiSchema
	}
>

const formType: FormData = {
	blowfly,
	lungworm_vet: lungwormVet,
	lungworm_pet: lungwormPet,
	find_my_sales_rep: findMySalesRep,
	ask_an_expert: askAnExpert,
	parvo_pet: parvoPet,
	parvo_vet: parvoVet,
}

const apiRoutes: Obj = {
	blowfly: '/api/disease/blowfly',
	lungworm_vet: '/api/disease/lungworm/vet',
	lungworm_pet: '/api/disease/lungworm/pet',
	parvo_pet: '/api/disease/parvo/pet',
	parvo_vet: '/api/disease/parvo/vet',
	find_my_sales_rep: '/api/contact/find-my-sales-rep',
	ask_an_expert: '/api/contact/ask-an-expert',
}

export type FormSelectorContentItem = IContentItem<{
	formId: Elements.TextElement
	submitToSendgrid: Elements.MultipleChoiceElement
	toEmailAddress: Elements.TextElement
	fromEmailAddress: Elements.TextElement
	ccEmailAddress: Elements.TextElement
	templateId: Elements.TextElement
	redirectUrl: Elements.TextElement
	requiredError: Elements.TextElement
	validationError: Elements.TextElement
	submitFormError: Elements.TextElement
	tabTitle: Elements.TextElement
	submitButton: Elements.LinkedItemsElement<CtaButtonContentItem>
}>

export const FormSelectorBlock: Block<FormSelectorContentItem> = ({block}) => {
	const authState = useAuth()
	const {locale = '', ...router} = useRouter()

	const [errorText, setErrorText] = useState<string | undefined>('')
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO: better form library?
	const [formData, setFormData] = useState<
		// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents -- TODO: better form library?
		FormSubmit['formData'] | UserDetails
	>(authState.userDetails)
	const [isLoading, setIsLoading] = useState(false)

	const formId = block.elements.formId

	const form = formType[formId]
	if (!form) return <div>Form does not exist</div>

	const formApiRoute = apiRoutes[formId]
	const localizedForm = form.formSchema[locale]
	const {uiSchema} = form

	const handleSubmit = async (data: FormSubmit): Promise<void> => {
		setIsLoading(true)
		pushToDataLayer({
			event: 'cta_click',
			cta_name: block.elements.submitButton[0]?.elements.text,
			cta_category: 'CTA_Button',
		})
		const redirectLink = block.elements.redirectUrl
			? addLocalePrefix(block.elements.redirectUrl, locale)
			: `/${locale}`

		try {
			await http({
				url: formApiRoute,
				method: 'POST',
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO: Better form library?
				data: {
					locale,
					formId,
					...data.formData,
					...(block.elements.submitToSendgrid[0]?.codename === 'yes'
						? {
								toAddress: block.elements.toEmailAddress,
								fromAddress: block.elements.fromEmailAddress,
								ccAddress: block.elements.ccEmailAddress,
								templateId: block.elements.templateId,
							}
						: {}),
				},
			})
			void router.push(redirectLink)
		} catch (e) {
			logError(e)
			setFormData(data.formData)
			setErrorText(block.elements.submitFormError)
			setIsLoading(false)
		}
	}

	const transformErrors = (errors: AjvError[]): AjvError[] =>
		errors.map((error: AjvError) => {
			if (error.name === 'pattern') {
				error.message = block.elements.validationError
			} else if (error.name === 'required') {
				error.message = block.elements.requiredError
			}
			return error
		})

	const widgets = {
		CheckboxWidget: Checkbox,
		DateWidget: DatePicker,
	}

	return (
		<div
			className="mx-15 relative flex w-full flex-col justify-center"
			data-kontent-element-codename="form_selector"
		>
			{isLoading ? (
				<div className="absolute bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center bg-white bg-opacity-50">
					<FadeLoader
						color="#02253e"
						height={15}
						loading
						margin={2}
						radius={2}
						width={5}
					/>
				</div>
			) : null}
			{localizedForm ? (
				<Form
					className="rjsf-form"
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO: better form library?
					formData={formData}
					onChange={(e) => {
						setFormData(e.formData)
					}}
					onSubmit={handleSubmit}
					schema={localizedForm}
					showErrorList={false}
					transformErrors={transformErrors}
					uiSchema={uiSchema}
					widgets={widgets}
				>
					<p
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO: better form library?
						dangerouslySetInnerHTML={{__html: uiSchema.description}}
					/>
					{errorText ? (
						<p className="rjsf-form-error">{errorText}</p>
					) : null}
					{block.elements.submitButton.map((button) => (
						<Button
							as="button"
							className="mt-6 max-w-xs"
							key={button.system.id}
							type="submit"
							variant={
								button.elements.variant[0]?.codename ??
								'primary'
							}
						>
							{button.elements.text}
						</Button>
					))}
				</Form>
			) : null}
		</div>
	)
}
