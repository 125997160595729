import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {
	AddButtonIconBlock,
	type AddButtonIconContentItem,
} from '../../add-button-icon'

export type HeadingActionsWrapperContentItem = IContentItem<{
	heading: Elements.TextElement
	ctaButton: Elements.LinkedItemsElement<AddButtonIconContentItem>
}>

export const HeadingActionsWrapperBlock: Block<
	HeadingActionsWrapperContentItem
> = ({
	block: {
		elements: {ctaButton, heading},
	},
	...context
}) => {
	return (
		<div className="container-wide my-6">
			<div className="flex items-center justify-between">
				<div>
					<h2 className="mb-2 text-2xl font-semibold text-midnight-blue">
						{heading}
					</h2>
				</div>
				<div className="flex">
					{ctaButton.map((item) => (
						<div className="mx-2" key={item.system.id}>
							<AddButtonIconBlock block={item} {...context} />
						</div>
					))}
				</div>
			</div>
		</div>
	)
}
