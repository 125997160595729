export function handleUTMQueryItem(
	item: string | string[] | undefined
): string | undefined {
	if (typeof item === 'string') {
		return item
	}
	if (Array.isArray(item)) {
		return item[0]
	}
	return ''
}
