import {Image} from '@elanco/component-library-v2'
import {clsx} from 'clsx'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {ExtendedBlock, Tersed} from '@/_new-code/services/kontent-ai/types'
import type {PetTraitContentItem} from '@/_new-code/products/pet-name-finder/models'

export type TraitsByType = Record<string, Tersed<PetTraitContentItem> | null>

export const PetTraitCardBlock: ExtendedBlock<
	PetTraitContentItem,
	{
		isActive: boolean
		onClick: (trait: Tersed<PetTraitContentItem>) => void
		unselected: boolean
	}
> = ({block, onClick, isActive, unselected}) => {
	return (
		<div
			className={clsx(
				'cursor-pointer text-center',
				unselected ? 'opacity-[40%]' : 'opacity-[100%]'
			)}
		>
			<button
				className="relative flex h-[164px] w-[134px] max-w-full select-none flex-col items-center justify-center gap-4 rounded-[25%] text-center"
				onClick={() => {
					onClick(block)
				}}
				type="button"
			>
				{block.elements.icon[0] ? (
					<Image
						alt={block.elements.displayName}
						height={134}
						loader={loadImageFromKontentAI}
						src={block.elements.icon[0].url}
						width={134}
					/>
				) : null}

				{isActive ? (
					<div className="absolute bottom-7 left-auto right-3 flex h-7 w-7 scale-[1.4] items-center justify-center rounded-[50%] bg-green-600">
						<div
							className="mb-[10%] inline-block h-[15px] w-2 border-b-[2.5px] border-r-[2.5px] border-b-white border-r-white"
							style={{transform: 'rotate(45deg)'}}
						/>
					</div>
				) : null}
			</button>
			<span className="font-bold">{block.elements.displayName}</span>
		</div>
	)
}
