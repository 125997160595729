import {PropsWithChildren, ReactNode, FC} from 'react'
import {useFormContext} from 'react-hook-form'
import {ErrorMessage} from '../Form'

interface ChoiceGroupProps {
	label: ReactNode
	name: string
	className?: string
	isOptional?: boolean
	requiredErrorMsg?: string
	groupClassName?: string
	labelClassName?: string
}

const ChoiceGroup: FC<PropsWithChildren<ChoiceGroupProps>> = ({
	className = '',
	groupClassName = '',
	labelClassName = '',
	children,
	name,
	label,
	isOptional = false,
	requiredErrorMsg = '',
}) => {
	if (!children) return null
	const {register, formState} = useFormContext()
	const requiredMsg = requiredErrorMsg || `${label} is required`
	return (
		<fieldset
			className={`mb-4 ${groupClassName}`}
			id={name}
			{...register(name)}
		>
			<h5
				className={`align-middle text-xs font-normal text-pet-primary ${labelClassName}`}
			>
				{isOptional ? (
					label
				) : (
					<>
						{label}
						<span className="text-red-600">*</span>
					</>
				)}
			</h5>
			<div className="relative">
				<div className={`md:flex md:flex-wrap ${className}`}>
					{children}
				</div>
				{formState.errors[name] && (
					<ErrorMessage message={requiredMsg} />
				)}
			</div>
		</fieldset>
	)
}

export default ChoiceGroup
