import {ButtonIcons, Button} from '@elanco/component-library-v2'
import Link from 'next/link'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useRouter} from 'next/router'
import {addLocalePrefix} from '@/components/BlockMapper/utils'
import type {CtaButtonContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'

export type ModuleCreatePetCtaBlockContentItem = IContentItem<{
	title: Elements.TextElement
	description: Elements.RichTextElement
	ctaButton: Elements.LinkedItemsElement<CtaButtonContentItem>
}>

export const ModuleCreatePetCtaBlockBlock: Block<
	ModuleCreatePetCtaBlockContentItem
> = ({
	block: {
		elements: {ctaButton, description, title},
	},
	...context
}): JSX.Element => {
	const {locale = ''} = useRouter()

	return (
		<div className="relative my-6 w-full space-y-4 rounded border border-light-grey bg-white px-8 py-12 md:max-w-[415px]">
			<h3 className="text-xl uppercase leading-6 text-midnight-blue">
				{title}
			</h3>
			<RichTextRenderer
				className="text-midnight-blue"
				element={description}
				{...context}
			/>
			{ctaButton.map((button) => (
				<Link
					href={addLocalePrefix(button.elements.url, locale)}
					key={button.system.id}
					passHref
				>
					<Button
						as="a"
						className="uppercase"
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- TODO: fix component library types
						icon={ButtonIcons[button.elements.icon[0]?.name || '']}
						title={button.elements.text}
						variant={button.elements.variant[0]?.codename}
					>
						{button.elements.text}
					</Button>
				</Link>
			))}
		</div>
	)
}
