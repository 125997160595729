import {useState} from 'react'
import {useRouter} from 'next/router'
import {Button} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import Form from '@/components/Form'
import Modal from '@/components/Modal'
import LoadingState from '@/components/Form/LoadingState'
import {addLocalePrefix} from '@/components/BlockMapper/utils'
import {pushToDataLayer} from '@/utils/analytics'
import Alert from '@/components/Alert'
import type {Pet} from '@/models/consumerEngagement/models'
import type {ExtendedBlock} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'
import type {ModuleMedicationReminderDeleteModalContentItem} from './delete-modal'
import {ModuleMedicationReminderDeleteModalBlock} from './delete-modal'
import type {FormData} from './hooks'
import {useMedicationReminderForm} from './hooks'

export type ModuleMedicationReminderModalContentItem = IContentItem<{
	title: Elements.TextElement
	editReminderTitle: Elements.TextElement
	fieldIsRequiredText: Elements.TextElement
	selectPetLabel: Elements.TextElement
	selectPetPlaceholder: Elements.TextElement
	selectMedicationLabel: Elements.TextElement
	selectMedicationPlaceholder: Elements.TextElement
	selectReminderDateLabel: Elements.TextElement
	selectAdvantagexdReminderDateLabel: Elements.TextElement
	selectSerestoReminderDateLabel: Elements.TextElement
	reminderTypePreferenceLabel: Elements.TextElement
	reminderTypePreferenceLabelGalliprant: Elements.TextElement
	emailOptionText: Elements.TextElement
	textOptionText: Elements.TextElement
	submitButtonText: Elements.TextElement
	editButtonText: Elements.TextElement
	skipButtonText: Elements.TextElement
	mandatoryFieldsText: Elements.TextElement
	phoneVerificationTitle: Elements.TextElement
	smsTermsAndConditionsHeading: Elements.TextElement
	smsTermsAndConditionsBody: Elements.RichTextElement
	medicationReminderDeleteModal: Elements.LinkedItemsElement<ModuleMedicationReminderDeleteModalContentItem>
	deleteReminderText: Elements.TextElement
	urlRedirect: Elements.TextElement
}>

export const ModuleMedicationReminderModalBlock: ExtendedBlock<
	ModuleMedicationReminderModalContentItem,
	{selectedPet?: Pet; redirectUrl?: string}
> = ({
	block,
	selectedPet,
	redirectUrl,
	globalConfig,
	BlockMapper,
	page,
}): JSX.Element => {
	const {locale = '', asPath} = useRouter()
	const {
		pets,
		marketingbrands,
		onPetChange,
		minDate,
		maxDate,
		handleFormSubmit,
		isLoading,
		displayModal,
		defaultPet,
		isProductSeresto,
		isProductGalliprant,
		isProductAdvantageXD,
		onMedicationChange,
		displayPhoneVerification,
		showTermsModal,
		closeTermsModal,
		checkSmsChannel,
		checkEmailChannel,
		defaultMedication,
		defaultDate,
		closeModal,
		setDisplayPhoneVerification,
		selectedPetName,
		currentRemindersIds,
		defaultValues,
		onReminderDateChange,
		showError,
	} = useMedicationReminderForm(
		'sms',
		locale,
		asPath,
		redirectUrl ?? '',
		selectedPet
	)

	const [showDeleteReminderModal, setShowDeleteReminderModal] =
		useState(false)
	const router = useRouter()
	const isEdit = Boolean(router.query.edit)

	if (asPath && !displayModal) {
		return <div />
	}
	const Wrapper = asPath ? Modal : 'div'

	let reminderDateLabel

	switch (true) {
		case isProductSeresto:
			reminderDateLabel =
				block.elements.selectSerestoReminderDateLabel.replace(
					'{petName}',
					selectedPetName
				)
			break
		case isProductAdvantageXD:
			reminderDateLabel =
				block.elements.selectAdvantagexdReminderDateLabel.replace(
					'{petName}',
					selectedPetName
				)
			break
		default:
			reminderDateLabel = block.elements.selectReminderDateLabel.replace(
				'{petName}',
				selectedPetName
			)
	}

	return (
		<Wrapper
			onClose={closeModal}
			size="lg"
			title={
				defaultMedication
					? block.elements.editReminderTitle
					: block.elements.title
			}
		>
			<div className="relative">
				{!isEdit && Wrapper === 'div' && (
					<h3 className="mb-6 text-center font-semibold">
						{block.elements.title}
					</h3>
				)}
				{isLoading ? (
					<div className="m-20 flex p-20">
						<LoadingState isLoading={isLoading} theme="green" />
					</div>
				) : null}
				{!isLoading && (
					<Form
						action=""
						ajaxSubmission
						className="text-left"
						onSubmit={(data: FormData) => {
							void handleFormSubmit(data)
						}}
						setFormField={defaultValues}
					>
						<div className="mb-8 text-center text-xs font-bold text-red-500">
							{block.elements.mandatoryFieldsText}
						</div>

						{!selectedPet && (
							<Form.SelectField
								defaultValue={defaultPet}
								disabled={isEdit}
								label={
									<span className="mr-1">
										{block.elements.selectPetLabel}
									</span>
								}
								name="petId"
								onChange={onPetChange}
								requiredErrorMsg={
									block.elements.fieldIsRequiredText
								}
							>
								<option value="">
									{block.elements.selectPetPlaceholder}
								</option>
								{pets.map((pet) => (
									<option key={pet.id} value={pet.id}>
										{pet.name}
									</option>
								))}
							</Form.SelectField>
						)}

						<Form.SelectField
							className="break-words"
							defaultValue={defaultMedication}
							disabled={isEdit}
							label={
								<span className="mr-1">
									{block.elements.selectMedicationLabel.replace(
										'{petName}',
										selectedPetName
									)}
								</span>
							}
							name="medication"
							onChange={onMedicationChange}
							requiredErrorMsg={
								block.elements.fieldIsRequiredText
							}
						>
							<option value="">
								{block.elements.selectMedicationPlaceholder}
							</option>
							{marketingbrands.map((product) => (
								<option key={product.id} value={product.id}>
									{product.name}
								</option>
							))}
						</Form.SelectField>

						{!isProductGalliprant && (
							<Form.DateField
								className="flex break-words"
								defaultValue={defaultDate}
								invalidErrorMsg="Please enter a valid date"
								label={reminderDateLabel}
								maxDate={maxDate || undefined}
								minDate={minDate || undefined}
								name="nextReminderDate"
								onChange={onReminderDateChange}
								requiredErrorMsg={
									block.elements.fieldIsRequiredText
								}
								type="date"
							/>
						)}

						<Form.ChoiceGroup
							label={
								isProductGalliprant
									? block.elements.reminderTypePreferenceLabelGalliprant.replace(
											'{petName}',
											selectedPetName
										)
									: block.elements.reminderTypePreferenceLabel.replace(
											'{petName}',
											selectedPetName
										)
							}
							name="remindersCommunicationPreference"
							requiredErrorMsg={
								block.elements.fieldIsRequiredText
							}
						>
							<Form.ChoiceField
								checked={checkEmailChannel}
								className="mb-0 w-full"
								id="email"
								isGroup
								label={block.elements.emailOptionText}
								name="remindersCommunicationPreference"
								type="checkbox"
								value="email"
							/>

							<Form.ChoiceField
								checked={checkSmsChannel}
								className={`mb-0 w-full ${
									isProductSeresto || isProductGalliprant
										? 'hidden'
										: ''
								}`}
								id="sms"
								isGroup
								label={block.elements.textOptionText}
								name="remindersCommunicationPreference"
								type="checkbox"
								value="sms"
							/>
						</Form.ChoiceGroup>

						{showError ? (
							<Alert
								message={globalConfig.elements.genericApiError}
								type="error"
							/>
						) : null}

						<div className="flex flex-col justify-center gap-4 pt-4 md:flex-row">
							{redirectUrl ? (
								<Button
									as="a"
									className="w-full flex-1 md:max-w-[245px]"
									data-btn-id="reminder-form-create-reminder"
									href={addLocalePrefix(redirectUrl, locale)}
									onClick={() => {
										pushToDataLayer({
											event: 'pet_profile',
											step_completed: '3',
											step_name: block.elements.title,
										})
									}}
									type="button"
									variant="inverted_pet_primary_green"
								>
									{block.elements.skipButtonText}
								</Button>
							) : null}

							<Button
								as="button"
								className="w-full flex-1 md:max-w-[245px]"
								data-btn-id="reminder-form-create-reminder"
								onClick={() => {
									pushToDataLayer({
										event: 'pet_profile',
										step_completed: '3',
										step_name: block.elements.title,
									})
								}}
								type="submit"
								variant="pet_primary_green"
							>
								{!defaultMedication
									? block.elements.submitButtonText
									: block.elements.editButtonText}
							</Button>
						</div>

						{defaultMedication ? (
							<div className="mt-4 flex justify-center">
								<button
									className="cursor-pointer text-green-600 underline"
									onClick={() => {
										setShowDeleteReminderModal(true)
									}}
									type="button"
								>
									{block.elements.deleteReminderText}
								</button>
							</div>
						) : null}
						{showDeleteReminderModal &&
						block.elements.medicationReminderDeleteModal[0] ? (
							<div>
								<ModuleMedicationReminderDeleteModalBlock
									BlockMapper={BlockMapper}
									block={
										block.elements
											.medicationReminderDeleteModal[0]
									}
									globalConfig={globalConfig}
									page={page}
									remindersIds={currentRemindersIds}
									selectedPet={selectedPet}
									setShowDeleteReminderModal={
										setShowDeleteReminderModal
									}
								/>
							</div>
						) : null}
					</Form>
				)}
			</div>

			{displayPhoneVerification ? (
				<Modal
					onClose={() => {
						setDisplayPhoneVerification(false)
					}}
					size="md"
				>
					<div
						className="relative flex min-h-[200px] min-w-[200px] justify-center"
						id="phone-form-test"
					>
						<LoadingState isLoading theme="green" />
					</div>

					{showTermsModal ? (
						<Modal onClose={closeTermsModal} size="sm">
							<div className="content content--no-width-restriction">
								<h3 className="py-4 text-center text-2xl">
									{
										block.elements
											.smsTermsAndConditionsHeading
									}
								</h3>
								<RichTextRenderer
									BlockMapper={BlockMapper}
									element={
										block.elements.smsTermsAndConditionsBody
									}
									globalConfig={globalConfig}
									page={page}
								/>
							</div>
						</Modal>
					) : null}
				</Modal>
			) : null}
		</Wrapper>
	)
}
