import {pushToDataLayer} from '@/utils/analytics'

interface ListerSortingProps {
	dropDownData: {value: string; label: string}[]
	label: string
	onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
	className?: string
	id: string
	codename?: string
}

export const ListerSorting = (props: ListerSortingProps): JSX.Element => {
	const {dropDownData, label, onChange, className, id, codename} = props
	return (
		<div className={className} data-kontent-item-id={id}>
			<select
				className="xl rounded bg-white text-black"
				data-kontent-element-codename={codename}
				defaultValue=""
				id={id}
				onChange={(e) => {
					onChange(e)
					pushToDataLayer({
						event: 'sorting_selected',
						sorting_name: e.target.value,
					})
				}}
			>
				<option disabled value="">
					<div
						dangerouslySetInnerHTML={{
							__html: label,
						}}
					/>
				</option>
				{dropDownData.map((data) => (
					<option key={data.value + id} value={data.value}>
						<div
							dangerouslySetInnerHTML={{
								__html: data.label,
							}}
						/>
					</option>
				))}
			</select>
		</div>
	)
}
