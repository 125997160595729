import {FC, PropsWithChildren} from 'react'
import FormWrapper, {FormProps} from './Form'
import TextareaField from './FormElements/TextareaField'
import ChoiceField from './FormElements/ChoiceField'
import ChoiceGroup from './FormElements/ChoiceGroup'
import InputField from './FormElements/InputField'
import SelectField from './FormElements/SelectField'
import SearchableSelectField from './FormElements/SearchableSelectField'
import DateField from './FormElements/DateField'
import FieldGroup from './FormElements/FieldGroup'

interface FormElements {
	InputField: typeof InputField
	SelectField: typeof SelectField
	SearchableSelectField: typeof SearchableSelectField
	ChoiceGroup: typeof ChoiceGroup
	ChoiceField: typeof ChoiceField
	TextareaField: typeof TextareaField
	DateField: typeof DateField
	FieldGroup: typeof FieldGroup
}

type FormWithElements = FC<PropsWithChildren<FormProps>> & FormElements

// @ts-expect-error throws type error relating to FormElements not optional
const Form: FormWithElements = FormWrapper

Form.InputField = InputField
Form.SelectField = SelectField
Form.SearchableSelectField = SearchableSelectField
Form.ChoiceGroup = ChoiceGroup
Form.ChoiceField = ChoiceField
Form.TextareaField = TextareaField
Form.DateField = DateField
Form.FieldGroup = FieldGroup

export default Form
