export const SkeletonLoader = ({amount}: {amount?: number}): JSX.Element => {
	const loaderElement = (
		<div className="h-96 w-full animate-pulse rounded border border-gray-200 p-4 shadow-md">
			<div className="mb-4 h-2.5 w-48 rounded-full bg-gray-200" />
			<div className="mb-4 h-48 rounded bg-gray-200" />
			<div className="mb-2.5 h-2 max-w-[360px] rounded-full bg-gray-200" />
			<div className="mb-2.5 h-2 rounded-full bg-gray-200" />
			<div className="mb-2.5 h-2 max-w-[330px] rounded-full bg-gray-200" />
			<div className="mb-2.5 h-2 max-w-[300px] rounded-full bg-gray-200" />
			<div className="mb-2.5 h-2 max-w-[360px] rounded-full bg-gray-200" />
			<div className="mb-2.5 h-2 max-w-[330px] rounded-full bg-gray-200" />
			<div className="mb-2.5 h-2 max-w-[300px] rounded-full bg-gray-200" />
			<span
				aria-busy="true"
				aria-live="polite"
				className="sr-only"
				role="alert"
			>
				Loading...
			</span>
		</div>
	)

	if (!amount) {
		return loaderElement
	}

	return (
		<>
			{Array(amount)
				.fill(1)
				.map(() => loaderElement)}
		</>
	)
}
