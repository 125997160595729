import type {ReactNode, ReactElement} from 'react'
import {Children, forwardRef, useEffect, useState} from 'react'
import Slider from 'react-slick'
import {clsx} from 'clsx'
import {pushToDataLayer} from '@/utils/analytics'

interface ArrowProps {
	className?: string
	style?: React.CSSProperties
	onClick?: () => void
	onPreClick?: (currentSlide: number) => void
	currentSlide: number
}

const Arrow = ({
	className,
	style,
	onClick,
	onPreClick,
	currentSlide,
}: ArrowProps): JSX.Element => {
	return (
		<div
			className={className}
			onClick={() => {
				if (onPreClick) onPreClick(currentSlide)
				if (onClick) onClick()
			}}
			role="presentation"
			style={{...style, cursor: 'pointer'}}
		/>
	)
}

interface CarouselItemProps {
	heading?: string
}

interface FullWidthCarouselProps {
	className?: string
	children: ReactNode
	stylesClass?: string
	hasHrBelow?: boolean
}

export const FullWidthCarousel = forwardRef<Slider, FullWidthCarouselProps>(
	(
		{
			className = '',
			children = null,
			stylesClass = 'verticalyCenteredArrows',
			hasHrBelow = false,
		},
		ref
	) => {
		const [isFocussed, setIsFocussed] = useState(true)
		const [currentSlide, setCurrentSlide] = useState(0)

		const settings = {
			autoplay: isFocussed,
			dots: true,
			infinite: true,
			slidesToScroll: 1,
			slidesToShow: 1,
			ref: null,
			speed: 500,
			autoplaySpeed: 2000,
			pauseOnFocus: true,
			pauseOnHover: true,
			afterChange: (index: number) => {
				setCurrentSlide(index)
			},
		}

		// Convert children to an array of React elements
		const childrenArray = Children.toArray(
			children
		) as ReactElement<CarouselItemProps>[]

		useEffect(() => {
			const handleTabkey = (event: KeyboardEvent): void => {
				if (event.key === 'Tab' && isFocussed) {
					setIsFocussed(false)
				}
			}
			window.addEventListener('keydown', handleTabkey)
			return () => {
				window.removeEventListener('keydown', handleTabkey)
			}
		}, [isFocussed])

		return (
			<>
				<Slider
					className={clsx(
						className,
						stylesClass,
						hasHrBelow ? 'hasHrBelow' : ''
					)}
					{...settings}
					nextArrow={
						<Arrow
							currentSlide={currentSlide}
							onPreClick={(cs) => {
								pushToDataLayer({
									event: 'carousel_click',
									carousel_name:
										childrenArray[
											(cs + 1) % childrenArray.length
										]?.props.heading ?? `image ${cs}`,
									carousel_action: 'Click Right',
								})
							}}
						/>
					}
					prevArrow={
						<Arrow
							currentSlide={currentSlide}
							onPreClick={(cs) => {
								pushToDataLayer({
									event: 'carousel_click',
									carousel_name:
										childrenArray[
											(cs - 1 + childrenArray.length) %
												childrenArray.length
										]?.props.heading ?? `image ${cs}`,
									carousel_action: 'Click Left',
								})
							}}
						/>
					}
					ref={ref}
				>
					{children}
				</Slider>
				{hasHrBelow ? (
					<hr className="border-b border-t-0 border-gray-200 pt-8" />
				) : null}
			</>
		)
	}
)

FullWidthCarousel.displayName = 'FullWidthCarousel'
