import type {FC, ReactNode} from 'react'
import {clsx} from 'clsx'

interface FeatureBlockProps {
	children?: ReactNode
	reversed?: boolean
	image: ReactNode
}

export const FeatureBlock: FC<FeatureBlockProps> = ({
	children = null,
	reversed = false,
	image,
}) => (
	<div className="container-wide relative">
		<div
			className={clsx(
				'absolute h-full w-full rounded-lg bg-gray-100 md:w-3/5',
				reversed && 'right-0'
			)}
		/>
		<div
			className={clsx(
				'z-1 relative items-start justify-between px-6 py-8 md:flex md:px-0 md:py-12',
				reversed
					? 'flex-row-reverse md:pr-10 lg:pr-20'
					: 'md:pl-10 lg:pl-20'
			)}
		>
			<div className="md:w-1/3">
				{children}
				{/* Mobile image */}
				<div className="absolute relative right-0 mb-6 mt-8 hidden overflow-hidden rounded-lg text-white md:hidden">
					<div className="z-10 mr-4 mt-4 text-center">{image}</div>
				</div>
			</div>

			{/* Desktop image */}
			<div className="absolute relative right-0 hidden overflow-hidden rounded-lg text-white md:block md:w-3/5">
				<div className="z-10 mr-4 mt-4 hidden text-center" />
				{image}
			</div>
		</div>
	</div>
)
