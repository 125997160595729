import {ArticleLister, MetaBlock} from '@elanco/component-library-v2'
import Image from 'next/future/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {MetaBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/meta-block'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import type {CtaButtonContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'
import {ReusableCTAButtonComponent} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'

export type ArticleListerBlockLargeContentItem = IContentItem<{
	title: Elements.TextElement
	subtitle: Elements.TextElement
	description: Elements.TextElement
	url: Elements.TextElement
	openInNewTab: Elements.MultipleChoiceElement
	snippetLayoutBackgroundColorColor: Elements.MultipleChoiceElement
	hasWhiteText: Elements.MultipleChoiceElement
	metaBlock: Elements.LinkedItemsElement<MetaBlockContentItem>
	image: Elements.AssetsElement
	ctaButton: Elements.LinkedItemsElement<CtaButtonContentItem>
}>

export const ArticleListerBlockLarge: Block<
	ArticleListerBlockLargeContentItem
> = ({block, ...context}) => {
	const metaBlock = block.elements.metaBlock[0]
	const metaBlockImage = metaBlock?.elements.image[0]
	const metaBlockAlignment =
		metaBlock?.elements.horizontalAlignment[0]?.codename
	const snippetColor =
		block.elements.snippetLayoutBackgroundColorColor[0]?.codename ?? 'white'
	const textColor =
		block.elements.hasWhiteText[0]?.codename === 'yes' ? 'white' : 'black'

	return (
		<ArticleLister
			className={clsx(
				'max-w-4xl !shadow-around',
				`text-${textColor}`,
				`bg-${snippetColor
					.replace(
						/(?<lowercaseletters>[a-z])(?<uppercaseletters>[A-Z])/g,
						'$1-$2'
					)
					.replace(/[\s_]+/g, '-')}`
			)}
			description={
				<p data-kontent-element-codename="description">
					{block.elements.description}
				</p>
			}
			imageMetablock={
				metaBlock ? (
					<MetaBlock
						aligned={metaBlockAlignment || 'left'}
						blur
						image={
							metaBlockImage ? (
								<Image
									alt={metaBlockImage.description || ''}
									height={metaBlockImage.height || 40}
									loader={loadImageFromKontentAI}
									src={metaBlockImage.url}
									width={metaBlockImage.width || 166}
								/>
							) : null
						}
						isExternalLink={
							metaBlock.elements.openInNewTab[0]?.codename ===
							'yes'
						}
						metaBlockHref={metaBlock.elements.url}
					>
						{metaBlock.elements.title}
					</MetaBlock>
				) : null
			}
			imageUrl={block.elements.image[0]?.url}
			isExternalLink={block.elements.openInNewTab[0]?.codename === 'yes'}
			subtitle={
				<span data-kontent-element-codename="subtitle">
					{block.elements.subtitle}
				</span>
			}
			title={
				block.elements.title ? (
					<span data-kontent-element-codename="title">
						{block.elements.title}
					</span>
				) : null
			}
		>
			{block.elements.ctaButton.map((button, index, arr) => (
				<ReusableCTAButtonComponent
					block={button}
					className={`mt-6 w-full max-w-xs ${
						arr.length > 1 && index !== arr.length - 1
							? 'sm:mr-6'
							: ''
					}`}
					key={button.system.id}
					{...context}
				/>
			))}
		</ArticleLister>
	)
}
