export const askAnExpert = {
	formSchema: {
		default: {
			type: 'object',
			required: [
				'firstName',
				'lastName',
				'emailAddress',
				'postcode',
				'query',
			],
			properties: {
				firstName: {
					type: 'string',
					title: 'First Name',
				},
				lastName: {
					type: 'string',
					title: 'Last Name',
				},
				emailAddress: {
					type: 'string',
					title: 'Email address',
					pattern: '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$',
				},
				telephone: {
					type: 'string',
					title: 'Contact number',
					pattern: '^(?:0|\\+?44)(?:\\ds?){9,10}$',
				},
				postcode: {
					type: 'string',
					title: 'Postcode',
					pattern:
						'^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$',
				},
				query: {
					type: 'string',
					title: 'Query',
				},
			},
		},
	},
	uiSchema: {
		'ui:rootFieldId': 'askAnExpert',
		query: {
			'ui:widget': 'textarea',
			'ui:options': {
				rows: 4,
			},
			'ui:placeholder': 'Enter your query here...',
		},
	},
}
