import type {Tersed} from '@/_new-code/services/kontent-ai/types'
import {
	RENDER_MODE_DROP_DOWN,
	RENDER_MODE_RADIO_BUTTON,
} from '@/_new-code/products/campaign-forms/cdc-screen-set-wrapper/model-as-code/single-selection-question.mac'
import type {
	SharedQuestionProps,
	SingleSelectionContentItem,
} from '@/_new-code/products/campaign-forms/cdc-screen-set-wrapper/models'
import {SingleSelectDropdown} from './single-select-dropdown'
import {SingleSelectRadioButtons} from './single-select-radio-buttons'

interface SingleSelectionQuestionProps extends SharedQuestionProps {
	question: Tersed<SingleSelectionContentItem>
}

export const SingleSelectionQuestion = ({
	question,
	selectedOptionsCallback,
	injectedElementsCallback,
	errorsCallback,
	validationEvent,
}: SingleSelectionQuestionProps): JSX.Element => {
	if (question.elements.selections.length === 0) {
		return <div />
	}

	switch (question.elements.renderMode[0]?.codename) {
		case RENDER_MODE_DROP_DOWN:
			return (
				<SingleSelectDropdown
					errorsCallback={errorsCallback}
					injectedElementsCallback={injectedElementsCallback}
					question={question}
					selectedOptionsCallback={selectedOptionsCallback}
					validationEvent={validationEvent}
				/>
			)
		case RENDER_MODE_RADIO_BUTTON:
			return (
				<SingleSelectRadioButtons
					errorsCallback={errorsCallback}
					injectedElementsCallback={injectedElementsCallback}
					question={question}
					selectedOptionsCallback={selectedOptionsCallback}
					validationEvent={validationEvent}
				/>
			)
		default:
			return <div />
	}
}
