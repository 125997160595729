import {Anchor as AComp} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'

export type AnchorContentItem = IContentItem<{
	anchorId: Elements.TextElement
	scrollMarginTopDesktop: Elements.TextElement
	scrollMarginTopMobile: Elements.TextElement
}>

export const AnchorBlock: Block<AnchorContentItem> = ({block}) => {
	return (
		<AComp
			anchorLink={block.elements.anchorId}
			data-kontent-element-codename="anchor_id"
			scrollMarginTop={{
				desktop: block.elements.scrollMarginTopDesktop || '8rem',
				mobile: block.elements.scrollMarginTopMobile || '4rem',
			}}
		/>
	)
}
