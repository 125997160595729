import dynamic from 'next/dynamic'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import type {ClickToBuyMultiProductContentItem} from './click-to-buy-multi-product'

const ClickToBuyMultiProductComponent = dynamic(
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error -- Necessary to fix build
	// @ts-ignore -- Necessary to fix build
	() =>
		import('./click-to-buy-multi-product').then(
			(mod) => mod.ClickToBuyMultiProduct
		),
	{
		ssr: false,
	}
)

export const ClickToBuyMultiProductBlock: Block<
	ClickToBuyMultiProductContentItem
> = (props) => {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error -- Necessary to fix build
	// @ts-ignore -- Necessary to fix build
	return <ClickToBuyMultiProductComponent {...props} />
}
