import {MarketingBrand, Pet} from '@/models/consumerEngagement/models'
import {addRecurring} from '@/utils/YPAY/commonFunctions'
import http from '@/utils/axios'

export type Channel = 'email' | 'sms'

export interface Reminder {
	pet?: Pet
	petId?: string
	productId?: string
	product?: unknown
	marketingBrand?: MarketingBrand
	id: string
	cdcPetOwnerId: string
	startDate: string
	channel: Channel
	status: 'active' | 'removed'
	lastModified: string
	isDeleted: boolean
	dosesRemaining: number
	autoship?: boolean
	locale?: string
	recurringDates?: string[]
	pastDates?: string[]

	date: string
	serestoRefillDate: unknown[]
}

export const fetchPetReminders = async (
	locale: string
): Promise<Reminder[]> => {
	const endpoint = `/api/reminders?locale=${locale}`
	const {data} = await http(endpoint)
	const remindersData = addRecurring(data, 12, 1)

	return remindersData?.data
}
