import {QueryFunctionContext} from 'react-query'
import http from '@/utils/axios'
import {ErrorResponse} from '@/models/ResponseType'

interface IParams {
	pageId: string
	locale: string
	pageUrl: string
}

interface ICrumb {
	id: string
	url: string
	title: string
	breadcrumbText: string
}

export type TBreadcrumbs = ICrumb[]

export type TBreadcrumbsResponse = ICrumb[] | ErrorResponse

export const fetchBreadcrumbs = async (
	context: QueryFunctionContext
): Promise<TBreadcrumbsResponse | null> => {
	const {pageId, locale, pageUrl} = context.queryKey[1] as IParams
	const isTaxonomyTermPage = /taxonomy\/term/.test(pageUrl)
	if (isTaxonomyTermPage) {
		// Return null to prevent breadcrumbs from being displayed for taxonomy term pages since they does not exist in kontent.ai
		return null
	}
	const endpoint = `/api/breadcrumbs?pageId=${pageId}&locale=${locale}&pageUrl=${pageUrl}`
	const {data} = await http<TBreadcrumbsResponse>(endpoint)
	return data
}
