import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {
	SearchHeaderBlockBlock,
	type SearchHeaderBlockContentItem,
} from '../search-header-block'
import {
	ShowSearchResultsBlock,
	type ShowSearchResultsContentItem,
} from '../show-search-results'

export type SearchModuleContentItem = IContentItem<{
	searchHeaderBlock: Elements.LinkedItemsElement<SearchHeaderBlockContentItem>
	showSearchResults: Elements.LinkedItemsElement<ShowSearchResultsContentItem>
}>

export const SearchModuleBlock: Block<SearchModuleContentItem> = ({
	block,
	...context
}) => {
	return (
		<div>
			{block.elements.searchHeaderBlock[0] ? (
				<SearchHeaderBlockBlock
					block={block.elements.searchHeaderBlock[0]}
					{...context}
				/>
			) : null}
			{block.elements.showSearchResults[0] ? (
				<ShowSearchResultsBlock
					block={block.elements.showSearchResults[0]}
					{...context}
				/>
			) : null}
		</div>
	)
}
