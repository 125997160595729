import {Faq, FaqItem, Image} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import Script from 'next/script'
import {spacing} from '@/_new-code/products/flexible-web-toolkit/styles'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block, Tersed} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'
import type {CtaButtonContentItem} from '../cta-button'
import {ReusableCTAButtonComponent} from '../cta-button'
import type {VideoBlockContentItem} from '../videos/video-block'
import {VideoBlockBlock as YouTubeBlock} from '../videos/video-block'
import type {WistiaContentItem} from '../videos/wistia'
import {WistiaBlock} from '../videos/wistia'

export type FaqItemContentItem = IContentItem<{
	title: Elements.TextElement
	content: Elements.RichTextElement
	imageUrl: Elements.AssetsElement
	faqItemButtons: Elements.LinkedItemsElement<CtaButtonContentItem>
	config: Elements.LinkedItemsElement // TODO: restrict to FAQ config
	video: Elements.LinkedItemsElement<
		WistiaContentItem | VideoBlockContentItem
	>
	fullWidthImage: Elements.MultipleChoiceElement
}>

export type FaqContentItem = IContentItem<{
	faqItems: Elements.LinkedItemsElement<FaqItemContentItem>
	expandIconColor: Elements.MultipleChoiceElement
	snippetSpacingSpacing: Elements.MultipleChoiceElement
}>

export const FaqBlock: Block<FaqContentItem> = ({block, ...context}) => {
	const componentSpacing =
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not unnecessary as snippetSpacingSpacing may be undefined
		spacing[
			block.elements.snippetSpacingSpacing[0]
				?.codename as keyof typeof spacing
		] || spacing.none
	const iconColor =
		block.elements.expandIconColor[0]?.codename === 'beige'
			? '#D5C4AF'
			: '#000000'

	const faqStructuredData = {
		'@context': 'https://schema.org',
		'@type': 'FAQPage',
		mainEntity: block.elements.faqItems.map((item) => ({
			'@type': 'Question',
			name: item.elements.title,
			acceptedAnswer: {
				'@type': 'Answer',
				text: item.elements.content.value,
			},
		})),
	}

	return (
		<div className={componentSpacing}>
			<Script
				dangerouslySetInnerHTML={{
					__html: JSON.stringify(faqStructuredData),
				}}
				id="faq-schema"
				type="application/ld+json"
			/>
			<Faq
				className="container-narrow faq-block"
				data-kontent-element-codename="faq_items"
			>
				{block.elements.faqItems.map((item) => (
					<FaqItem
						data-kontent-element-codename="faq_item"
						iconColor={iconColor}
						key={item.system.id}
						title={item.elements.title}
					>
						<div
							className={clsx(
								'mt-6',
								item.elements.fullWidthImage[0]?.codename ===
									'yes'
									? 'flex-col'
									: 'flex'
							)}
						>
							{item.elements.imageUrl[0] ? (
								<div
									className={clsx(
										'overflow-hidden',
										item.elements.fullWidthImage[0]
											?.codename === 'yes'
											? 'mb-5 w-full'
											: 'w-1/2'
									)}
								>
									<Image
										className="pr-2"
										data-kontent-element-codename="image_url"
										height={
											item.elements.imageUrl[0].height ||
											200
										}
										loader={loadImageFromKontentAI}
										src={item.elements.imageUrl[0].url}
										width={
											item.elements.imageUrl[0].width ||
											200
										}
									/>
								</div>
							) : null}
							<RichTextRenderer
								className={clsx(
									'content faq-content text-base text-black',
									item.elements.imageUrl[0]
										? 'w-1/2 pl-2'
										: ''
								)}
								element={item.elements.content}
								{...context}
							/>
						</div>
						{item.elements.faqItemButtons.map((button) => (
							<ReusableCTAButtonComponent
								block={button}
								className="m-auto mr-6 mt-6"
								key={button.system.id}
								{...context}
							/>
						))}
						{item.elements.video.map((v) =>
							v.system.type === 'video_block' ? (
								<YouTubeBlock
									block={v as Tersed<VideoBlockContentItem>} // Assertion is correct as it is narrowed by system.type
									key={v.system.id}
									{...context}
								/>
							) : (
								<WistiaBlock
									block={v as Tersed<WistiaContentItem>} // Assertion is correct as it is narrowed by system.type
									key={v.system.id}
									{...context}
								/>
							)
						)}
					</FaqItem>
				))}
			</Faq>
		</div>
	)
}
