/* eslint-disable jsx-a11y/anchor-is-valid -- TODO: @vercel/style-guide is using a newer version that is not compatible with Next.js v12 */
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import Link from 'next/link'
import Image from 'next/future/image'
import {useRouter} from 'next/router'
import type {Block, Tersed} from '@/_new-code/services/kontent-ai/types'
import type {IconContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/icon'
import {loadImageFromKontentAI} from '@/imageLoader'
import {Icon} from '../../components/common/icon'

export type TileWithIconContentItem = IContentItem<{
	icon: Elements.LinkedItemsElement<IconContentItem>
	primaryText: Elements.TextElement
	secondaryText: Elements.TextElement
	url: Elements.TextElement
	openInNewTab: Elements.MultipleChoiceElement
}>

export const TileWithIconBlock: Block<TileWithIconContentItem> = ({block}) => {
	const {locale = ''} = useRouter()
	const shouldOpenInNewTab =
		block.elements.openInNewTab[0]?.codename === 'yes'
	const isLinkRelative = block.elements.url.startsWith('/')
	const href = isLinkRelative
		? `/${locale}${block.elements.url}`
		: block.elements.url

	if (isLinkRelative) {
		return (
			<li className="grid place-items-center rounded-md border border-[#ccc]">
				<Link href={href}>
					<a className="h-full w-full p-6">
						<TileContent block={block} />
					</a>
				</Link>
			</li>
		)
	}

	return (
		<li className="grid place-items-center rounded-md border border-[#ccc]">
			{shouldOpenInNewTab ? (
				<a
					className="h-full w-full p-6"
					href={href}
					rel="noreferrer"
					target="_blank"
				>
					<TileContent block={block} />
				</a>
			) : (
				<a className="h-full w-full p-6" href={href}>
					<TileContent block={block} />
				</a>
			)}
		</li>
	)
}

const TileContent: React.FC<{block: Tersed<TileWithIconContentItem>}> = ({
	block,
}) => (
	<>
		{block.elements.icon.map((icon) => (
			<Icon
				className="mx-auto mb-2 h-8 w-8"
				key={icon.system.id}
				noBackground
				noBorder
			>
				<Image
					alt={icon.elements.icon[0]?.description || ''}
					className="h-8 w-8"
					height={32}
					loader={loadImageFromKontentAI}
					src={icon.elements.icon[0]?.url ?? ''}
					width={32}
				/>
			</Icon>
		))}
		<h4 className="text-center text-midnight-blue">
			{block.elements.primaryText}
		</h4>
		{block.elements.secondaryText ? (
			<p className="text-center text-sm text-[#999]">
				{block.elements.secondaryText}
			</p>
		) : null}
	</>
)
