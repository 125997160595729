import {FC, useEffect, ReactNode} from 'react'
import {useFormContext, Validate} from 'react-hook-form'
import {ErrorMessage} from '../Form'

interface InputFieldProps {
	type: string
	label: ReactNode
	name: string
	className?: string
	isOptional?: boolean
	onChange?: (value: string) => void
	maxLength?: number
	requiredErrorMsg?: string
	invalidErrorMsg?: string
	onValidate?: Validate<any> | Record<string, Validate<any>> | undefined
	defaultValue?: string | number
	disabled?: boolean
	min?: number
	max?: number
}

const InputField: FC<InputFieldProps> = ({
	defaultValue = '',
	className = '',
	type = 'text',
	label,
	name,
	isOptional = false,
	requiredErrorMsg = '',
	invalidErrorMsg = '',
	onValidate = undefined,
	maxLength = 100,
	onChange = () => {},
	disabled = false,
	min = 0,
	max = 100,
}) => {
	const requiredMsg = requiredErrorMsg || `${label} is required`
	const invalidMsg = invalidErrorMsg || `Please enter a valid ${label}`
	const {register, trigger, formState} = useFormContext()
	useEffect(() => {
		let ignore = false

		const validation = async () => {
			if (defaultValue && !ignore) {
				await trigger(name)
			}
		}

		validation()

		return () => {
			ignore = true
		}
	}, [defaultValue])

	const {
		onChange: libOnChange,
		onBlur: libOnBlur,
		...registerProps
	} = register(name, {
		validate: onValidate,
		required: !isOptional,
		...(type === 'email' && {
			pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
		}),
		...(type === 'tel' && {
			pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
		}),
		...(type === 'number' && {
			min,
			max,
		}),
	})

	return (
		<div className={`relative mb-4 ${className}`}>
			<label htmlFor={name} className="w-full text-xs text-pet-primary">
				<span>
					{label}
					{!isOptional && <span className="text-red-600">*</span>}
				</span>
				<div className="relative mt-2">
					<input
						{...registerProps}
						onChange={(e) => {
							libOnChange(e)
							onChange(e.target.value)
						}}
						onBlur={(e) => {
							libOnBlur(e)
							onChange((e.target.value = e.target.value.trim()))
						}}
						defaultValue={defaultValue}
						disabled={disabled}
						type={type}
						id={name}
						name={name}
						className={`${
							formState.errors[name] ? 'invalid' : ''
						} text-sky-900 rounded-md !bg-white py-3 text-sm font-normal text-pet-primary`}
						maxLength={maxLength}
						min={min}
						max={max}
					/>
					{formState.errors[name] && (
						<ErrorMessage
							message={
								formState.errors[name]?.type === 'required'
									? requiredMsg
									: invalidMsg
							}
						/>
					)}
				</div>
			</label>
		</div>
	)
}

export default InputField
