import {Visual, Image} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import type {CtaButtonContentItem} from '../cta-button'
import {ReusableCTAButtonComponent} from '../cta-button'

export type DistributorCardContentItem = IContentItem<{
	title: Elements.TextElement
	description: Elements.RichTextElement
	image: Elements.AssetsElement
	button: Elements.LinkedItemsElement<CtaButtonContentItem>
	imageAlignment: Elements.MultipleChoiceElement
}>

export const DistributorCardBlock: Block<DistributorCardContentItem> = ({
	block: {
		elements: {
			button,
			description,
			image: [image],
			imageAlignment,
			title,
		},
	},
	...context
}) => {
	return (
		<Visual reversed={imageAlignment[0]?.codename === 'left'}>
			<h3>{title}</h3>
			<div
				className="py-6"
				dangerouslySetInnerHTML={{__html: description.value}}
			/>
			{button.map((b) => (
				<ReusableCTAButtonComponent
					block={b}
					key={b.system.id}
					{...context}
				/>
			))}
			{image ? (
				<Image
					alt={image.description}
					height={image.height ?? 300}
					loader={loadImageFromKontentAI}
					src={image.url}
					width={image.width ?? 500}
				/>
			) : null}
		</Visual>
	)
}
