export const lungwormVet = {
	formSchema: {
		default: {
			type: 'object',
			required: [
				'firstName',
				'lastName',
				'practiceName',
				'practicePostcode',
				'emailAddress',
				'country',
				'checkbox',
				'caseDate',
			],
			properties: {
				firstName: {
					type: 'string',
					title: 'First name',
				},
				lastName: {
					type: 'string',
					title: 'Last name',
				},
				practiceName: {
					type: 'string',
					title: 'Name of practice/business',
				},
				country: {
					type: 'string',
					title: 'Country',
					enum: ['United Kingdom', 'Republic of Ireland'],
				},
				practicePostcode: {
					type: 'string',
					title: 'Practice/business postcode',
					pattern:
						'^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$',
				},
				telephone: {
					type: 'string',
					title: 'Contact number',
					pattern: '^(?:0|\\+?44)(?:\\ds?){9,10}$',
				},
				emailAddress: {
					type: 'string',
					title: 'Email address',
					pattern: '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$',
				},
				caseOutcome: {
					type: 'string',
					title: 'Outcome of case',
					enum: ['Full Recovery', 'Ongoing', 'Fatal'],
				},
				diagnosisConfirmed: {
					type: 'string',
					title: 'Was a diagnosis of lungworm (A.vasorum) confirmed?',
					enum: ['Confirmed', 'Suspected'],
				},
				caseDate: {
					type: 'string',
					title: 'Date of admission',
					format: 'date',
				},
				checkbox: {
					type: 'boolean',
					title: 'I understand that Elanco will pass on my details to my vet practice in order to verify my case before it is published on the map.',
				},
			},
			dependencies: {
				country: {
					oneOf: [
						{
							properties: {
								country: {
									enum: ['United Kingdom'],
								},
								practicePostcode: {
									type: 'string',
									title: 'Practice/business postcode',
									pattern:
										'^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$',
								},
							},
						},
						{
							properties: {
								country: {
									enum: ['Republic of Ireland'],
								},
								practicePostcode: {
									type: 'string',
									title: 'Practice/business eircode',
									pattern:
										'^([AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$',
								},
							},
						},
					],
				},
			},
		},
		en_gb: {
			type: 'object',
			required: [
				'firstName',
				'lastName',
				'practiceName',
				'practicePostcode',
				'emailAddress',
				'country',
				'caseDate',
				'caseOutcome',
			],
			properties: {
				firstName: {
					type: 'string',
					title: 'First Name',
				},
				lastName: {
					type: 'string',
					title: 'Last Name',
				},
				practiceName: {
					type: 'string',
					title: 'Name of practice',
				},
				country: {
					type: 'string',
					title: 'Country',
					enum: ['United Kingdom', 'Republic of Ireland'],
				},
				practicePostcode: {
					type: 'string',
					title: 'Practice postcode',
					pattern:
						'^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$',
				},
				telephone: {
					type: 'string',
					title: 'Contact number',
					pattern: '^(?:0|\\+?44)(?:\\ds?){9,10}$',
				},
				emailAddress: {
					type: 'string',
					title: 'Email address',
					pattern: '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$',
				},
				caseOutcome: {
					type: 'string',
					title: 'Outcome of case',
					enum: ['Full Recovery', 'Ongoing', 'Fatal'],
				},
				diagnosisConfirmed: {
					type: 'string',
					title: 'Diagnosis of lungworm (A. vasorum)?',
					enum: ['Confirmed', 'Suspected'],
				},
				caseDate: {
					type: 'string',
					title: 'Date of admission to practice',
					format: 'date',
				},
			},
			dependencies: {
				country: {
					oneOf: [
						{
							properties: {
								country: {
									enum: ['United Kingdom'],
								},
								practicePostcode: {
									type: 'string',
									title: 'Practice postcode',
									pattern:
										'^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$',
								},
							},
						},
						{
							properties: {
								country: {
									enum: ['Republic of Ireland'],
								},
								practicePostcode: {
									type: 'string',
									title: 'Practice eircode',
									pattern:
										'^([AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$',
								},
							},
						},
					],
				},
			},
		},
	},
	uiSchema: {
		'ui:rootFieldId': 'vet',
		'ui:title': 'To be completed by vet practice',
		'ui:order': [
			'firstName',
			'lastName',
			'practiceName',
			'country',
			'practicePostcode',
			'telephone',
			'emailAddress',
			'caseOutcome',
			'diagnosisConfirmed',
			'caseDate',
			'checkbox',
		],
		description:
			'For Elanco’s contact details, data protection officer, the purposes and legal basis for our processing of your personal data, and how we use, share, or transfer your personal data, please see our <a href="https://privacy.elanco.com/en-us">Privacy Notice</a>.',
	},
}
