import type {ReactElement} from 'react'
import {
	useState,
	useEffect,
	useRef,
	cloneElement,
	isValidElement,
	Children,
} from 'react'
import {Button, EllipseButton} from '@elanco/component-library-v2'
import {clsx} from 'clsx'
import {pushToDataLayer} from '@/utils/analytics'
import {
	CheckMarkIcon,
	CheckMarkStarIcon,
	CrossMarkIcon,
	CrossMarkStarIcon,
	StarShapedIcon,
} from '@/_new-code/components/icons/icons'
import {ButtonIcons} from '../../../../components/button-icons/button-icon'
import {AnswerOption} from './answer-option'
import {Question} from './question'

interface QuizProps {
	introPageContent?: React.ReactNode
	introPageButtonText?: string
	questions: QuizQuestion[]
	className?: string
	isCertification?: boolean
	noFeedbackPerQuestion?: boolean
	result: React.ReactNode
	failedResult: React.ReactNode | null
	passPercent?: number
	showUserScore?: boolean
	correctOptionFeedbackTitle?: string
	wrongOptionFeedbackTitle?: string
	wrongOptionTooltip?: string
	shareNotes: ReactElement | null
	prevButtonText?: string
	nextButtonText?: string
	userCorrectAnswersCountText?: string
	facebookShareUrl?: string
	twitterShareUrl?: string
	linkedinShareUrl?: string
	hideShareIcons?: boolean
	hideProgressBar?: boolean
}

interface QuizQuestion {
	id: string
	questionText: React.ReactNode
	options: QuizOption[]
	feedback: React.ReactNode
}

interface QuizOption {
	optionText: string
	isCorrect: boolean
}

interface UserAnswer {
	id: string | undefined
	answerText: string
}

export const Quiz = ({
	introPageContent = null,
	introPageButtonText = 'Example Button',
	questions,
	className = '',
	isCertification = false,
	noFeedbackPerQuestion = false,
	result,
	failedResult = null,
	passPercent = 80,
	showUserScore = false,
	correctOptionFeedbackTitle = '',
	wrongOptionFeedbackTitle = '',
	wrongOptionTooltip = '',
	shareNotes,
	prevButtonText = 'Prev',
	nextButtonText = 'Next',
	userCorrectAnswersCountText = 'You got {0} out of {1} correct',
	facebookShareUrl = '',
	twitterShareUrl = '',
	linkedinShareUrl = '',
	hideShareIcons = false,
	hideProgressBar = false,
}: QuizProps): JSX.Element => {
	const hasIntroPageContent = introPageContent !== null
	const [showIntroPage, setShowIntroPage] = useState(hasIntroPageContent)
	const oldShowIntroPage = useRef(showIntroPage)
	const [counter, setCounter] = useState(1)
	const [lastQuestionNumber, setLastQuestionNumber] = useState(1)
	const [prevButtonDisabled, setPrevButtonDisabled] = useState(!showIntroPage)
	const [nextButtonDisabled, setNextButtonDisabled] = useState(true)
	const [showResults, setShowResults] = useState(false)
	const [questionId, setQuestionId] = useState<string | undefined>(
		questions[counter - 1]?.id
	)
	const prevQuestionId = useRef(questionId)
	const [questionText, setQuestionText] = useState(
		questions[counter - 1]?.questionText
	)
	const [answerOptions, setAnswerOptions] = useState(
		questions[counter - 1]?.options
	)
	const [showQuestionFeedback, setShowQuestionFeedback] = useState(false)
	const prevShowQuestionFeedback = useRef(showQuestionFeedback)
	const [hasUserAnsweredCorrect, setHasUserAnsweredCorrect] = useState(false)
	const [userCorrectAnswersCount, setUserCorrectAnswersCount] = useState(0)
	const [userAnswers, setUserAnswers] = useState<UserAnswer[]>([])
	const [hasUserPassed, setHasUserPassed] = useState(false)
	const [selectedAnswer, setSelectedAnswer] = useState<string>('')
	const [invalidAnswer, setInvalidAnswer] = useState<string>('')

	useEffect(() => {
		const userAnswer = userAnswers.find((x) => x.id === questionId)
		if (userAnswer) {
			setSelectedAnswer(userAnswer.answerText)
		} else {
			setSelectedAnswer('')
		}
	}, [questionId, userAnswers])

	const total = questions.length
	const listOfCorrectAnswers = questions.map((q) => ({
		id: q.id,
		answerText: q.options.find((o) => o.isCorrect)?.optionText || '',
	}))

	const noShareIcons =
		(!facebookShareUrl && !twitterShareUrl && !linkedinShareUrl) ||
		hideShareIcons

	const isReactElement = (value: unknown): value is ReactElement => {
		return (
			typeof value === 'object' && value !== null && isValidElement(value)
		)
	}
	const isButtonElement = (
		element: React.ReactNode
	): element is ReactElement<{'data-reset-quiz': boolean}> => {
		return (
			isReactElement(element) &&
			element.type === Button &&
			'data-reset-quiz' in element.props
		)
	}

	let retakeQuizButton: React.ReactElement | undefined
	let failedResultContent: React.ReactNode | null = null

	if (failedResult && isReactElement(failedResult)) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- no other option
		const children = Children.toArray(failedResult.props.children)
		retakeQuizButton = children.find(isButtonElement) as
			| React.ReactElement
			| undefined
		failedResultContent = children.filter(
			(child) => child !== retakeQuizButton
		)
	}

	const resetQuiz = (): void => {
		setShowIntroPage(hasIntroPageContent)
		setCounter(1)
		setLastQuestionNumber(1)
		setPrevButtonDisabled(!hasIntroPageContent)
		setNextButtonDisabled(true)
		setShowResults(false)
		setQuestionId(questions[0]?.id)
		setQuestionText(questions[0]?.questionText)
		setAnswerOptions(questions[0]?.options)
		setShowQuestionFeedback(false)
		setHasUserAnsweredCorrect(false)
		setUserCorrectAnswersCount(0)
		setUserAnswers([])
		setHasUserPassed(false)
		setSelectedAnswer('')
		setInvalidAnswer('')
	}

	const setDefaultCheckedValue = (): void => {
		const userAnswer = userAnswers.find((x) => x.id === questionId)
		if (!userAnswer) {
			return
		}
		setSelectedAnswer(userAnswer.answerText)
	}

	const handleAnswerSelected = (
		e: React.ChangeEvent<HTMLInputElement>,
		option: QuizOption
	): void => {
		setSelectedAnswer(option.optionText)
		const userAnswer = userAnswers.find((x) => x.id === questionId)
		if (userAnswer === undefined) {
			setUserAnswers(
				userAnswers.concat({
					id: questionId,
					answerText: option.optionText,
				})
			)
		} else {
			setUserAnswers(
				userAnswers.map((ans) =>
					ans.id === questionId
						? {...ans, answerText: option.optionText}
						: ans
				)
			)
		}

		const isCorrectOption = option.isCorrect
		if (!isCertification) {
			setNextButtonDisabled(false)
			setHasUserAnsweredCorrect(isCorrectOption)
			return
		}

		// Reset all inputs and remove existing error states
		setInvalidAnswer(isCorrectOption ? '' : option.optionText)
		setNextButtonDisabled(!isCorrectOption)

		if (!isCorrectOption) {
			e.currentTarget.classList.add('invalid')
			setNextButtonDisabled(true)
		} else {
			setNextButtonDisabled(false)
		}
	}
	const handleCtaClick = (buttonLabel?: string): void => {
		if (buttonLabel) {
			pushToDataLayer({
				event: 'cta_click',
				cta_name: buttonLabel,
				cta_category: 'CTA_Button',
			})
		}
	}

	const handleNextButtonClick = (buttonLabel?: string): void => {
		handleCtaClick(buttonLabel)
		setPrevButtonDisabled(false)
		if (!isCertification && !noFeedbackPerQuestion) {
			setShowQuestionFeedback(!showQuestionFeedback)
			if (!showQuestionFeedback) {
				return
			}
		}

		if (counter < questions.length) {
			if (counter + 1 >= lastQuestionNumber) {
				setLastQuestionNumber(counter + 1)
				setNextButtonDisabled(true)
			}
			setCounter(counter + 1)
			setQuestionId(questions[counter]?.id)
			setQuestionText(questions[counter]?.questionText)
			setAnswerOptions(questions[counter]?.options)
		} else {
			let correctCount = 0
			listOfCorrectAnswers.forEach((a) => {
				const ua = userAnswers.find((x) => x.id === a.id)
				if (JSON.stringify(a) === JSON.stringify(ua)) {
					correctCount += 1
				}
			})
			setUserCorrectAnswersCount(correctCount)
			const userPercentage = (correctCount * 100) / total
			setHasUserPassed(userPercentage >= passPercent)
			setShowResults(true)
		}
	}

	const handlePrevButtonClick = (buttonLabel?: string): void => {
		handleCtaClick(buttonLabel)
		if (!isCertification && !noFeedbackPerQuestion) {
			if (counter > 1 && !showQuestionFeedback) {
				setShowQuestionFeedback(true)
			} else if (showQuestionFeedback) {
				setShowQuestionFeedback(false)
				if (counter === 1 && !hasIntroPageContent) {
					setPrevButtonDisabled(true)
				}
				return
			}
		}
		if (counter > 1) {
			if (counter === 2 && !hasIntroPageContent) {
				if (!isCertification && !noFeedbackPerQuestion) {
					if (showQuestionFeedback) {
						setPrevButtonDisabled(true)
					}
				} else {
					setPrevButtonDisabled(true)
				}
			}
			setCounter(counter - 1)
			setQuestionId(questions[counter - 2]?.id)
			setQuestionText(questions[counter - 2]?.questionText)
			setAnswerOptions(questions[counter - 2]?.options)
			setNextButtonDisabled(false)
		} else if (hasIntroPageContent) {
			setShowIntroPage(true)
		}
	}

	const renderAnswerOptions = (
		option: QuizOption,
		index: number
	): ReactElement => {
		return (
			<AnswerOption
				checked={selectedAnswer === option.optionText}
				className={clsx(
					`js-quiz-input ${
						invalidAnswer === option.optionText ? 'invalid' : ''
					}`
				)}
				id={`${questionId}-${index + 1}`}
				key={`${questionId}-${index + 1}`}
				onAnswerSelected={(e) => {
					handleAnswerSelected(e, option)
				}}
				optionText={option.optionText}
				questionId={`q-${questionId}`}
				wrongOptionTooltip={wrongOptionTooltip}
			/>
		)
	}

	const renderProgressBar = (
		q: QuizQuestion,
		index: number
	): ReactElement => {
		if (index + 1 < questions.length) {
			return (
				<li key={q.id}>
					<span
						className={clsx(
							`mx-1 inline-block h-5 w-5 rounded-full text-xs ${
								index + 1 < counter
									? 'bg-secondary'
									: 'bg-white'
							}`
						)}
					/>
				</li>
			)
		}
		return (
			<li key={q.id}>
				<span className="mx-1 inline-block h-10 w-10 rounded-full text-xs">
					<StarShapedIcon
						className={clsx(
							`mx-auto h-full w-full fill-current ${
								index + 1 < counter
									? 'text-secondary'
									: 'text-white'
							}`
						)}
					/>
				</span>
			</li>
		)
	}

	useEffect(() => {
		if (prevQuestionId.current !== questionId) {
			setDefaultCheckedValue()
		} else if (oldShowIntroPage.current !== showIntroPage) {
			setDefaultCheckedValue()
		} else if (prevShowQuestionFeedback.current !== showQuestionFeedback) {
			setDefaultCheckedValue()
		}
		prevQuestionId.current = questionId
		oldShowIntroPage.current = showIntroPage
		prevShowQuestionFeedback.current = showQuestionFeedback
	})

	return (
		<div className={clsx(`mx-auto my-4 rounded-lg ${className}`)}>
			{showIntroPage ? (
				<div>
					<div className="bg-blue-900 p-4 text-center text-white md:p-8 lg:py-16">
						<div className="content">{introPageContent}</div>
					</div>

					<div className="p-4 text-center md:py-8 lg:py-16">
						<Button
							as="button"
							onClick={() => {
								setShowIntroPage(false)
							}}
							type="button"
						>
							{introPageButtonText}
						</Button>
					</div>
				</div>
			) : null}
			{!showIntroPage && (
				<div>
					{!showResults && (
						<>
							<div className="p-4 md:p-8">
								{!showQuestionFeedback && (
									<>
										<Question
											counter={counter}
											questionText={questionText}
										/>
										{answerOptions?.map(
											renderAnswerOptions
										)}
									</>
								)}
								{showQuestionFeedback ? (
									<div className="mx-auto my-6 max-w-xl">
										{hasUserAnsweredCorrect ? (
											<CheckMarkIcon className="mx-auto h-12 w-12" />
										) : (
											<CrossMarkIcon className="mx-auto h-12 w-12" />
										)}
										<h3 className="mb-4 mt-8">
											{hasUserAnsweredCorrect
												? correctOptionFeedbackTitle
												: wrongOptionFeedbackTitle}
										</h3>
										<div>
											{questions[counter - 1]?.feedback}
										</div>
									</div>
								) : null}

								<div className="flex pt-6">
									<Button
										as="button"
										disabled={prevButtonDisabled}
										icon={ButtonIcons.ArrowLeft}
										onClick={() => {
											handlePrevButtonClick(
												prevButtonText
											)
										}}
										reversed
										type="button"
									>
										{prevButtonText}
									</Button>
									<Button
										as="button"
										className="ml-auto"
										disabled={nextButtonDisabled}
										icon={ButtonIcons.ArrowRight}
										onClick={() => {
											handleNextButtonClick(
												nextButtonText
											)
										}}
										type="button"
									>
										{nextButtonText}
									</Button>
								</div>
							</div>
							{!hideProgressBar && (
								<div className="bg-blue-900 p-8 text-center text-white">
									<ul className="mb-2 flex items-center justify-center">
										{questions.map(renderProgressBar)}
									</ul>
									<p className="text-sm">{`${counter} of ${total}`}</p>
								</div>
							)}
						</>
					)}
					{showResults ? (
						<>
							<div className="mx-auto max-w-xl p-8 py-12 text-center">
								{!isCertification &&
								showUserScore &&
								!hasUserPassed ? (
									<h4 className="mb-4">
										{userCorrectAnswersCountText
											.replace(
												'{0}',
												userCorrectAnswersCount.toString()
											)
											.replace('{1}', total.toString())}
									</h4>
								) : null}
								{hasUserPassed ? (
									<div>
										<CheckMarkStarIcon className="mx-auto h-20 w-20" />
										{result}
									</div>
								) : (
									<div>
										<CrossMarkStarIcon className="mx-auto mb-6 h-20 w-20" />
										{failedResultContent}
										{retakeQuizButton
											? cloneElement(retakeQuizButton, {
													onClick: resetQuiz,
												})
											: null}
									</div>
								)}
							</div>
							<div
								className={clsx(
									`items-center justify-center bg-blue-900 p-8 text-center text-white sm:flex ${
										noShareIcons ? 'py-16' : ''
									}`
								)}
							>
								{!noShareIcons && (
									<>
										<p className="mb-4 sm:mb-1 sm:mr-6">
											{shareNotes}
										</p>
										<div>
											{facebookShareUrl ? (
												<EllipseButton
													className="mr-4 h-12 w-12 border border-white"
													href={`https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&u=${facebookShareUrl}&display=popup&ref=plugin&src=share_button`}
													icon={
														ButtonIcons.Facebook
															.icon
													}
													iconSize="h-8 w-8"
													target="_blank"
													variant="secondary"
												>
													Facebook
												</EllipseButton>
											) : null}
											{twitterShareUrl ? (
												<EllipseButton
													className="mr-4 border border-white"
													href={`https://twitter.com/share?url=${twitterShareUrl}`}
													icon={
														ButtonIcons.Twitter.icon
													}
													iconSize="h-7 w-7"
													target="_blank"
													variant="secondary"
												>
													Twitter
												</EllipseButton>
											) : null}
											{linkedinShareUrl ? (
												<EllipseButton
													className="mr-4 h-12 w-12 border border-white"
													href={`https://www.linkedin.com/sharing/share-offsite/?url=${linkedinShareUrl}`}
													icon={
														ButtonIcons.LinkedIn
															.icon
													}
													iconSize="h-7 w-7"
													target="_blank"
													variant="secondary"
												>
													Linkedin
												</EllipseButton>
											) : null}
										</div>
									</>
								)}
							</div>
						</>
					) : null}
				</div>
			)}
		</div>
	)
}
