import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useTrustArcCookieValue} from '@/utils/analytics'
import {FallbackBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/videos/fallback-block'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {spacing} from '../flexible-web-toolkit/styles'

export type PlayableWidgetContentItem = IContentItem<{
	urlAlias: Elements.TextElement
	urlDomain: Elements.MultipleChoiceElement
	parameters: Elements.MultipleChoiceElement
	scrolling: Elements.MultipleChoiceElement
}>

export const PlayableWidgetBlock: Block<PlayableWidgetContentItem> = ({
	block,
}) => {
	const sandboxDefaultParam = ['allow_same_origin', 'allow_scripts']
	const sandboxOptionalParams = block.elements.parameters.map(
		({codename}) => codename
	)
	const cumulativeSandboxParams = [
		...sandboxDefaultParam,
		...sandboxOptionalParams,
	]
	const sandboxParams = cumulativeSandboxParams.join(' ').replaceAll('_', '-')
	const trustArcCookie = useTrustArcCookieValue()

	return (
		<div className={`flex justify-around ${spacing.default}`}>
			{trustArcCookie === '1:' || trustArcCookie === '0:' ? (
				<FallbackBlock />
			) : (
				<>
					{/* eslint-disable-next-line @next/next/no-sync-scripts -- iirc, needs to be syncr to work properly */}
					<script src="https://files.cdn.leadfamly.com/campaign/sdk/utilities.responsive_iframe.min.js" />
					<iframe
						allow="autoplay; fullscreen"
						className="w-full border-none"
						data-no-scroll-into-view
						sandbox={sandboxParams}
						scrolling={block.elements.scrolling[0]?.codename} // fully aware that this is a deprecated prop, but alas... LeadFamly
						src={`${
							block.elements.urlDomain[0]?.name ||
							'https://elanco.leadfamly.com'
						}/${
							block.elements.urlAlias
						}?onlyflow=1&iframe=dontbreakout`}
						style={{height: '500px'}} // style tag has to be used here due to responsive utilities JS from LeadFamly (Playable)
						title="Playable Widget"
					/>
				</>
			)}
		</div>
	)
}
