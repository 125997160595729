import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {QuoteSlider} from '@elanco/component-library-v2'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import type {QuoteContentItem} from '../quote'
import {QuoteBlock} from '../quote'

export type QuoteSliderContentItem = IContentItem<{
	quotes: Elements.LinkedItemsElement<QuoteContentItem>
	showSliderArrowsInCenter: Elements.MultipleChoiceElement
	autoScroll: Elements.MultipleChoiceElement
	autoScrollDuration: Elements.NumberElement
}>

export const QuoteSliderBlock: Block<QuoteSliderContentItem> = ({
	block,
	...context
}) => {
	const isAutoPlayEnabled = block.elements.autoScroll[0]?.codename === 'yes'
	let autoPlaySpeed
	if (isAutoPlayEnabled) {
		if (
			block.elements.autoScrollDuration &&
			block.elements.autoScrollDuration > 0
		) {
			autoPlaySpeed = block.elements.autoScrollDuration * 1000
		} else {
			autoPlaySpeed = 1000
		}
	}

	return (
		<div className="pt-4 md:pb-8 md:pt-8 lg:pt-16">
			<div
				className="container-wide"
				data-kontent-element-codename="quote_slider"
			>
				<QuoteSlider
					autoPlay={isAutoPlayEnabled}
					autoPlaySpeed={autoPlaySpeed}
					isLargeMode={
						block.elements.showSliderArrowsInCenter[0]?.codename ===
						'yes'
					}
				>
					{block.elements.quotes.map((quote) => (
						// TODO: Remove this extra div eventually once we can confirm there's no visual regression across all towers of doing so
						<div key={quote.system.id}>
							<QuoteBlock block={quote} {...context} />
						</div>
					))}
				</QuoteSlider>
			</div>
		</div>
	)
}
