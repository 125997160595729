import {Button as CButton, ButtonIcons} from '@elanco/component-library-v2'
import {useRouter} from 'next/router'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {SyntheticEvent} from 'react'
import {clsx} from 'clsx'
import {addLocalePrefix} from '@/components/BlockMapper/utils'
import {pushToDataLayer} from '@/utils/analytics'
import type {ExtendedBlock} from '@/_new-code/services/kontent-ai/types'
import {spacing} from '@/_new-code/products/flexible-web-toolkit/styles'

export type CtaButtonContentItem = IContentItem<{
	text: Elements.TextElement
	preserveQueryString: Elements.MultipleChoiceElement
	url: Elements.TextElement
	variant: Elements.MultipleChoiceElement
	icon: Elements.MultipleChoiceElement
	openLinkInNewTab: Elements.MultipleChoiceElement
	snippetSpacingSpacing: Elements.MultipleChoiceElement
}>

/**
 * Instance of the cta_button component that can be used inside of other components
 */
export const ReusableCTAButtonComponent: ExtendedBlock<
	CtaButtonContentItem,
	{
		className?: string
		variant?: string
		onClick?: (e: SyntheticEvent) => void
	}
> = ({block, className, variant, onClick}) => {
	const router = useRouter()
	const queryParams = router.asPath.split('?')[1]
		? `?${router.asPath.split('?')[1]}`
		: ''
	const preserveQueryString =
		block.elements.preserveQueryString[0]?.codename === 'yes'

	const href = addLocalePrefix(
		preserveQueryString
			? `${block.elements.url}${queryParams}`
			: block.elements.url,
		router.locale ?? ''
	)

	const iconChoice: keyof typeof ButtonIcons | undefined =
		block.elements.icon[0]?.name

	const openInNewTab = block.elements.openLinkInNewTab[0]?.codename === 'yes'

	return (
		<CButton
			className={className}
			href={href}
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- TODO: fix component library types
			icon={iconChoice ? ButtonIcons[iconChoice] : null}
			onClick={(e: SyntheticEvent) => {
				pushToDataLayer({
					event: 'cta_click',
					cta_name: block.elements.text,
					cta_category: block.system.type,
				})

				if (onClick) onClick(e)

				// If href is an empty string, don't push it
				if (href && !openInNewTab) void router.push(href)
			}}
			target={openInNewTab ? '_blank' : '_self'}
			variant={
				variant ??
				(block.elements.variant[0]?.codename ||
					(block.elements.variant[0]?.codename === undefined ||
					block.elements.variant[0]?.codename === 'secondary'
						? 'primary'
						: undefined))
			}
		>
			{block.elements.text}
		</CButton>
	)
}

/**
 *  When the cta_button is used by itself, it should be wrapped in a container to provide spacing
 */
export const CtaButtonBlock: ExtendedBlock<
	CtaButtonContentItem,
	{
		className?: string
		variant?: string
		onClick?: (e: SyntheticEvent) => void
	}
> = ({block, ...rest}) => {
	const componentSpacing =
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- condition is necessary, block.elements.snippetSpacingSpacing may be null
		spacing[
			block.elements.snippetSpacingSpacing[0]
				?.codename as keyof typeof spacing
		] || spacing.default

	return (
		<div className={clsx(componentSpacing, 'container-narrow text-center')}>
			<ReusableCTAButtonComponent block={block} {...rest} />
		</div>
	)
}
