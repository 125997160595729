import {useState, useEffect} from 'react'
import {getBrowser} from '@/utils/helpers'

interface WindowSize {
	width: number | undefined
	height: number | undefined
	isMobile: boolean | undefined
	isTablet: boolean | undefined
}

const isClient = typeof window === 'object'

const getSize = (): WindowSize => {
	const browser = getBrowser()
	let isMobile
	if (!isClient) {
		// Use UA sniffing to see if we're server side rendering for a mobile
		isMobile = browser ? browser.platform.type === 'mobile' : undefined
	}
	return {
		width: isClient ? window.innerWidth : undefined,
		height: isClient ? window.innerHeight : undefined,
		isMobile: isClient ? window.innerWidth < 768 : isMobile,
		isTablet: isClient
			? window.innerWidth >= 768 && window.innerWidth < 1024
			: undefined,
	}
}

export const useWindowSize = (): WindowSize => {
	const [windowSize, setWindowSize] = useState(getSize)

	useEffect(() => {
		if (!isClient) {
			return
		}

		const handleResize = (): void => {
			setWindowSize(getSize())
		}

		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, []) // Empty array ensures that effect is only run on mount and unmount

	return windowSize
}
