import {FC, PropsWithChildren, ReactNode, useEffect} from 'react'
import {useFormContext} from 'react-hook-form'
import {ErrorMessage} from '../Form'

interface SelectFieldProps {
	label: ReactNode
	name: string
	className?: string
	isOptional?: boolean
	requiredErrorMsg?: string
	defaultValue?: string
	onChange?: (value: string) => void
	disabled?: boolean
}

const SelectField: FC<PropsWithChildren<SelectFieldProps>> = ({
	defaultValue = '',
	className = '',
	children,
	label,
	name,
	isOptional = false,
	requiredErrorMsg = '',
	onChange = () => {},
	disabled = false,
}) => {
	const requiredMsg = requiredErrorMsg || `${label} is required`
	const {register, trigger, formState} = useFormContext()
	useEffect(() => {
		let ignore = false

		async function validation() {
			if (defaultValue && !ignore) {
				await trigger(name)
			}
		}

		validation()

		return () => {
			ignore = true
		}
	}, [defaultValue])

	const {onChange: libOnChange, ...registerProps} = register(name, {
		required: !isOptional,
	})

	return (
		<div className={`relative mb-4 ${className}`}>
			<label htmlFor={name} className="w-full text-xs text-pet-primary">
				<span>
					{label}
					{!isOptional && <span className="text-red-600">*</span>}
				</span>
				<div className="relative mt-2">
					<fieldset className="bg-transparent" disabled={disabled}>
						<select
							{...registerProps}
							onChange={(e) => {
								libOnChange(e)
								onChange(e.target.value)
							}}
							defaultValue={defaultValue}
							id={name}
							name={name}
							className={`${
								formState.errors[name] ? 'invalid' : ''
							} appearance-none rounded-md !bg-white py-3 text-sm font-normal text-pet-primary`}
						>
							{children}
						</select>
						<svg
							height="20"
							width="20"
							viewBox="0 0 20 20"
							aria-hidden="true"
							focusable="false"
							className="mr-[15px] mt-[13px] !fill-mid-grey"
						>
							<path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
						</svg>
						{formState.errors[name] && (
							<ErrorMessage message={requiredMsg} />
						)}
					</fieldset>
				</div>
			</label>
		</div>
	)
}

export default SelectField
