import {useEffect, ReactNode, FC} from 'react'
import {useFormContext} from 'react-hook-form'
import {ErrorMessage} from '../Form'

interface TextareaFieldProps {
	label: ReactNode
	name: string
	className?: string
	isOptional?: boolean
	icon?: string
	maxLength?: number
	requiredErrorMsg?: string
	defaultValue?: string
	disabled?: boolean
}

const TextareaField: FC<TextareaFieldProps> = ({
	defaultValue = '',
	className = '',
	label,
	name,
	isOptional = false,
	requiredErrorMsg = '',
	maxLength = 1000,
	disabled = false,
}) => {
	const requiredMsg = requiredErrorMsg || `${label} is required`
	const {register, trigger, formState} = useFormContext()
	useEffect(() => {
		let ignore = false

		async function validation() {
			if (defaultValue && !ignore) {
				await trigger(name)
			}
		}

		validation()

		return () => {
			ignore = true
		}
	}, [defaultValue])

	return (
		<div className={`relative mb-4 ${className}`}>
			<label htmlFor={name}>
				<span>
					{label}
					{!isOptional && <span className="text-red-600">*</span>}
				</span>
				<div className="relative mt-2">
					<textarea
						{...register(name, {required: !isOptional})}
						defaultValue={defaultValue}
						disabled={disabled}
						rows={5}
						id={name}
						name={name}
						maxLength={maxLength}
						className={formState.errors[name] ? 'invalid' : ''}
					/>
					{formState.errors[name] && (
						<ErrorMessage message={requiredMsg} />
					)}
				</div>
			</label>
		</div>
	)
}

export default TextareaField
