/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-console */
import http from '@/utils/axios'
import vetApi, {MapEntity} from '../services/vetApi'

interface Address {
	geometry: {
		location: {
			lat: string
			lng: string
		}
	}
}

interface GeoResults {
	results: Address[]
	status: string
	error: string
}
async function geocode(address: string): Promise<GeoResults> {
	try {
		const {data} = await http({
			method: 'POST',
			url: '/api/google/geocode',
			data: {address},
		})
		return data as GeoResults
	} catch (error: any) {
		throw new Error(
			`Geocode API error status ${error.response.status}\nMessage: ${error.message}`
		)
	}
}

export const fetchVetClinics = async (
	address: string
): Promise<MapEntity[]> => {
	if (!address) {
		return []
	}
	const geo = await geocode(address)
	if (geo.status !== 'OK') {
		return []
	}
	// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain -- works for now
	const {lat, lng} = geo?.results[0]?.geometry?.location!
	const response = await vetApi.searchLatLng(lat, lng, 'ELA_AC_061')
	if (response?.entities !== undefined) {
		return response.entities
	}
	return []
}

export const fetchShelters = async (address: string): Promise<MapEntity[]> => {
	if (!address) {
		return []
	}
	const geo = await geocode(address)
	if (geo.status !== 'OK') {
		return []
	}
	// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain -- works for now
	const {lat, lng} = geo?.results[0]?.geometry?.location!
	const response = await vetApi.searchLatLng(lat, lng, 'ELA_AC_003')
	if (response?.entities !== undefined) {
		return response.entities
	}
	return []
}
