import type {PropsWithChildren, ReactElement} from 'react'
import {useState, useEffect, isValidElement, Children} from 'react'
import clsx from 'clsx'
import type {AccordionItemProps} from './accordion-item'
import {AccordionItem} from './accordion-item'

interface AccordionProps {
	className?: string
	expanded?: boolean
	title?: string
}

export const Accordion = ({
	children,
	className = '',
	// eslint-disable-next-line @typescript-eslint/no-unused-vars -- Optional prop for controlling expansion
	expanded: exp = false,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars -- Optional title prop for the accordion
	title = '',
}: PropsWithChildren<AccordionProps>): JSX.Element => {
	const [expanded, setExpanded] = useState<number | null>(null)
	const [isContextUsed, setIsContextUsed] = useState(false)

	const childNodes = Children.toArray(children) as ReactElement[]

	useEffect(() => {
		setIsContextUsed(document.querySelector('#theme') !== null)
	}, [])

	const toggleItem = (index: number): void => {
		setExpanded(expanded === index ? null : index)
	}

	return (
		<>
			{childNodes.map((child, index) => {
				if (isValidElement(child) && child.type === AccordionItem) {
					const accordionItemProps = child.props as AccordionItemProps

					return (
						<dl className="mb-0.5" key={accordionItemProps.key}>
							<dt>
								<button
									aria-expanded={expanded === index}
									className="w-full bg-gray-100 px-5 py-5 text-left text-blue-800"
									onClick={() => {
										toggleItem(index)
									}}
									type="button"
								>
									<div className="flex items-center justify-between">
										<h2
											className={clsx(
												'text-lg',
												'font-semibold',
												{
													'text-main': isContextUsed,
													'text-blue-900':
														!isContextUsed,
												}
											)}
										>
											{accordionItemProps.title}
										</h2>
										<svg
											className="shrink-0 stroke-current"
											height="20"
											viewBox="0 0 17.1 10.7"
											width="20"
										>
											<path
												d="M1.9,1.9l6.6,6.2l6.7-6.2"
												fill="none"
												strokeLinecap="round"
												strokeWidth="4"
											/>
										</svg>
									</div>
								</button>
							</dt>
							<dd
								className={clsx(
									'content--no-width-restriction',
									'p-5',
									'transition-all',
									'duration-200',
									'ease-out',
									className,
									{
										block: expanded === index,
										hidden: expanded !== index,
									}
								)}
							>
								{child}
							</dd>
						</dl>
					)
				}
				return null
			})}
		</>
	)
}
