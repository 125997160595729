import {Pet, PetResponse} from '@/models/consumerEngagement/models'
import http from '@/utils/axios'
import {QueryFunctionContext} from 'react-query'

export interface PetFormData {
	id?: string
	petName: string
	petSex: string
	breed: string
	petSpecies: string
	petDateOfBirth: string
	petWeight: number
	petAcquisitionType: string
	petAcquisitionDate: string
	imageReference: string
	shelterName?: string
	petShelter: string
	vetClinic: string
	clinicName?: string
}

interface PetIDQuery {
	id?: string
}

export type SinglePetQueryKey = [string, PetIDQuery]

export const fetchPetProfiles = async (locale: string): Promise<Pet[]> => {
	const endpoint = `/api/petProfiles?locale=${locale}`
	const {data: response} = await http<PetResponse>(endpoint)

	return response.data
}

export const fetchSinglePetProfile = async (
	context: QueryFunctionContext<SinglePetQueryKey>,
	locale: string
): Promise<Pet> => {
	const [, params] = context.queryKey
	const endpoint = `/api/pets/${params.id}?locale=${locale}`
	const {data} = await http<Pet>(endpoint)

	return data
}

export const createPetProfile = async (
	petData: PetFormData,
	locale: string
): Promise<Pet> => {
	const {data} = await http<Pet>(`/api/petProfiles?locale=${locale}`, {
		method: 'POST',
		data: {
			name: petData.petName,
			gender: petData.petSex,
			breed: petData.breed,
			species: petData.petSpecies,
			birthDate: petData.petDateOfBirth,
			weight: +petData.petWeight,
			acquisitionSource: petData.petAcquisitionType,
			acquisitionDate: petData.petAcquisitionDate,
			petShelterId: petData.petShelter,
			petShelterName: petData.shelterName,
			vetClinicId: petData.vetClinic,
			vetClinicName: petData.clinicName,
			imageReference: petData.imageReference,
		},
	})
	return data
}
