import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {defaultMargins} from '../../styles'

export type SmallTextContentItem = IContentItem<{
	content: Elements.TextElement
}>

export const SmallTextBlock: Block<SmallTextContentItem> = ({
	block: {
		elements: {content},
	},
}) => {
	return (
		<p
			className={`container-narrow text-center ${defaultMargins}`}
			data-kontent-element-codename="content"
		>
			{content}
		</p>
	)
}
