export const REGEX = '^[A-Za-z0-9-/_]*$'
export const REGEX_URL_MESSAGE =
	'URLs contain only alphanumeric characters, forward slash,  dashes and underscores'

export const bcp47List: {[key: string]: string} = {
	default: 'en-gb',
	en_gb: 'en-gb',
	us: 'en-us',
	au: 'en-au',
	nl: 'nl-nl',
	dk: 'da-dk',
	se: 'sv-se',
	ru: 'ru-ru',
	it: 'it-it',
	en_ca: 'en-ca',
	fr_ca: 'fr-ca',
	fr: 'fr-fr',
	hu: 'hu-hu',
	de: 'de-de',
	br: 'pt-br',
	es: 'es-es',
	mx: 'es-mx',
	pl: 'pl-pl',
	ar: 'es-ar',
	at: 'de-at',
	nl_be: 'nl-be',
	fr_be: 'fr-be',
	cn: 'zh-cn',
	cl: 'es-es',
	co: 'es-es',
	cz: 'cs-cz',
	jp: 'ja-jp',
	pt: 'pt-pt',
	ua: 'ru-ua',
	nz: 'en-gb',
	sa: 'en-za',
	th: 'th-th',
	fi: 'fi-fi',
	no: 'no-no',
	tr: 'tr-tr',
	hr: 'hr-hr',
	testing: 'en-gb',
	null: 'en-gb',
}
