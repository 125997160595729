export function getDateOneYearAgo(from = new Date()): Date {
	const year = from.getFullYear() - 1
	from.setFullYear(year)
	return from
}

export const toYYYYMMDD = (date = new Date()): string => {
	return `${date.getFullYear().toLocaleString('en-US', {
		minimumIntegerDigits: 4,
		useGrouping: false,
	})}-${(date.getMonth() + 1).toLocaleString('en-US', {
		minimumIntegerDigits: 2,
		useGrouping: false,
	})}-${date.getDate().toLocaleString('en-US', {
		minimumIntegerDigits: 2,
		useGrouping: false,
	})}`
}
