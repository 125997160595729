export function getAllCombinations<T>(array: T[]): T[][] {
	const combi: T[][] = []
	let temp: T[] = []
	const slent = Math.pow(2, array.length)

	for (let i = 0; i < slent; i++) {
		temp = []
		for (let j = 0; j < array.length; j++) {
			// eslint-disable-next-line no-bitwise -- Works
			if (i & Math.pow(2, j)) {
				const item = array[j]
				if (item) {
					temp.push(item)
				}
			}
		}
		if (temp.length > 0) {
			combi.push(temp)
		}
	}

	return combi
}

export function shuffleArray<T>(array: T[]): T[] {
	const shuffledArray = array.slice()

	for (let i = shuffledArray.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1))
		;[shuffledArray[i], shuffledArray[j]] = [
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- Assertions are correct
			shuffledArray[j]!,
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- Assertions are correct
			shuffledArray[i]!,
		]
	}

	return shuffledArray
}
