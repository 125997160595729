import {Icon as CIcon} from '@elanco/component-library-v2'
import Image from 'next/future/image'
import {useRouter} from 'next/router'
import Link from 'next/link'
import {loadImageFromKontentAI} from '@/imageLoader'
import {logout, useAuth} from '@/_new-code/products/auth/auth-provider'
import type {Tersed} from '@/_new-code/services/kontent-ai/types'
import type {IconContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/icon'
import type {
	AuthNavItemLinkContentItem,
	NavItemLinkContentItem,
} from '@/_new-code/products/flexible-web-toolkit/components/header/models'

interface AccountDropdownProps {
	accountIcon: Tersed<IconContentItem>[]
	greetingText: string
	navLinks: Tersed<AuthNavItemLinkContentItem | NavItemLinkContentItem>[]
}

export const AccountDropdown = ({
	accountIcon,
	greetingText,
	navLinks,
}: AccountDropdownProps): JSX.Element => {
	const authState = useAuth()
	const router = useRouter()
	const {firstName, lastName, salutation} = authState.userDetails
	let greetingTexts = greetingText
	if (
		greetingText &&
		firstName &&
		lastName &&
		salutation &&
		greetingText.includes('{{lastName}}')
	) {
		greetingTexts = greetingText
			.replace(/{{firstname}}/gi, '')
			.replace(/{{lastName}}/gi, `${salutation}  ${lastName}`)
	} else {
		greetingTexts = greetingText
			.replace(/{{firstname}}/gi, firstName)
			.replace(/{{lastname}}/gi, lastName)
	}

	return (
		<div className="account-dropdown">
			<button type="button">
				{accountIcon[0]
					? accountIcon.map((icon) => (
							<CIcon
								className="h-8 w-8"
								key={icon.system.id}
								noBackground
								noBorder
							>
								<Image
									alt={
										icon.elements.icon[0]?.description ??
										undefined
									}
									className="h-8 w-8"
									height={32}
									loader={loadImageFromKontentAI}
									src={icon.elements.icon[0]?.url || ''}
									width={32}
								/>
							</CIcon>
						))
					: null}
				<span>{greetingTexts}</span>
			</button>
			<div className="nav-link-container">
				<ul>
					{navLinks.map((link) => (
						<li key={link.system.id}>
							<Link href={link.elements.url} passHref>
								{/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions -- TODO: update when next/link updates */}
								<a
									className="inline-block w-full cursor-pointer py-3"
									onClick={() => {
										if (
											link.elements.logoutUser[0]
												?.codename === 'yes'
										) {
											logout(
												router,
												link.elements.url || '/'
											)
										}
									}}
								>
									{link.elements.text}
								</a>
							</Link>
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}
