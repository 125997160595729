import React from 'react'
import Image from 'next/future/image'
import {loadImageFromKontentAI} from '@/imageLoader'

interface TableHeaderProps {
	centered?: boolean
	column?: boolean
	iconAlt?: string
	iconUrl?: string
	text?: string | React.ReactNode
}

export const TableHeader: React.FC<TableHeaderProps> = ({
	centered = false,
	column = false,
	iconAlt = '',
	iconUrl,
	text = '\u00A0',
}): JSX.Element => (
	<span
		className={`${column ? 'flex-col' : ''} ${
			iconUrl ? 'flex items-center' : ''
		} ${centered ? 'mb-5 md:mb-0 md:justify-center' : ''}`}
	>
		{iconUrl ? (
			<Image
				alt={iconAlt || 'icon'}
				className={column ? 'flex-col' : 'h-6'}
				height={50}
				loader={loadImageFromKontentAI}
				src={iconUrl}
				width={50}
			/>
		) : null}
		<span
			className={`min-w-18 font-bold ${
				iconUrl ? 'ml-2 inline-block' : ''
			}`}
		>
			{text}
		</span>
	</span>
)
