import {createElement} from 'react'
import {createRoot} from 'react-dom/client'
import type {QuestionType} from '@/_new-code/products/campaign-forms/cdc-screen-set-wrapper/models'
import {CampaignQuestions} from '@/_new-code/products/campaign-forms/cdc-screen-set-wrapper/campaign-questions/campaign-questions'
import type {CampaignInterestHandler} from './campaign-interest-handler'

function fieldSelector<T extends Element>(
	selector: string,
	parent: HTMLElement | Document = document
): T {
	const element = parent.querySelector<T>(selector)
	if (!element) {
		throw new Error('Element not found')
	}
	return element
}

export function injectIntoScreenset(
	id: string,
	campaignQuestions: QuestionType[],
	handler: CampaignInterestHandler
): void {
	const formElement = fieldSelector<HTMLDivElement>(
		'div[data-kontent-element-codename="cdc_gigya"]'
	)
	const insertHere = fieldSelector<HTMLLabelElement>(
		'label[class~="interests"][data-screenset-roles*="instance"]',
		formElement
	)

	const row = document.createElement('div')
	row.setAttribute('class', 'gigya-layout-row')

	const questions = (
		<CampaignQuestions
			campaignQuestions={campaignQuestions}
			handler={handler}
			id={id}
		/>
	)
	const reactDiv = createElement(
		'div',
		{
			className: 'gigya-composite-control',
			key: id,
			id,
		},
		questions
	)

	insertHere.parentElement?.after(row)

	const root = createRoot(row)
	root.render(reactDiv)
}
