import {useAuth} from '@/_new-code/products/auth/auth-provider'
import {ResponsiveLinkWithIconBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/responsive-link-with-icon'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {NotificationBannerBlock} from '@/_new-code/products/flexible-web-toolkit/components/header/notification-banner'
import {useMeasure} from '@/_new-code/utilities/elements'

export const StickyBottomNavBlock: Block = ({block, ...context}) => {
	const auth = useAuth()

	// If the user is authenticated and the stickyBottomNavAuthenticated is populated then use that otherwise use stickyBottomNav
	const stickyNavBlock =
		auth.authenticated &&
		context.globalConfig.elements.stickyBottomNavAuthenticated[0]
			? context.globalConfig.elements.stickyBottomNavAuthenticated
			: context.globalConfig.elements.stickyBottomNav

	const {
		ref: navBarsContainerRef,
		dimensions: {height: notificationBarsHeight},
	} = useMeasure<HTMLDivElement>()

	const {
		ref: stickyBarContainerRef,
		dimensions: {height: stickyBarHeight},
	} = useMeasure<HTMLElement>()

	const spacerHeight = notificationBarsHeight + stickyBarHeight

	return (
		<div
			data-kontent-element-codename={block.system.codename}
			// This ID is used for full tabbed blocks and the back-to-top button's positioning
			id="our_sticky_bottom_nav_spacer"
			style={{
				// Add padding between this block and the footer element to account for any Notification Bars and the Sticky Nav Bar
				height: `${spacerHeight}px`,
			}}
		>
			<div
				className="fixed z-[10] w-full md:bottom-16"
				ref={navBarsContainerRef}
				style={{
					bottom: `${stickyBarHeight}px`,
				}}
			>
				<NotificationBannerBlock
					block={block}
					position="bottom"
					{...context}
				/>
			</div>
			{stickyNavBlock.length > 0 ? (
				<nav
					className="fixed bottom-0 z-[99] flex w-full items-center justify-around bg-white p-0 shadow-[0px_0px_5px_DarkGrey] md:p-4 [&>*]:scale-75 md:[&>*]:scale-100"
					ref={stickyBarContainerRef}
				>
					{/* We take the first four items of the array as that's the limit set by the content model */}
					{stickyNavBlock.slice(0, 4).map((link) => (
						<ResponsiveLinkWithIconBlock
							block={link}
							className="w-1/4"
							key={link.system.id}
							{...context}
						/>
					))}
				</nav>
			) : null}
		</div>
	)
}
